import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Message from './Message';
import Sent from './Sent';

class Index extends Component {
  render() {
    return (
      <div className={`flex h-[100vh]`}>
        <Switch>
          {/* メッセージ */}
          <Route path="/print/messages/:messageId" component={Message} />
          {/* 送信済み */}
          <Route path="/print/sent/:sentId" component={Sent} />
        </Switch>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
