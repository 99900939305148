import { decorateHtml, messageConverter, StorageMessage } from 'lib';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LogoImageSrc from '../../../assets/logos/logo_wide_fullcolor_RGB.png';
import * as color from '../../../color';
import { fetchAsJson, fetchAsText } from '../../../util';
import Body from './body';
import Header from './header';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageLoading: true,
      message: null,
      loading: true,
      storageMessage: null,
      storageHtml: null,
      storageTextAsHtml: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const message = await this.loadMessage();
    if (!message) {
      return;
    }

    await this.fetchMessageFromStorage(message);
    setTimeout(() => {
      window.print();
    }, 500);
  };

  loadMessage = async () => {
    this.setState({ messageLoading: true });

    const { messageId } = this.props.match.params;

    const snapshot = await getDoc(
      companyDoc('messages', messageId, messageConverter)
    );
    if (!snapshot.exists()) {
      this.setState({ messageLoading: false });
      return null;
    }

    const message = snapshot.data();
    this.setState({ messageLoading: false, message });
    return message;
  };

  fetchMessageFromStorage = async (message) => {
    const {
      id,
      storagePath,
      textAsHtml,
      textAsHtmlStoragePath,
      html,
      htmlStoragePath,
    } = message;

    if (html) {
      this.setState({
        loading: false,
      });
      return;
    }

    if (htmlStoragePath) {
      const storageHtml = await fetchAsText(htmlStoragePath);
      this.setState({
        storageHtml: decorateHtml(storageHtml),
        loading: false,
      });
      return;
    }

    if (textAsHtml) {
      this.setState({
        loading: false,
      });
      return;
    }

    if (textAsHtmlStoragePath) {
      const storageTextAsHtml = await fetchAsText(textAsHtmlStoragePath);
      this.setState({
        storageTextAsHtml: decorateHtml(storageTextAsHtml),
        loading: false,
      });
      return;
    }

    if (storagePath) {
      const storageMessage = await this.fetchStorageMessage(id, storagePath);
      this.setState({
        storageMessage,
        loading: false,
      });
    }
  };

  fetchStorageMessage = async (id, path) => {
    return new StorageMessage(id, await fetchAsJson(path));
  };

  html() {
    const { loading, storageMessage, storageHtml, storageTextAsHtml } =
      this.state;
    if (loading) return null;
    if (storageHtml) return storageHtml;
    if (storageTextAsHtml) return storageTextAsHtml;
    if (storageMessage) {
      return storageMessage.html
        ? storageMessage.html
        : storageMessage.sanitizedTextAsHtml;
    }
    const { message } = this.state;
    const { html, textAsHtml } = message;
    if (html) return html;
    return decorateHtml(textAsHtml);
  }

  render() {
    const { message, loading, storageMessage } = this.state;
    const { me } = this.props.store;

    return (
      <div
        className={`m-[8px] w-full`}
        style={{ backgroundColor: color.common.white }}
      >
        {message && (
          <>
            <div
              className={`flex items-center justify-between pb-[12px] pt-[12px]`}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img alt="" className={`h-[40px]`} src={LogoImageSrc} />
              </div>
              <p
                className={`font-bold text-gray-500`}
              >{`${me.name} <${me.email}>`}</p>
            </div>
            <hr />
            <Header>{message.subject}</Header>
            <hr />
            <Body
              message={message}
              html={this.html()}
              loading={loading}
              attachments={storageMessage?.attachments || message.attachments}
            />
          </>
        )}
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
