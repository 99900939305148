import React from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';

const { SubMenu } = Menu;

type Props = {
  openDatePicker: () => void;
  supported: boolean;
} & MenuProps;

export const FollowUpSubMenu: React.FC<Props> = ({
  openDatePicker,
  supported,
  ...props
}) => {
  if (!supported) {
    return (
      <SubMenu title="フォローアップ日時を指定&nbsp;&nbsp;" {...props}>
        <Menu.Item>
          <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
            現在のプランで送信予約はサポートされていません
          </div>
        </Menu.Item>
      </SubMenu>
    );
  }

  return (
    <SubMenu title="フォローアップ日時を指定&nbsp;&nbsp;" {...props}>
      <Menu.Item onClick={openDatePicker}>日付と時刻を選択</Menu.Item>
    </SubMenu>
  );
};
