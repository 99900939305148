import { Icon, Switch } from 'antd';
import { H2 } from 'App/Common/H2';

type Props = {
  enabled: boolean;
  title: string;
  onChangeEnabled: (enabled: boolean) => void;
  icon?: React.ReactNode;
  switchDisabled?: boolean;
};
export const UserNotificationHeading: React.FC<Props> = ({
  enabled,
  title,
  icon,
  onChangeEnabled,
  switchDisabled,
}) => {
  return (
    <H2 className={'flex items-center'}>
      {icon ? <div className="icon-wrappwer">{icon}</div> : null}
      <Icon type="chatwork" style={{ marginRight: 4, fontSize: 21 }} />
      {title}
      <Switch
        className="switch"
        checked={enabled}
        onChange={onChangeEnabled}
        style={{ marginLeft: 12 }}
        disabled={switchDisabled}
      />
    </H2>
  );
};
