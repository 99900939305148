import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { SPOnly } from '../../../Common/MediaQuery';
import * as color from '../../../../color';

type Props = {
  backLink: string | (() => string);
};

export const SPBackButton: React.FC<Props> = ({ backLink }) => {
  const history = useHistory();
  const back = () => {
    if (typeof backLink === 'string') {
      history.push(backLink);
    } else {
      history.push(backLink());
    }
  };

  return (
    <SPOnly>
      <div
        style={{ backgroundColor: color.sidebar.background.normal }}
        className={`w-full border-b-[0.5px] border-b-[#f1f1f3]`}
      >
        <Button onClick={back}>
          <Icon type="left" />
          戻る
        </Button>
      </div>
    </SPOnly>
  );
};
