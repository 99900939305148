// TODO: libに移動
export interface ProductData {
  name: string;
  description: string;
  metadata: any;
  badge?: boolean;
}

export class Product {
  id: string;
  name: string;
  description: string;
  maxInboxes: number;
  maxUsers: number;
  customStatusSupported: boolean;
  plan: 'light' | 'standard' | 'business' | 'enterprise';
  version: number;
  order: number;
  metadata: any;
  badge?: boolean;
  maxTemplates?: number | null;
  maxUserGroups?: number | null;

  constructor({
    id,
    name,
    description,
    metadata,
    badge,
  }: ProductData & { id: string }) {
    this.id = id;
    this.name = name;
    this.description = description || '';
    this.maxInboxes = metadata.maxInboxes || null;
    this.maxUsers = metadata.maxUsers || null;
    this.customStatusSupported = metadata.customStatusSupported === 'true';
    this.plan = metadata.plan;
    this.version = Number(metadata.version || 1);
    this.order = Number(metadata.order || 0);
    this.metadata = metadata;
    this.badge = badge || false;
    this.maxTemplates =
      typeof metadata.maxTemplates !== 'undefined'
        ? Number(metadata.maxTemplates)
        : null;
    this.maxUserGroups =
      typeof metadata.maxUserGroups !== 'undefined'
        ? Number(metadata.maxUserGroups)
        : null;
  }

  selectable = (inboxCount: number, userCount: number): boolean =>
    !this.hasSmallerMaxInboxes(inboxCount) &&
    !this.hasSmallerMaxUsers(userCount);

  hasSmallerMaxInboxes = (count: number): boolean =>
    !!this.maxInboxes && count > this.maxInboxes;

  hasSmallerMaxUsers = (count: number): boolean =>
    !!this.maxUsers && count > this.maxUsers;
}
