import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import * as Sentry from '@sentry/react';
import { Divider } from 'antd';
import { MFAVerifyPhoneCodeForm } from './MFA';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isSigningIn: false,
      errorMessage: '',
    };
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({ isSigningIn: true });
    try {
      const { email, password } = this.state;
      // firebaseにログイン
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // ログイン成功
      window.location.href = '/';
    } catch (error) {
      console.error('SignIn.submit:', error);
      // "auth/user-not-found"
      const { code } = error;
      let errorMessage;
      let mfaRequiredError = null;
      switch (code) {
        case 'auth/user-not-found':
          errorMessage = '※登録されていないメールアドレスです';
          break;
        case 'auth/wrong-password':
          errorMessage = '※パスワードが違います';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            '※認証に一定回数失敗しました。時間をおいてから再度お試し下さい。';
          break;
        case 'auth/multi-factor-auth-required':
          mfaRequiredError = error;
          break;
        case 'auth/internal-error':
          if (error.message.includes('Unauthorized access')) {
            errorMessage =
              'ログインが許可されませんでした。自社のyaritori管理者権限を持つ担当に問い合わせください';
          } else {
            errorMessage = '予期せぬエラーが発生しました';
          }

          break;
        default:
          Sentry.captureMessage(e);
          errorMessage = '予期せぬエラーが発生しました';
      }
      this.setState({ errorMessage, isSigningIn: false, mfaRequiredError });
    }
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  toResetPassword = () => this.props.history.push('/resetPassword');

  render() {
    const { isSigningIn, email, password, errorMessage, mfaRequiredError } =
      this.state;

    // MFAが設定されている場合にMFAの確認画面を設定する
    if (mfaRequiredError) {
      return (
        <div className={`block h-[100vh] md:flex`}>
          <div
            className="w-1/2 bg-cover bg-center"
            style={{ backgroundImage: `url(${bg1})` }}
          />
          <div
            className={`flex h-full w-full flex-col items-center justify-between p-[10px_20px] md:h-auto md:w-1/2`}
          >
            <div />
            <div>
              <div className={`pb-[50px]`}>
                <MFAVerifyPhoneCodeForm mfaRequiredError={mfaRequiredError} />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      );
    }

    return (
      <div className={`block h-[100vh] md:flex`}>
        <div
          className="w-1/2 bg-cover bg-center"
          style={{ backgroundImage: `url(${bg1})` }}
        />
        <div
          className={`flex h-full w-full flex-col items-center justify-between p-[10px_20px] md:h-auto md:w-1/2`}
        >
          <div />
          <div>
            <div className={`pb-[50px]`}>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <div className={`relative mb-[20px]`}>
                  <label
                    className={`md;pb-0 static left-[-110px] top-[10px] block pb-[1em] md:absolute`}
                  >
                    メールアドレス
                  </label>
                  <DefaultInput
                    className={`max-w-[calc(100vw - 60px)] h-[40px] w-[400px]`}
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isSigningIn}
                    required
                    autoComplete="username"
                  />
                </div>
                <div className={`relative mb-[20px]`}>
                  <label
                    className={`static left-[-110px] top-[10px] block pb-[1em] md:absolute md:pb-0`}
                  >
                    パスワード
                  </label>
                  <DefaultInput
                    className={`max-w-[calc(100vw - 60px)] h-[40px] w-[400px]`}
                    value={password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isSigningIn}
                    required
                    autoComplete="current-password"
                  />
                </div>
                <DefaultButton
                  className={`mt-[20px] h-[50px] w-full max-w-[400px]`}
                  type="submit"
                  disabled={isSigningIn}
                >
                  ログイン
                </DefaultButton>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className={`mt-[20px] block text-[15px] underline`}
                  style={{ color: color.a.text.normal }}
                  onClick={(e) => {
                    this.toResetPassword();
                  }}
                >
                  パスワードを忘れた場合はこちら
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <Divider />
                <a
                  className={`mt-[20px] block text-[15px] underline`}
                  style={{ color: color.a.text.normal }}
                  href="https://yaritori.jp/trial/"
                >
                  新規登録はこちら
                </a>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

/**
 * @type {DefaultButton & React.FC<React.HTMLProps<HTMLButtonElement> & { type: "submit"}}
 */

export default compose(withRouter, inject('store'), observer)(SignIn);
