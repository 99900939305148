import { forwardRef } from 'react';
import Avatar from '../../../../Common/Avatar';
import Tooltip from '../../../../Common/Tooltip';
import Tags from './tags';
import * as color from '../../../../../color';
import { Button, Select } from 'antd';
import { CmpOptGroup, CmpOption, CmpSelect, CmpTooltip } from 'utils/antdComp';
import { observer } from 'mobx-react';
import { SPBackButton } from '../SPBackButton';
import { useStore } from 'hooks/useStore';
import { isSP } from 'shared/util';
import { SPConversationHeader } from './SPConversationHeader';
import { getAssigneeOptions } from './util';
import { ConversationHeaderProps } from './type';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import { useAtomValue } from 'jotai';
import {
  buildFrontMessageStatus,
  buildFrontSystemMessageStatus,
  hasCustomStatus,
  statusesOptionsWhenCustomStatusedEnabled,
} from 'utils/frontMessageStatus';
import { Attach } from 'components/icons';

export const ConversationHeader = observer(
  forwardRef<HTMLDivElement, ConversationHeaderProps>((props, ref) => {
    const {
      message,
      messages,
      locked,
      updateStatus,
      updateAssignee,
      users,
      teamMembers,
      restoreMessages,
      deleteMessages,
      backLink,
    } = props;
    const store = useStore();
    const [, statuses] = useAtomValue(statusesAtomFamily(message.teamId));

    const isReadOnly = store.me.isReadOnly;
    let statusSelect = null;

    if (hasCustomStatus(statuses)) {
      const options = statusesOptionsWhenCustomStatusedEnabled(statuses);
      const isExists = options.some((o) => o.value === message.status);
      statusSelect = (
        <Select
          value={isExists ? message.status : '削除されたステータス'}
          onChange={(status) => {
            updateStatus(buildFrontMessageStatus(status, statuses));
          }}
          disabled={isReadOnly}
        >
          {options.map(({ value, label }) => (
            <Select.Option value={value} key={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      );
    }

    const assigneeOptions = getAssigneeOptions(users, teamMembers);

    if (isSP()) {
      return <SPConversationHeader {...props} />;
    }

    return (
      <div
        className={`sticky top-0 z-[2] w-full p-[10px_12px]`}
        style={{ backgroundColor: color.sidebar.background.normal }}
        ref={ref}
        data-testid="conversationHeader"
      >
        <SPBackButton backLink={backLink} />
        <div
          className={`subject mt-[8px] overflow-hidden text-ellipsis whitespace-nowrap p-[6px_8px_6px_1px] text-[16px] font-semibold leading-[1]`}
          style={{ color: 'rgba(0,0,0,0.8)' }}
          aria-label="メールタイトル"
        >
          {Boolean(message.attachments.length > 0) && (
            <Attach className="mr-2 align-sub" />
          )}
          <CmpTooltip title={message.subject}>{message.subject}</CmpTooltip>
        </div>
        <div
          className={`mt-[8px] flex flex-col justify-between gap-[10px] md:flex-row md:gap-0`}
        >
          <div className={`order-2 flex items-center pr-[16px] md:order-none`}>
            <Tags
              message={message}
              messages={messages || [message]}
              onRemoveTag={props.onRemoveTag}
            />
          </div>

          <div className={`order-1 flex items-center md:order-none`}>
            <CmpSelect
              value={message.assignee ?? (null as any)}
              style={{ minWidth: 160 }}
              onChange={(value) =>
                updateAssignee((value ?? null) as string | null)
              }
              disabled={isReadOnly}
            >
              {/* TODO: FIX Warning: Each child in a list should have a unique "key" prop */}
              <CmpOptGroup label="担当者">
                {assigneeOptions.map((o) =>
                  o.user ? (
                    <CmpOption
                      value={o.user.id as any}
                      key={o.user.id}
                      disabled={!o.isTeamMember}
                    >
                      <Avatar
                        user={o.user}
                        size="small"
                        style={{ marginTop: -3 }}
                      />
                      <span className={`ml-[4px]`}>
                        {o.isTeamMember ? null : '(チーム外)'}
                        {o.user.name}
                      </span>
                    </CmpOption>
                  ) : (
                    <CmpOption key={'none'} value={null as any}>
                      担当者未設定
                    </CmpOption>
                  )
                )}
              </CmpOptGroup>
            </CmpSelect>
            <div className="status ml-[8px] flex gap-2">
              {message.deleted ? (
                <>
                  <Button
                    icon="delete"
                    onClick={deleteMessages}
                    className="border-none bg-[#7a7a7a] text-white disabled:opacity-50"
                    disabled={isReadOnly}
                  >
                    完全に削除
                  </Button>
                  <Tooltip title="元に戻す" visible={!isReadOnly}>
                    <Button
                      icon="rollback"
                      onClick={restoreMessages}
                      disabled={isReadOnly}
                      aria-label="元に戻す"
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  title="返信中はステータス変更ができません"
                  visible={!isReadOnly && locked}
                >
                  {statusSelect || (
                    <Button
                      icon="check"
                      type={message.status === '未対応' ? 'dashed' : 'default'}
                      onClick={() =>
                        updateStatus(
                          buildFrontSystemMessageStatus(
                            message.status === '未対応' ? '対応済み' : '未対応'
                          )
                        )
                      }
                      disabled={isReadOnly || locked}
                      style={{
                        ...(message.status === '対応済み' && !locked
                          ? {
                              background: '#87d068',
                              borderColor: '#87d068',
                              color: 'white',
                              borderRadius: '8px',
                            }
                          : {
                              borderRadius: '8px',
                            }),
                      }}
                    >
                      {message.status === '未対応'
                        ? '対応済みにする'
                        : message.status}
                    </Button>
                  )}
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);
