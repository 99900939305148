import { Comment } from 'App/Common/Comment';
import { DefaultInput } from 'App/Common/Input';
import { SettingField } from './SettingField';

export const SettingBasicInputInputField: React.FC<{
  label: string;
  value?: string;
  placeholder?: string;
  note?: string;
  readonly?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}> = ({ label, value, placeholder, note, readonly, disabled, onChange }) => (
  <SettingField label={label}>
    <div>
      <DefaultInput
        className={`input h-[40px]`}
        value={value}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange && onChange(e.target.value)
        }
        readOnly={readonly}
        disabled={disabled}
      ></DefaultInput>
      <Comment className={`note mt-[14px]`}>{note}</Comment>
    </div>
  </SettingField>
);
