import { Button } from '../../../../../components/basics';
import { InboxesEmpty } from '../InboxEmpty/InboxesEmpty';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';
import React, { ReactElement, ReactNode } from 'react';
import { EmailAddDrawer } from '../EmailAddDrawer/EmailAddDrawer';
import { InboxType } from '../../../../../firestore/entity/inbox';

type Inbox = {
  id: string;
  name: string;
  yaritoriName: string;
  email: string;
  forwardTo?: string;
  slackIntegrationChannel?: string;
  chatworkIntegrationRoomId?: number;
  autoCc: string;
  autoBcc: string;
  defaultSignatureId: string | null;
  smtp: boolean;
  type: InboxType;
  error?: string;
  isOneClickUnsubscribeEnabled?: boolean;
};

type Signature = {
  id: string;
  name: string;
};

export type InboxesProps = {
  inboxes: Inbox[];
  signatures: Signature[];
  addState: 'loading' | 'max_inboxes' | 'available';
  isPrivateTeam: boolean;
  readonly: boolean;
  addDialogOpen: boolean;
  onChangeAddDialogOpen: (open: boolean) => void;
  editingInboxId: string | undefined;
  onChangeEditingInboxId: (id: string | undefined) => void;
  onAddByEmailAddress: (email: string) => Promise<void>;
  onAddByGoogleOauth: () => Promise<void>;
  onAddByMicrosoftOauth: () => Promise<void>;
  renderEditDrawer: (inboxId: string) => ReactNode;
  drawerContainer?: HTMLElement;
};

export const Inboxes = ({
  inboxes,
  addState,
  isPrivateTeam,
  readonly,
  addDialogOpen,
  onChangeAddDialogOpen,
  editingInboxId,
  onChangeEditingInboxId,
  onAddByEmailAddress,
  onAddByGoogleOauth,
  onAddByMicrosoftOauth,
  renderEditDrawer,
  drawerContainer,
}: InboxesProps) => {
  const handleAdd = () => {
    onChangeAddDialogOpen(true);
  };
  const handleEdit = (id: string) => {
    onChangeEditingInboxId(id);
  };
  let tooltipContent: string | ReactElement = '';
  if (readonly) {
    tooltipContent = '権限がありません';
  } else if (addState === 'max_inboxes') {
    tooltipContent = (
      <>
        <p>
          現在のプランでのメールアドレス数の上限に達しているため作成できません。
        </p>
        <p>
          プラン変更をご希望の場合は、左列「お支払い」から実施していただくことが可能です。
        </p>
        <p>
          プランを再度下げる場合は、登録アドレスを規定の数以下にしたうえで、手動でプランを変更していただく必要がある点のみご留意下さい。
        </p>
        <p>ご不明点がある場合は、右上のチャットよりお気軽にご連絡ください。</p>
      </>
    );
  }

  const editingInbox = inboxes.find((i) => i.id === editingInboxId);

  return (
    <>
      {inboxes.length === 0 ? (
        <InboxesEmpty
          onAdd={() => handleAdd()}
          isPrivateTeam={isPrivateTeam}
          readonly={readonly}
        />
      ) : (
        <div className="flex flex-col items-start gap-4 text-sm">
          <Tooltip content={tooltipContent} visible={!!tooltipContent}>
            <Button
              onClick={() => handleAdd()}
              disabled={readonly || addState !== 'available'}
              loading={addState === 'loading'}
            >
              新規追加
            </Button>
          </Tooltip>
          <div className="text-base font-bold">設定中のメール</div>
          <div className="flex w-[640px] flex-col gap-2">
            {inboxes.map((inbox, i) => (
              <div
                aria-label="受信メール"
                key={i}
                className="group grid grid-cols-[250px_230px_auto_auto] items-center gap-2 hover:bg-sumi-100"
              >
                <div className="overflow-hidden truncate whitespace-nowrap text-base">
                  {inbox.email}
                </div>
                <div className="flex justify-end">
                  <Button
                    variant="text"
                    onClick={() => handleEdit(inbox.id)}
                    disabled={readonly}
                  >
                    詳細
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <EmailAddDrawer
        open={addDialogOpen}
        onOpenChange={onChangeAddDialogOpen}
        onAddByEmailAddress={onAddByEmailAddress}
        onAddByGoogleOauth={onAddByGoogleOauth}
        onAddByMicrosoftOauth={onAddByMicrosoftOauth}
        container={drawerContainer}
      />
      {editingInbox && renderEditDrawer(editingInbox.id)}
    </>
  );
};
