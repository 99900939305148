import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Avatar from '../../../../../../Common/Avatar';
import { highlightMention } from '../../../../../../../util';

class Index extends Component {
  render() {
    const { message, comment } = this.props;
    const latestComment = comment || message.latestComment; // コメントが指定されている場合は使用する
    if (!latestComment) return <></>; // コメントが存在しない場合

    const commenter = this.props.store.getUser(latestComment.commenter);

    return (
      <div
        className="mt-[8px] flex items-center"
        aria-label="最新メールコメント"
      >
        <div>
          <Avatar size="small" user={commenter} />
        </div>
        <div className="ml-[8px] overflow-hidden text-ellipsis whitespace-nowrap rounded-[8px] bg-[#f8f8fb] px-[12px] py-[8px] text-[12px]">
          {highlightMention(
            latestComment.text,
            this.props.store.users,
            this.props.store.groupStore.groups
          )}
        </div>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
