import React, { useState } from 'react';
import { Icon, Popover } from 'antd';
import { isSP } from '../../../shared/util';
import { CreateMessageWysiwygEditor } from './CreateMessageWysiwygEditor';

export default ({
  teamSignatures,
  signature,
  submitting,
  setSignature,
  setSignatureBody,
  addSignature,
  removeSignature,
  readOnly,
  plainTextMode,
  htmlToolbarVisible,
  checkSignatureToolbarVisible,
  initEditorHandle,
  uploadImage,
  bubbleMenuContainerId,
}) => {
  const [hover, setHover] = useState(false);

  if (!signature) {
    if (readOnly || !teamSignatures || teamSignatures.length === 0) {
      return <div />;
    }
    return (
      <button
        type="button"
        className="block w-fit cursor-pointer rounded bg-transparent p-1 text-xs text-[#c5c6c5] hover:bg-[#F0F0F1] hover:text-[#000000a6]"
        onClick={addSignature}
      >
        <Icon className={`mr-[4px]`} type="plus" />
        署名を追加
      </button>
    );
  }

  const onFocus = () => {
    if (htmlToolbarVisible) checkSignatureToolbarVisible(true);
  };

  const onBlur = () => {
    checkSignatureToolbarVisible(false);
  };

  return (
    <div
      className={`relative`}
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      {!readOnly && (
        <div className={`absolute right-0 top-0 z-[1] pr-[8px]`}>
          <Popover
            content={
              <div className="m-[-12px_-16px]">
                {teamSignatures.map((s) => (
                  <div
                    className="cursor-pointer p-[8px] hover:bg-[#f0f0f1]"
                    onClick={() => setSignature(s.signature)}
                    key={s.id}
                  >
                    <Icon
                      className={`mr-[4px]`}
                      style={{
                        visibility:
                          signature.id === s.id ? 'visible' : 'hidden',
                      }}
                      type="check"
                    />
                    {s.title}
                  </div>
                ))}
              </div>
            }
            trigger={isSP() ? 'click' : 'hover'}
          >
            <Icon
              className={`mr-[8px] cursor-pointer`}
              style={{ visibility: isSP() || hover ? 'visible' : 'hidden' }}
              type="up-circle"
              theme="filled"
            />
          </Popover>

          <Icon
            className={`mr-[8px] cursor-pointer`}
            style={{ visibility: isSP() || hover ? 'visible' : 'hidden' }}
            type="close-circle"
            theme="filled"
            onClick={removeSignature}
          />
        </div>
      )}
      <CreateMessageWysiwygEditor
        editorClassName="hover:bg-sumi-100"
        plainTextMode={plainTextMode}
        defaultValue={signature.bodyHtml}
        onChange={setSignatureBody}
        disabled={submitting || readOnly}
        onFocus={readOnly ? undefined : onFocus}
        onBlur={readOnly ? undefined : onBlur}
        initEditorHandle={initEditorHandle}
        uploadImage={uploadImage}
        bubbleMenuContainerId={bubbleMenuContainerId}
      />
    </div>
  );
};
