import React, { useState, useCallback, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Icon } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import '../../../css/pdf.css';
import 'react-image-lightbox/style.css';

const PreviewPdfModal = ({ open, onClose, file, onDownload }) => {
  const [iframe, setIframe] = useState(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfContents, setPdfContents] = useState([]);

  const onLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages);
  }, []);

  const onPrint = useCallback(() => {
    if (!iframeLoaded || !iframe) return;

    iframe.focus();
    iframe.contentWindow.print();
  }, [iframe, iframeLoaded]);

  // 印刷用にiframeにてpdfを読み込んでいる。
  useEffect(() => {
    if (!open) {
      return;
    }

    setIframeLoaded(false);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';

    iframe.onload = () => {
      setIframeLoaded(true);
    };

    const dataUrl = window.URL.createObjectURL(file);
    iframe.src = dataUrl;
    setIframe(iframe);
  }, [file, open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const _pdfContents = [];
    for (let i = 0; i < totalPages; i++) {
      const d = (
        <Page
          className={`m-[0_auto_20px]`}
          key={i}
          pageNumber={i + 1}
          scale={4 / 3}
          renderAnnotationLayer={false}
        />
      );
      _pdfContents.push(d);
    }
    setPdfContents(_pdfContents);
  }, [open, totalPages]);

  if (!open) {
    return <></>;
  }

  return (
    <div className={`fixed bottom-0 left-0 right-0 top-0 z-[1000]`}>
      <div
        className={`bg-[rgba(0, 0, 0, 0.5)] fixed z-[1] flex h-[50px] w-full items-center justify-between`}
      >
        <ul className={`m-0 h-[50px] pl-[20px] pr-0`} />
        <ul className={`m-0 h-[50px] pl-0 pr-[20px]`}>
          <li
            className={`l inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.2em] leading-[50px] text-white`}
          >
            {iframeLoaded && (
              <button
                className={`h-[35px] w-[40px] cursor-pointer border-none bg-transparent px-[6px] py-[1px] align-middle opacity-[0.7] hover:opacity-[1]`}
                onClick={onPrint}
              >
                <PrinterOutlined
                  style={{ fontSize: 24, verticalAlign: 'initial' }}
                />
              </button>
            )}
            <button
              className={`h-[35px] w-[40px] cursor-pointer border-none bg-transparent px-[6px] py-[1px] align-middle opacity-[0.7] hover:opacity-[1]`}
              onClick={onDownload}
            >
              <Icon
                type="download"
                style={{ fontSize: 24, verticalAlign: 'initial' }}
              />
            </button>
            <button
              className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
              onClick={onClose}
            />
          </li>
        </ul>
      </div>
      <div
        className={`bg-[rgba(0, 0, 0, 0.85)] h-full w-full overflow-scroll pt-[50px]`}
      >
        <Document
          className={`h-full`}
          file={file}
          onLoadSuccess={onLoadSuccess}
          // NOTE: 日本語が上手く表示できないことがあるため、cmapを設定している。
          // https://github.com/wojtekmaj/react-pdf#support-for-non-latin-characters
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
          }}
        >
          {pdfContents}
        </Document>
      </div>
    </div>
  );
};

export default PreviewPdfModal;

export const isPdfMimeType = (mimeType = '') => mimeType === 'application/pdf';
