import React from 'react';
import { Avatar, Tag } from 'antd';
import * as color from '../../../color';
import level from '../../../font/level';
import { PCOnly, SPOnly } from '../../Common/MediaQuery';
import moment from 'moment';
import { decorateHtml, generateHtml } from 'lib';
import FullHeightIframe from '../Conversations/Conversation/Message/FullHeightIframe';

const ValueComponent = ({ value, title }) => {
  if (!value) return <div />;

  if (Array.isArray(value))
    return value.length > 0 ? (
      <div className={addressesClassName}>
        {title}:{' '}
        {value.map((v) => (
          <Tag key={v} style={{ whiteSpace: 'break-spaces' }}>
            {v}
          </Tag>
        ))}
      </div>
    ) : (
      <div />
    );

  return (
    <div className={addressesClassName}>
      {title}: <Tag style={{ whiteSpace: 'break-spaces' }}>{value}</Tag>
    </div>
  );
};

export default ({
  to,
  cc,
  bcc,
  subject,
  signature,
  bodyHtml,
  inboxId,
  getInbox,
}) => {
  // 署名
  const signatureBody = signature?.bodyHtml ? '\n\n' + signature.bodyHtml : '';
  // 本文（HTML）
  const willSendHtmlBody = generateHtml({
    body: bodyHtml,
    signatureBody: signatureBody,
    quotedBody: '', // 引用はsending時に表示しない
  });
  const inbox = getInbox(inboxId);

  return (
    <div
      className={`p-[20px_0] sm:m-[-16px] sm:rounded-[10px]`}
      style={{
        backgroundColor: color.common.white,
        boxShadow: '0 1px 6px rgba(0, 0, 0, 0.16)',
      }}
    >
      <div className="border-b-[0.5px] border-[#f1f1f3] px-[30px] pb-[20px]">
        <SPOnly>
          <div className="text-[#8d8d8d]" style={{ fontSize: level[0] }}>
            {moment().format('YYYY年M月D日 HH:mm')}
          </div>
        </SPOnly>
        <div className="flex" style={{ justifyContent: 'space-between' }}>
          <div>
            <h2 className="mb-[16px] font-bold" style={{ fontSize: level[3] }}>
              {subject}
            </h2>
            <div className="flex">
              <Avatar size="large">{inbox.emailIconName}</Avatar>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <div style={{ fontSize: level[2] }}>
                  <span>{inbox.email}</span>
                </div>
                <div className={addressesClassName}>
                  <ValueComponent value={to} title="宛先" />
                </div>
                <div className={addressesClassName}>
                  <ValueComponent value={cc} title="Cc" />
                </div>
                <div className={addressesClassName}>
                  <ValueComponent value={bcc} title="Bcc" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex" style={{ justifyContent: 'space-between' }}>
              <PCOnly>
                <div className="flex items-center">
                  <div
                    className="text-[#8d8d8d]"
                    style={{ fontSize: level[0] }}
                  >
                    {moment().format('YYYY年M月D日 HH:mm')}
                  </div>
                </div>
              </PCOnly>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[30px] pt-[20px]">
        <p className="mb-[20px]" style={{ fontSize: level[1] }}>
          <FullHeightIframe srcDoc={decorateHtml(willSendHtmlBody)} />
        </p>
      </div>
    </div>
  );
};

const addressesClassName = 'mt-[4px]';
