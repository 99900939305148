import { SearchQuery } from '../../../../store/search';
import { SearchRange } from './useSearch';

export const getSearchRange = (query: SearchQuery): SearchRange => {
  if (!query.teamIds || query.teamIds.length === 0) {
    return { type: 'allInboxes' };
  }

  const teamId = query.teamIds[0];
  if (query.inboxId) {
    return { type: 'inbox', teamId, inboxId: query.inboxId };
  } else {
    return { type: 'teamInboxes', teamId };
  }
};

export const searchRangeToQuery = (
  searchRange: SearchRange
): Partial<SearchQuery> => {
  return {
    teamIds:
      searchRange.type === 'teamInboxes' || searchRange.type === 'inbox'
        ? [searchRange.teamId]
        : undefined,
    inbox: searchRange.type === 'inbox' || undefined,
    inboxId: searchRange.type === 'inbox' ? searchRange.inboxId : undefined,
  };
};
