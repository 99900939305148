import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import level from '../../../font/level';

class Index extends Component {
  render() {
    return (
      <div className={`break-words font-bold`} style={{ fontSize: level[3] }}>
        {this.props.children}
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
