import { message as antdMessage, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { GroupMember } from './GroupMember';
import { useStore } from '../../../../hooks/useStore';
import { DefaultInput } from '../../../Common/Input';
import { H3 } from '../../../Common/H3';
import { DefaultButton } from '../../../Common/Button';
import { H1 } from '../../../Common/H1';
import _ from 'lodash';
import { Group } from '../../../../store/entity';
import { User } from 'lib';

type Props = {
  group?: Group;
  visible: boolean;
  close: () => void;
};

export const GroupModal = ({ group, visible, close }: Props): JSX.Element => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [saving, setSaving] = useState(false);

  const store = useStore();
  const { groupStore } = store;

  useEffect(() => {
    if (visible) {
      setError('');
      if (!group) {
        setName('');
        setUsers(store.users);
        setMembers([]);
        return;
      }
      const memberIds = group.members;
      setName(group.name);
      setUsers(store.users.filter((user) => !memberIds.includes(user.id)));
      setMembers(store.users.filter((user) => memberIds.includes(user.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, visible]);

  const updateUsers = (action: (prev: User[]) => User[]) => {
    setUsers((prev) => _.sortBy(action(prev), 'name'));
  };

  const addMember = (user: User) => {
    updateUsers((prev) => prev.filter((p) => p.id !== user.id));
    setMembers((prev) => [...prev, user]);
  };

  const removeMember = (user: User) => {
    setMembers((prev) => prev.filter((p) => p.id !== user.id));
    updateUsers((prev) => [...prev, user]);
  };

  const onClickSave = async () => {
    const trimmedName = name.trim();
    const g = groupStore.getGroupByName(trimmedName);
    if (g && g.id !== group?.id) {
      setError('すでに作成されているグループ名です');
      return;
    } else {
      setError('');
    }

    setSaving(true);
    if (!group) {
      await groupStore.addGroup(
        trimmedName,
        members.map((member) => member.id)
      );
      antdMessage.success('グループを作成しました');
      close();
    } else {
      await groupStore.updateGroup(group.id, {
        name: trimmedName,
        members: members.map((member) => member.id),
      });
      antdMessage.success('グループを更新しました');
    }
    setSaving(false);
  };

  return (
    <Modal visible={visible} onCancel={close} footer={null} width={600}>
      <H1 className={`mt-[30px] pb-[30px] text-center`}>
        {group ? 'グループ設定' : '新規グループ'}
      </H1>
      <H3 className={H3ClassName}>表示名</H3>
      <div className={`mb-[12px] px-[32px] py-0`}>
        <DefaultInput
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
        {error && <div className="text-red-500">{error}</div>}
      </div>
      <H3 className={H3ClassName}>メンバーを追加する</H3>
      <div className={UserClassName}>
        {users.length
          ? users.map((user) => (
              <GroupMember
                key={user.id}
                user={user}
                onClick={() => addMember(user)}
              />
            ))
          : '追加可能なメンバーがいません'}
      </div>
      <H3 className={H3ClassName}>追加済みのメンバー</H3>
      <div className={UserClassName}>
        {members.length
          ? members.map((member) => (
              <GroupMember
                key={member.id}
                user={member}
                onClick={() => removeMember(member)}
                inGroup
              />
            ))
          : 'メンバーがまだ追加されていません'}
      </div>
      <div className={`mt-[16px] flex justify-center gap-[32px]`}>
        <DefaultButton
          className={`h-[40px] w-[100px] max-w-[100px]`}
          type="danger"
          onClick={close}
        >
          キャンセル
        </DefaultButton>
        <DefaultButton
          className={`h-[40px] w-[100px] max-w-[100px]`}
          type="primary"
          onClick={onClickSave}
          disabled={!name || saving}
        >
          保存
        </DefaultButton>
      </div>
    </Modal>
  );
};

const H3ClassName = `mb-[4px] px-[32px] py-0`;
const UserClassName = `mb-[16px] max-h-[30vh] w-full overflow-auto px-[32px] py-0`;
