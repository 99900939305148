import { Select } from 'antd';
import { SettingField } from 'App/Settings/common';
import { Signature } from 'lib';
import { Comment } from 'App/Common/Comment';
type Props = {
  disabled?: boolean;
  signatureId: string | null;
  signatures: Signature[];
  onSelect?: (signatureId: string | null) => void;
};

export const DefaultSignatureField: React.FC<Props> = (props) => {
  return (
    <SettingField label="デフォルトの署名">
      <div>
        <Select
          className={`select min-w-[160px]`}
          size="large"
          value={
            props.signatures
              .map((s) => s.id)
              .find((id) => id === props.signatureId) || null
          }
          onSelect={props.onSelect}
          disabled={props.disabled}
        >
          <Select.Option value={null as any}>なし</Select.Option>
          {props.signatures.map((signature) => (
            <Select.Option value={signature.id} key={signature.id}>
              {signature.title}
            </Select.Option>
          ))}
        </Select>
        <Comment className={`note mt-[14px]`}>
          メール作成時、自動的に署名に追加されます。
        </Comment>
      </div>
    </SettingField>
  );
};
