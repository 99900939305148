import { ReactNode, useEffect, useRef } from 'react';
import { SettingPageContainer } from '../../../../App/Settings/common/SettingPageContainer/SettingPageContainer';
import { Button, Icon } from '../../../basics';
import { Alert } from '../../../basics/Alert/Alert';
import { Check } from '../../../icons';
import { range } from 'lodash';
import { DeliveryMessageCardSkeleton } from '../DeliveryMessageCardSkeleton/DeliveryMessageCardSkeleton';

type Props = {
  onCreateMessage: () => void;
  loading: boolean;
  children: ReactNode;
  readonly?: boolean;
  noAlphaVersionHint?: boolean;
};

export const DeliveryMessagesPage = ({
  onCreateMessage,
  loading,
  children,
  readonly = false,
  noAlphaVersionHint = false,
}: Props) => {
  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onMove = (direction: 1 | -1) => {
      if (!listRef.current) {
        return false;
      }
      const children = [...listRef.current.children] as HTMLElement[];
      const focusedIndex = children.findIndex(
        (el) => el === document.activeElement
      );
      if (focusedIndex === -1) {
        return false;
      }

      const targetIndex = focusedIndex + direction;
      if (targetIndex < 0 || targetIndex >= children.length) {
        return true;
      }

      const targetElement = children[targetIndex];
      targetElement.focus();
      return true;
    };
    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          if (onMove(1)) {
            e.preventDefault();
          }
          break;
        case 'ArrowUp':
          if (onMove(-1)) {
            e.preventDefault();
          }
          break;
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);
  return (
    <SettingPageContainer title="メール一斉送信" full>
      <div className="mb-4 flex flex-col gap-4 text-sm">
        {!noAlphaVersionHint && (
          <Alert>
            α版につき、すべての機能を無料でお試しいただけます。
            但し、署面に「yaritoriから送信」が入ります。
          </Alert>
        )}
        {[
          '大量のメールを一斉に送信できます。メルマガ・お知らせなどを遅滞なく効率的に送信することができます。',
          '生成AIによりメール文の作成ができます。今後はAI機能のアップデートや分析機能などを充実させていきます。',
        ].map((message, i) => (
          <div key={i} className="grid grid-cols-[auto_1fr] items-center gap-2">
            <Icon icon={Check} size={24} className="text-sea-500" />
            <div>{message}</div>
          </div>
        ))}
        <Button
          className="self-start"
          onClick={() => onCreateMessage()}
          disabled={readonly}
        >
          メールを作成
        </Button>
        <div className="flex flex-col gap-4" ref={listRef}>
          {!loading && children}
          {loading &&
            range(0, 3).map((i) => <DeliveryMessageCardSkeleton key={i} />)}
        </div>
      </div>
    </SettingPageContainer>
  );
};
