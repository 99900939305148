import React, { useEffect, useState, VFC } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { MobileNavButton } from '../../Top/MobileNavButton';
import { MobileSearchInput } from './MobileSearchInput';
import { useSearch } from './search/useSearch';
import { MobileSearchFilter } from './MobileSearchFilter';
import { History, Menu } from '../../../components/icons';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Icon } from '../../../components/basics';
import { getFilterText } from './filter';
import { tv } from 'tailwind-variants';
import { Controller } from 'react-hook-form';

const header = tv({
  base: 'grid h-header w-full items-center bg-white',
  variants: {
    icon: {
      true: 'grid-cols-[auto_1fr] gap-2 pl-2 pr-4',
      false: 'px-4',
    },
  },
});

export const MobileNav: VFC = observer(() => {
  const [focused, setFocused] = useState(false);
  const search = useSearch(() => setFocused(false));
  const store = useStore();
  const isSearchResultPage = useRouteMatch('/search/:tray/:query');
  const { pathname } = useLocation();

  useDisableScrolls(focused);

  useEffect(() => {
    setFocused(false);
  }, [pathname]);

  return (
    <>
      <div>
        <div className={header({ icon: !focused })}>
          {!focused && (
            <MobileNavButton icon={Menu} onClick={store.openDrawer} />
          )}
          <div className="h-10 w-full">
            <Controller
              control={search.filterForm.control}
              name="keyword"
              render={({ field }) => (
                <MobileSearchInput
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                  onSubmit={() => search.search()}
                  onFocus={() => setFocused(true)}
                  onBack={() => setFocused(false)}
                  focused={focused}
                />
              )}
            />
          </div>
        </div>
        {focused && (
          <div className="fixed left-0 z-40 flex h-[calc(100%_-_theme(height.header)_-_theme(height.mobile-search-filter))] w-full flex-col gap-2 bg-sumi-50 py-4">
            {store.searchStore.history.length > 0 && (
              <div className="px-4 text-sm font-bold text-sumi-600">
                最近行った検索
              </div>
            )}
            <div className="px-2">
              {store.searchStore.history.map((entry, i) => {
                const text = getFilterText(store, entry.query);
                const to = `/search/${entry.tray}/${encodeURIComponent(
                  JSON.stringify(entry.query)
                )}`;
                return (
                  <Link
                    key={i}
                    to={to}
                    className="grid w-full cursor-pointer grid-cols-[24px_1fr] items-center gap-2 rounded bg-transparent p-0 px-2 py-2 text-start text-sumi-900 hover:bg-sumi-200"
                  >
                    <Icon icon={History} size={24} />
                    <div className="text-sm">{text}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        {(focused || isSearchResultPage) && (
          <MobileSearchFilter search={search} />
        )}
      </div>
    </>
  );
});

const useDisableScrolls = (value: boolean) => {
  useEffect(() => {
    if (value) {
      const html = document.documentElement;
      const body = document.body;
      html.style.setProperty('overscroll-behavior-y', 'none');
      html.style.setProperty('overflow', 'hidden');
      body.style.setProperty('overflow', 'hidden');
      body.style.setProperty('height', '200px');
      return () => {
        html.style.removeProperty('overscroll-behavior-y');
        html.style.removeProperty('overflow');
        body.style.removeProperty('overflow');
        body.style.removeProperty('height');
      };
    }
  }, [value]);
};
