import React from 'react';
import { Result, Button } from 'antd';
import * as color from '../../color';

function NotFound(): React.ReactElement {
  return (
    <div
      style={{ backgroundColor: color.sidebar.background.normal }}
      className={`flex min-h-[100vh] flex-col items-center justify-center`}
    >
      <Result
        status="404"
        title="404"
        subTitle="指定されたページが存在しません。"
        extra={
          <Button type="primary" onClick={() => (window.location.href = '/')}>
            トップへ
          </Button>
        }
      />
    </div>
  );
}

export default NotFound;
