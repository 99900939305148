import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { addOAuth } from 'functions';
import { useStore } from 'hooks/useStore';
import { Alert, Badge, Button, Icon, Modal, Spin, Select } from 'antd';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as SalesforceIcon } from '../../../../assets/icons/salesforce.svg';
import Tooltip from '../../../Common/Tooltip';

const { confirm } = Modal;
const { Option } = Select;

const redirectUri =
  document.location.origin + '/settings/integrations/salesforce';
const authorizeUrl = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${redirectUri}`;

async function integrateSalesforce(companyId: string, code: string) {
  await addOAuth({
    service: 'salesforce',
    companyId,
    code,
    extra: {
      redirectUri,
    },
  });
}

export const SalesforceIntegration = observer((): JSX.Element => {
  const store = useStore();
  const location = useLocation();
  const history = useHistory();
  const [isIntegrating, setIsIntegrating] = useState(false);
  const [isUnintegrating, setIsUnintegrating] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/settings/integrations/salesforce' &&
      location.search
    ) {
      const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (query.code) {
        setIsIntegrating(true);
        integrateSalesforce(store.signInCompany, query.code as string).finally(
          () => {
            setIsIntegrating(false);
            history.replace('/settings/integrations');
          }
        );
      }
    }
  }, [location]);

  const onSelectInterfaceType = async (
    interfaceType: 'lightningExperience' | 'classic'
  ) => {
    await store.settingsStore.updateDoc('salesforce', {
      interfaceType: interfaceType,
    });
  };

  const unintegrate = () => {
    confirm({
      title: 'Salesforceとの連携を解除してよろしいですか？',
      content: '※解除後でも、再度連携が可能です。',
      okText: '解除',
      cancelText: 'キャンセル',
      onOk: async () => {
        setIsUnintegrating(true);
        await store.oauthStore.unintegrate('salesforce');
        setIsUnintegrating(false);
      },
      onCancel() {
        //
      },
      okType: 'danger',
      maskClosable: true,
    });
  };

  const integration: any =
    store.oauthStore.salesforceIntegration?.get('data') || {};
  const { isAdmin } = store.me;
  const integrated = store.oauthStore.salesforceIntegrated;
  const interfaceType = store.settingsStore.salesforce?.get('interfaceType');

  return (
    <>
      <div className={`mt-[12px]`}>
        <Badge status={integrated ? 'success' : 'default'} />
        {integrated ? '連携されています' : '連携されていません'}
        {integrated ? (
          <Tooltip
            className={`mt-[5px] block max-w-[250px]`}
            title="権限がありません"
            visible={!store.me.isAdmin}
          >
            <Select
              onSelect={(value) => onSelectInterfaceType(value as any)}
              value={interfaceType ? interfaceType : 'lightningExperience'}
              disabled={!store.me.isAdmin}
            >
              <Option value="lightningExperience">
                Salesforce Lightningを利用中
              </Option>
              <Option value="classic">Salesforce Classicを利用中</Option>
            </Select>
          </Tooltip>
        ) : (
          ''
        )}
        {integration.error_description && (
          <> ({integration.error_description})</>
        )}
      </div>
      {isAdmin && store.isCRMIntegrationSupported ? (
        <div className={`mt-[12px]`}>
          {integrated ? (
            <Button
              type="danger"
              onClick={unintegrate}
              loading={isUnintegrating}
              disabled={isUnintegrating || !isAdmin}
              style={{ marginTop: 10 }}
            >
              連携を解除
            </Button>
          ) : (
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
              spinning={isIntegrating}
              style={{ width: 140 }}
            >
              <a
                className={`inline-flex items-center gap-[0.5rem] rounded-[0.5rem] border border-solid border-[rgb(198,202,204)] p-[0.5rem_1rem] text-[0.875rem] text-inherit`}
                href={authorizeUrl}
              >
                <SalesforceIcon style={{ height: '1.5rem' }} />
                Salesforceと連携
              </a>
            </Spin>
          )}
        </div>
      ) : (
        <>
          {!isAdmin ? (
            <Alert type="warning" message="連携を実行する権限がありません" />
          ) : (
            ''
          )}
          {!store.isCRMIntegrationSupported ? (
            <Alert
              type="warning"
              message="現在のプランでは連携を実行できません"
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
});
