import { Badge } from 'antd';

type Props = {
  className?: string;
  integrated?: boolean;
  integratedWith?: string;
};
export const IntegrationStatus: React.FC<Props> = (props) => {
  const getMessage = () => {
    if (props.integrated) {
      return props.integratedWith
        ? `${props.integratedWith}と連携されています`
        : `連携されています`;
    } else {
      return '連携されていません';
    }
  };
  return (
    <div className={props.className}>
      <Badge status={props.integrated ? 'success' : 'default'} />
      {getMessage()}
    </div>
  );
};
