import { Alert, Button, Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { IntegrationStatus } from '../../IntegrationStatus';

type Props = {
  userName: string;
  noPermission?: boolean;
  needsReintegration?: boolean;
  notSupported?: boolean;
  isUnintegrating?: boolean;
  onUnintegrate?: () => void;
  authorizeUrl: string;
};

export const ChatworkIntegrationEnabled: React.FC<Props> = observer((props) => {
  return (
    <div>
      <IntegrationStatus
        className="status"
        integrated
        integratedWith={props.userName}
      />
      <Tooltip title="権限がありません" visible={props.noPermission}>
        {props.needsReintegration && (
          <Alert
            className="reintegrate mt-[12px]"
            message={
              <Row type="flex" justify="space-between" align="middle">
                <Col>通知に必要な権限の取得のため再連携の必要があります</Col>
                <Col>
                  <Tooltip
                    title={
                      props.notSupported &&
                      'Chatwork通知はこのプランではサポートされていません'
                    }
                  >
                    <Button
                      type="primary"
                      href={props.authorizeUrl}
                      disabled={props.noPermission || props.notSupported}
                    >
                      再連携
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            }
          />
        )}
        <Button
          className="unintegrate-button mt-[12px]"
          type="danger"
          onClick={props.onUnintegrate}
          loading={props.isUnintegrating}
          disabled={props.noPermission || props.isUnintegrating}
        >
          連携を解除
        </Button>
      </Tooltip>
    </div>
  );
});
