import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Skeleton } from 'antd';

import { eventNames, logEvent } from 'analytics';
import store from 'store';
import { useContactNameByEmail } from 'App/Top/MessageList/Teams/Messages/Message/contact';

/**
 * @typedef Props
 * @property {React.CSSProperties} style
 * @property {string} teamId
 * @property {{address: string, name?: string}} email
 * @property {boolean} [bold]
 * @property {number} [size]
 */

/**
 * @type {React.FC<Props>}
 **/
export const Contact = observer(({ email, teamId, style, bold, size }) => {
  const { name, loading } = useContactNameByEmail({ email, teamId });

  const selectContact = useCallback(() => {
    store.contactStore.selectContact(teamId, email);
    // store.selectedContact = createContact(email, teamId);
    logEvent(eventNames.show_message_histories);
  }, [email, teamId]);

  if (loading) {
    return (
      <Skeleton
        title={{
          width: 240,
          style: { margin: '0 0 5px', background: '#f6f6f7' },
        }}
        paragraph={false}
        active
      />
    );
  }

  const nameBold = bold && name.length > 0;
  const addressBold = bold && (!name || name.length === 0);

  return (
    <Button
      type="link"
      onClick={selectContact}
      style={{ fontSize: size ? size : null, ...style }}
      className={`flex h-[inherit] select-text p-0`}
      title={`${name}<${email.address}>`}
    >
      <span style={{ fontWeight: nameBold ? 600 : null }}>{name}</span>
      <span
        className={'truncate'}
        style={{
          fontWeight: addressBold ? 600 : null,
          marginLeft: '0.125rem',
        }}
      >
        &lt;{email.address}&gt;
      </span>
    </Button>
  );
});
