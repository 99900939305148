import { Alert, Button } from 'antd';
import React from 'react';

type Props = {
  target: string;
  isIntegrated: boolean;
  gotoIntegration: () => void;
};

export const UserNotificationAlert: React.FC<Props> = ({
  target,
  isIntegrated,
  gotoIntegration,
}) => {
  return (
    <Alert
      message={
        <div className={`flex items-center justify-between`}>
          <div>
            {isIntegrated
              ? '通知に必要な権限の取得のため再連携の必要があります'
              : `${target}にyaritoriを連携すると通知を設定できます`}
          </div>
          <div>
            <Button onClick={gotoIntegration} type="primary">
              連携設定
            </Button>
          </div>
        </div>
      }
    />
  );
};
