import { Alert, Button } from 'antd';
import logo from 'assets/icons/chatwork_logo.png';
import { observer } from 'mobx-react';
import { IntegrationStatus } from '../../IntegrationStatus';

export const ChatworkIntegrationDisabled: React.FC<{
  noPermission?: boolean;
  notSupported?: boolean;
  isIntegrating?: boolean;
  onIntegrate?: () => void;
}> = observer((props) => {
  const integratable = !props.noPermission && !props.notSupported;
  return (
    <div>
      <IntegrationStatus className="status" />

      {integratable ? (
        <Button
          className={`integrate-button mt-[12px] flex h-[40px] items-center rounded-[8px] border border-solid border-[#c6cacc] tracking-[0.02em]`}
          size="large"
          onClick={props.onIntegrate}
          loading={props.isIntegrating}
          disabled={props.isIntegrating}
        >
          <img
            className="logo mr-[4px] h-[24px] w-[24px]"
            src={logo}
            alt="chatwork_logo"
          ></img>
          <span className="service-name font-semibold">Chatwork</span>と連携
        </Button>
      ) : (
        <div className="alerts mt-[12px] flex flex-col gap-[8px]">
          {props.noPermission && (
            <Alert type="warning" message={'連携を実行する権限がありません'} />
          )}
          {props.notSupported && (
            <Alert
              type="warning"
              message={'現在のプランではChatwork連携はサポートされていません'}
            />
          )}
        </div>
      )}
    </div>
  );
});
