import { useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import level from '../../../../../../font/level';
import moment from 'moment';
import { MessageItemWrapper } from 'components/MessageList';
import { Checkbox } from 'antd';
import { isSP } from '../../../../../../shared/util';

export default function SentItem({
  item,
  to,
  toFunc,
  checkSent,
  uncheckSent,
  checkedSent,
}) {
  const [hover, setHover] = useState(false);
  const checked = checkedSent.some((s) => s.id === item.id);
  const location = useLocation();
  const match = useMemo(
    () =>
      matchPath(decodeURI(location.pathname), {
        path: decodeURI(to),
      }),
    [location.pathname, to]
  );
  const onClickCheckboxWrapper = (e) => {
    e.stopPropagation();
    if (checked) {
      uncheckSent(item);
      return;
    }
    checkSent(item);
  };
  const showCheckbox = hover || checkedSent.length > 0;
  return (
    <MessageItemWrapper
      onClick={() => toFunc(to)}
      selected={!!match}
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      <div className="pl-5">
        <div className="relative flex justify-between">
          {showCheckbox && (
            <div className="absolute -left-5 ">
              <div
                className="flex w-4 justify-center"
                onClick={onClickCheckboxWrapper}
              >
                <Checkbox checked={checked} />
              </div>
            </div>
          )}
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap pr-[8px] font-bold"
            style={{ fontSize: level[1] }}
          >
            {item.to.join(' ')}
          </div>
          <div
            className="whitespace-nowrap text-[#bcbccb]"
            style={{ fontSize: level[0] }}
          >
            {moment(item.date).isSame(moment(), 'day')
              ? item.date.format('HH:mm')
              : item.date.format('M月D日')}
          </div>
        </div>
        <div
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          style={{ fontSize: level[1] }}
        >
          {item.subject.length > 0 ? item.subject : 'ありません'}
        </div>
        <div
          className="overflow-hidden text-ellipsis whitespace-nowrap text-[#bcbccb]"
          style={{ fontSize: level[1] }}
        >
          {item.text.length > 0 ? item.text : 'ありません'}
        </div>
      </div>
    </MessageItemWrapper>
  );
}
