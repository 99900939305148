import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { ContactsLayout } from './ContactsLayout/ContactsLayout';
import { Button } from '../../components/basics';
import { Tabs } from 'components/basics/Tabs';
import { useAtomValue } from 'jotai';
import { joinedTeamsAtom, privateTeamAtom } from '../../atoms/firestore/team';
import React, { useMemo, useState } from 'react';
import Accounts from './Team/accountList';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { companyAtom, meAtom } from '../../atoms/auth';
import NewAccount from './Team/newAccount';
import EditAccount from './Team/editAccount';
import { db } from '../../firebase';
import { eventNames, logEvent } from '../../analytics';
import { message, Modal } from 'antd';
import {
  DocumentReference,
  getDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Account, AccountData } from 'lib';
import { ContactsDetailWithLogic } from './ContactsDetail/ContactsDetailWithLogic';
import { ContactsImportDialog } from './ContactsDetail/ContactsImportDialog';

type Tab = 'contacts' | 'accounts';

export const ContactsDetailPage = observer(() => {
  const history = useHistory();
  const store = useStore();
  const { isReadOnly } = useAtomValue(meAtom);
  const { teamId } = useParams<{ teamId: string }>();
  const privateTeam = useAtomValue(privateTeamAtom);
  const teams = useAtomValue(joinedTeamsAtom);
  const team = useMemo(
    () => teams.find((t) => t.id === teamId),
    [teams, teamId]
  );
  const currentTab: Tab =
    useRouteMatch('/contacts/:teamId/contacts/accounts') === null
      ? 'contacts'
      : 'accounts';
  const [tab, setTab] = useState<Tab>(currentTab);
  const [keyword, setKeyword] = useState('');
  const contactTags = store.getContactTags(teamId);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [showImport, setShowImport] = useState(false);
  const [exporting, setExporting] = useState(false);

  const onCreate = () => {
    history.push(`/contacts/${teamId}/contacts/${tab}/new`);
  };

  const contacts = useMemo(() => {
    if (tab !== 'contacts') {
      return null;
    }

    return <ContactsDetailWithLogic keyword={keyword} />;
  }, [tab, keyword]);

  const accounts = useMemo(() => {
    if (tab !== 'accounts') {
      return null;
    }

    return (
      <Accounts
        keyword={keyword}
        selectedAccountIds={selectedAccountIds}
        setSelectedAccountIds={setSelectedAccountIds}
        existingTags={contactTags}
        key={teamId}
      />
    );
  }, [keyword, selectedAccountIds, contactTags, teamId]);

  const exportContacts = async () => {
    setExporting(true);
    const key = Date.now();
    try {
      message.loading({ content: 'コンタクトをエクスポート中です', key });
      await store.contactStore.exportContacts(teamId);
      message.success({ content: 'コンタクトをエクスポートしました', key });
    } catch (e) {
      message.error({ content: 'コンタクトのエクスポートに失敗しました', key });
      console.error(e);
    } finally {
      setExporting(false);
    }
  };

  if (!team) {
    return <Redirect to={`/contacts/${privateTeam.id}/contacts/contacts`} />;
  }

  return (
    <ContactsLayout
      buttons={
        <>
          {tab === 'contacts' && (
            <>
              <Button variant="outlined" onClick={() => setShowImport(true)}>
                インポート
              </Button>
              <Button
                variant="outlined"
                onClick={() => exportContacts()}
                disabled={exporting}
              >
                エクスポート
              </Button>
              <ContactsImportDialog
                open={showImport}
                onOpenChange={setShowImport}
              />
            </>
          )}
          <Button onClick={() => onCreate()}>新規作成</Button>
        </>
      }
      onSearch={setKeyword}
      searchInputKey={tab}
    >
      <Tabs
        className="mb-2"
        entries={[
          { value: 'contacts', label: 'コンタクト' },
          { value: 'accounts', label: '取引先' },
        ]}
        value={tab}
        onChange={(tab: Tab) => {
          setTab(tab);
          history.push(`/contacts/${teamId}/contacts/${tab}`);
        }}
      />
      {contacts}
      {accounts}

      {!isReadOnly && (
        <Switch>
          <AccountDrawers />
        </Switch>
      )}
    </ContactsLayout>
  );
});

const AccountDrawers = observer(() => {
  const { teamId } = useParams<{ teamId: string }>();
  const { id: companyId } = useAtomValue(companyAtom);
  const history = useHistory();
  const store = useStore();

  const addAccount = async (account: AccountData) => {
    await db.collection(`companies/${companyId}/accounts`).add({
      ...account,
      teamId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    logEvent(eventNames.add_contact);
    message.success('取引先を作成しました');
    history.push('../accounts');
  };

  const updateAccount = async (
    ref: DocumentReference<AccountData>,
    account: AccountData
  ) => {
    const accountSnapshot = await getDoc(ref);

    if (!accountSnapshot.exists) {
      console.error('Accounts.update: !accountSnapshot.exists:', ref);
    }
    await updateDoc(ref, {
      ...account,
      updatedAt: serverTimestamp(),
    });
    message.success('取引先を更新しました');
    history.push('../accounts');
  };

  const deleteAccount = (account: Account) => {
    Modal.confirm({
      title: `取引先 "${account.name}" を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        await account.ref.delete();
        message.success('取引先を削除しました');
        history.push('../accounts');
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  const checkDuplicateAccountName = async (name: string) => {
    const accounts = await store.getAccountsByName(teamId, name);
    return accounts && accounts.length > 0;
  };

  const checkDuplicateDomains = async (domains: string) => {
    const accounts = await store.getAccountsByDomains(teamId, domains);
    return accounts && accounts.length > 0;
  };

  const getAllContacts = async () => {
    return await store.getContactsByTeamId(teamId);
  };

  return (
    <>
      <Route exact path="/contacts/:teamId/contacts/accounts/:accountId">
        <div
          className={`absolute bottom-0 right-0 top-0 z-20 w-[37%] overflow-auto bg-[#f6f6f7] p-[32px_32px_32px_16px]`}
        >
          <EditAccount
            update={updateAccount}
            delete={deleteAccount}
            checkDuplicateName={checkDuplicateAccountName}
            checkDuplicateDomains={checkDuplicateDomains}
            getAllContacts={getAllContacts}
            getAccount={store.getAccount}
            getContactByAccountId={store.getContactByAccountId}
          />
        </div>
      </Route>

      <Route exact path="/contacts/:teamId/contacts/accounts/new">
        <div
          className={`absolute bottom-0 right-0 top-0 z-20 w-[37%] overflow-auto bg-[#f6f6f7] p-[32px_32px_32px_16px]`}
        >
          <NewAccount
            add={addAccount}
            checkDuplicateName={checkDuplicateAccountName}
            checkDuplicateDomains={checkDuplicateDomains}
          />
        </div>
      </Route>
    </>
  );
});
