import { ReactNode } from 'react';
import { SidebarSearchOverlay } from './SidebarSearchOverlay';
import { useSetAtom } from 'jotai/index';
import { dirtyResetAtom } from '../../../App/Common/MainNav/search/useSearch';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';

type Props = {
  children: ReactNode;
};

export const SidebarSearchOverlayWithLogic = observer(({ children }: Props) => {
  const { searchStore } = useStore();
  const resetFilter = useSetAtom(dirtyResetAtom);
  return (
    <SidebarSearchOverlay
      show={searchStore.inSearch}
      onResetFilter={() => resetFilter()}
    >
      {children}
    </SidebarSearchOverlay>
  );
});
