import { DocumentReference, onSnapshot, Query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { registerUnsubscribe } from '../../firestore';
import { v4 } from 'uuid';

export const useSubscribeDocument = <T>(
  ref: DocumentReference<T> | undefined
): [boolean, T | undefined] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  useEffect(() => {
    if (ref) {
      return registerUnsubscribe(
        v4(),
        onSnapshot(
          ref,
          (snapshot) => {
            if (loading) {
              setLoading(false);
            }
            setData(snapshot.data());
          },
          console.error
        )
      );
    } else {
      setData(undefined);
    }
  }, [ref?.path, ref?.converter]);
  return [loading, data];
};

export const useSubscribeCollection = <T>(
  query: () => Query<T> | undefined,
  deps: unknown[]
): [boolean, T[]] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T[]>([]);
  useEffect(() => {
    const q = query();
    if (q) {
      return registerUnsubscribe(
        v4(),
        onSnapshot(
          q,
          (snapshot) => {
            if (loading) {
              setLoading(false);
            }
            setData(snapshot.docs.map((doc) => doc.data()));
          },
          console.error
        )
      );
    } else {
      setLoading(true);
      setData([]);
    }
  }, [...deps]);
  return [loading, data];
};
