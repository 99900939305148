import { useCallback, useMemo } from 'react';
import { SearchRange } from './useSearch';
import { useAtomValue } from 'jotai/index';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';
import {
  Option,
  Options,
  Select,
  SelectProps,
} from '../../../../components/forms';
import { useStore } from '../../../../hooks/useStore';

type Props = Omit<SelectProps<SearchRange>, 'options'>;

export const SearchRangeSelect = ({ renderLabel, ...props }: Props) => {
  const store = useStore();
  const joinedTeams = useAtomValue(joinedTeamsAtom);
  const options = useMemo(() => {
    const base: Options<SearchRange> = [
      { value: { type: 'allInboxes' }, label: 'すべてのチームとアドレス' },
    ];
    for (const team of joinedTeams) {
      const subOptions: Option<SearchRange>[] = [];
      const inboxes = store.getTeamInboxes(team.id);
      if (team.isPrivate && inboxes.length === 1) {
        base.push({
          value: { type: 'inbox', teamId: team.id, inboxId: inboxes[0].id },
          label: '個人メール',
        });
        continue;
      }

      if (inboxes.length <= 0) {
        continue;
      }

      subOptions.push({
        value: { type: 'teamInboxes', teamId: team.id },
        label: 'すべてのアドレス',
      });

      for (const inbox of inboxes) {
        subOptions.push({
          value: { type: 'inbox', teamId: team.id, inboxId: inbox.id },
          label: inbox.email,
        });
      }
      base.push({
        group: team.isPrivate ? '自分' : team.name,
        type: 'sub',
        options: subOptions,
      });
    }
    return base;
  }, [joinedTeams]);
  const renderSearchRangeLabel = useCallback(
    (value: SearchRange, label: string) => {
      if (value.type === 'inbox' || value.type === 'teamInboxes') {
        const team = joinedTeams.find((t) => t.id === value.teamId);
        const teamName = team?.isPrivate ? '自分' : team?.name;
        if (value.type === 'inbox' && label !== '個人メール') {
          return teamName + ' / ' + label;
        }
        if (value.type === 'teamInboxes') {
          return teamName + 'のすべてのアドレス';
        }
      }
      return label;
    },
    [joinedTeams]
  );
  return (
    <Select
      {...props}
      options={options}
      renderLabel={(v, l) =>
        renderLabel
          ? renderLabel(v, renderSearchRangeLabel(v, l))
          : renderSearchRangeLabel(v, l)
      }
    />
  );
};
