import defaultEmptyImage from './images/emptyImage.png';

type Props = {
  image?: string;
  description?: string;
};

const MessageListEmpty = ({ image, description }: Props) => {
  const emptyImage = image || defaultEmptyImage;
  return (
    <div className="flex h-full w-full flex-1 justify-center pt-24">
      <div>
        <img
          style={{ width: '233px' }}
          src={emptyImage}
          className="block"
          alt={description || ''}
        />
        <p
          aria-label="メッセージタブ空の説明"
          className="mt-6 text-center text-base text-sumi-600"
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default MessageListEmpty;
