import { Comment } from '../../../Common/Comment';
import React from 'react';
import * as color from '../../../../color';
import { useStore } from '../../../../hooks/useStore';
import Tooltip from '../../../Common/Tooltip';
import { DefaultButton } from '../../../Common/Button';
import { message, Modal } from 'antd';
import { Group as GroupEntity } from '../../../../store/entity';

type Props = {
  group: GroupEntity;
  onEdit: (group: GroupEntity) => void;
};

export const Group = ({ group, onEdit }: Props): JSX.Element => {
  const { me, groupStore } = useStore();

  const isAdmin = me.isAdmin;

  const onClickDelete = () => {
    Modal.confirm({
      title: `グループ "${group.name}" を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        await groupStore.deleteGroup(group.id);
        message.success('グループを削除しました');
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  return (
    <div className="mt-[16px] flex w-[450px] items-center">
      <div className="flex flex-1 flex-col justify-center">
        <div
          className={`text-[13px] font-bold`}
          style={{ color: color.common.text2 }}
        >
          {group.name}
        </div>
        <Comment>{`${group.members.length}名のメンバー`}</Comment>
      </div>
      <Tooltip title="権限がありません" visible={!isAdmin}>
        <div className={buttonWrapperClassName}>
          <DefaultButton
            className={`h-[40px] w-[100px]`}
            onClick={() => onEdit(group)}
            disabled={!isAdmin}
          >
            編集
          </DefaultButton>
        </div>
      </Tooltip>
      <Tooltip title="権限がありません" visible={!isAdmin}>
        <div className={buttonWrapperClassName}>
          <DefaultButton
            className={`h-[40px] w-[100px]`}
            type="danger"
            onClick={onClickDelete}
            disabled={!isAdmin}
          >
            削除
          </DefaultButton>
        </div>
      </Tooltip>
    </div>
  );
};

const buttonWrapperClassName = `ml-[12px]`;
