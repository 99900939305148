import { generatePath, matchPath } from 'react-router-dom';
import { MyEmailTabKey } from './MyEmailTabKey';
import { SettingObjectRoutes } from 'App/Settings/common';

export type MyEmailPaths = {
  base: string;
  inbox: string;
  inboxNew: string;
  inboxDetail: string;
  detail: string;
};

export class MyEmailRoutes {
  constructor(
    readonly paths: MyEmailPaths,
    readonly templateRoutes: SettingObjectRoutes<any, 'templateId'>,
    readonly signatureRoutes: SettingObjectRoutes<any, 'signatureId'>,
    readonly tagRoutes: SettingObjectRoutes<any, 'tagId'>,
    readonly filterRoutes: SettingObjectRoutes<any, 'filterId'>,
    readonly autoReplyRoutes: SettingObjectRoutes<any, 'autoReplyId'>,
    private router: {
      push: (location: string) => void;
      replace: (location: string) => void;
    }
  ) {}

  redirectToInboxIfNeeded(pathname: string): void {
    if (matchPath(pathname, { path: this.paths.base, exact: true })) {
      this.toInbox({ replace: true });
    }
  }

  matchInboxNew(pathname: string): boolean {
    return !!matchPath(pathname, { path: this.paths.inboxNew });
  }

  toInbox(options: { replace?: boolean } = {}): void {
    if (options.replace) {
      this.router.replace(this.inboxPathName);
    } else {
      this.router.push(this.inboxPathName);
    }
  }

  toInboxNew(): void {
    this.router.push(this.inboxNewPathName);
  }

  toInboxDetail(inboxId: string): void {
    this.router.push(this.getInboxDetailPathName(inboxId));
  }

  toTab(tab: MyEmailTabKey): void {
    this.router.push(this.getPathNameForTab(tab));
  }

  get inboxPathName(): string {
    return this.paths.inbox;
  }

  get inboxNewPathName(): string {
    return this.paths.inboxNew;
  }

  getInboxDetailPathName(inboxId: string): string {
    return generatePath(this.paths.inboxDetail, { inboxId });
  }

  get detailPathName(): string {
    return this.paths.detail;
  }

  getPathNameForTab(tab: MyEmailTabKey): string {
    switch (tab) {
      case MyEmailTabKey.Inbox:
        return this.inboxPathName;
      case MyEmailTabKey.Template:
        return this.templateRoutes.getIndexPathName({});
      case MyEmailTabKey.Signature:
        return this.signatureRoutes.getIndexPathName({});
      case MyEmailTabKey.Tag:
        return this.tagRoutes.getIndexPathName({});
      case MyEmailTabKey.Filter:
        return this.filterRoutes.getIndexPathName({});
      case MyEmailTabKey.AutoReply:
        return this.autoReplyRoutes.getIndexPathName({});
      case MyEmailTabKey.Detail:
        return this.detailPathName;
      default:
        return this.inboxPathName;
    }
  }

  getTabForPathName(pathname: string): MyEmailTabKey {
    if (matchPath(pathname, { path: this.paths.inbox })) {
      return MyEmailTabKey.Inbox;
    } else if (matchPath(pathname, { path: this.templateRoutes.paths.index })) {
      return MyEmailTabKey.Template;
    } else if (
      matchPath(pathname, { path: this.signatureRoutes.paths.index })
    ) {
      return MyEmailTabKey.Signature;
    } else if (matchPath(pathname, { path: this.tagRoutes.paths.index })) {
      return MyEmailTabKey.Tag;
    } else if (matchPath(pathname, { path: this.filterRoutes.paths.index })) {
      return MyEmailTabKey.Filter;
    } else if (
      matchPath(pathname, { path: this.autoReplyRoutes.paths.index })
    ) {
      return MyEmailTabKey.AutoReply;
    } else if (matchPath(pathname, { path: this.paths.detail })) {
      return MyEmailTabKey.Detail;
    }
    return MyEmailTabKey.Inbox;
  }
}
