import { Product } from '../Product';

export type ProductOption = {
  product: Product;
  exceeds: {
    inbox: boolean;
    users: boolean;
    customStatuses: boolean;
  };
  isCurrent: boolean;
};

export type StripeProductOption = {
  id: string;
  name: string;
  price: number;
  priceId: string | null;
  interval?: 'year' | 'month';
  optionType?: StripeProductOptionType;
};

export type StripeCard = {
  last4: string;
  exp_month: number;
  exp_year: number;
  brand: string;
};

export enum StripePaymentMethod {
  card = 'card',
  invoice = 'invoice',
}

export enum StripeProductOptionType {
  bool = 'bool',
  seat = 'seat',
}

export type StripeProductOptionValue = {
  optionId: StripeProductOption['id'];
  checked: boolean;
  seat?: number;
};
