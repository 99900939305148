import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { sentConverter } from 'lib';
import LogoImageSrc from '../../../assets/logos/logo_wide_fullcolor_RGB.png';
import * as color from '../../../color';
import Header from '../Message/header';
import Body from './body';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: null,
      storageSent: null,
    };
  }

  componentDidMount() {
    this.loadSent();
  }

  componentDidUpdate() {
    this.startprint();
  }

  startprint = () => {
    if (this.state.storageSent) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  };

  handleStorageSentLoaded = (storageSent) => {
    this.setState({ storageSent });
  };

  loadSent = async () => {
    const { sentId } = this.props.match.params;

    const sentSnapshot = await getDoc(
      companyDoc('sent', sentId, sentConverter)
    );
    if (!sentSnapshot.exists()) {
      return null;
    }

    const sent = sentSnapshot.data();
    this.setState({ sent });
    return sent;
  };

  render() {
    const { sent } = this.state;
    const { me } = this.props.store;

    return (
      <div
        className={`m-[8px] w-full`}
        style={{ backgroundColor: color.common.white }}
      >
        {sent && (
          <>
            <div
              className={`flex items-center justify-between pb-[12px] pt-[12px]`}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img alt="" className={`h-[40px]`} src={LogoImageSrc} />
              </div>
              <p
                className={`font-bold text-gray-500`}
              >{`${me.name} <${me.email}>`}</p>
            </div>
            <hr />
            <Header>{sent.subject}</Header>
            <hr />
            <Body
              sent={sent}
              attachments={sent.attachments}
              onStorageSentLoaded={this.handleStorageSentLoaded}
            />
          </>
        )}
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
