import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import qs from 'qs';
import { Icon, Spin } from 'antd';
import { joinCompanyFunction } from '../../functions';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRegistering: false,
      errorMessage: '',
      tip: '',
    };
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { email } = query;
    this.setState({ email });
  }

  setErrorState = (message) =>
    this.setState({
      errorMessage: message,
      isRegistering: false,
    });

  submit = async (e) => {
    e.preventDefault();
    this.setState({ isRegistering: true, tip: 'ユーザを作成中…' });
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { companyId, invitationId } = query;
    const { email, password } = this.state;

    try {
      // 自動ログインを行わない（ページを再読込するため）
      this.props.store.skipAutoSignIn = true;
      // firebaseに認証ユーザを作成
      await this.createUserWithEmailAndPasswordWithSigninRetry(email, password);
      this.setState({ tip: '会社にジョイン中…' });
      // ユーザを作成
      await joinCompanyFunction({
        companyId,
        invitationId,
      });
      this.setState({ tip: 'ログイン中…' });
      // ユーザプロフィール設定画面へ移動する
      window.location.href = '/settings/me/profile';
    } catch (error) {
      console.error('Join.submit:', error);

      if (error.code?.startsWith('auth/')) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            this.setErrorState('※すでに登録されているメールアドレスです');
            break;
          default:
            this.setErrorState('ユーザーの作成に失敗しました');
        }
      } else {
        this.setErrorState(
          ['invalid-argument', 'permission-denied'].includes(error.code)
            ? error.message
            : '予期せぬエラーが発生しました'
        );
      }
    }
  };

  createUserWithEmailAndPasswordWithSigninRetry = async (email, password) => {
    let errorOnCreateUser = null;
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      return;
    } catch (error) {
      errorOnCreateUser = error;
    }
    if (
      errorOnCreateUser &&
      errorOnCreateUser.message.includes('deadline exceeded')
    ) {
      // auth/internal-error
      const maxSigninRetry = 3;
      for (let i = 0; i < maxSigninRetry; i++) {
        // wait a sec for retry
        await new Promise((r) => setTimeout(r, 2000));
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password);
          if (firebase.auth().currentUser != null) return;
        } catch (error) {
          // ignore errors while retry, just do next try
        }
      }
    }
    throw errorOnCreateUser;
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isRegistering, email, password, errorMessage, tip } = this.state;

    return (
      <div className={`block h-[100vh] md:flex`}>
        <div
          className={`w-1/2 bg-cover`}
          style={{ backgroundImage: `url(${bg1})` }}
        />
        <div
          className={`flex h-full w-full flex-col items-center justify-between p-[10px_20px] md:h-auto md:w-1/2`}
        >
          <div />
          <div>
            <div className={`pb-[50px]`}>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <div className={`relative mb-[20px]`}>
                  <label className={InputLabelClassName}>メールアドレス</label>
                  <DefaultInput
                    className={`max-w-[calc(100vw - 60px)] h-[40px] w-[400px]`}
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled
                    required
                    autoComplete="username"
                  />
                </div>
                <div className={`relative mb-[20px]`}>
                  <label className={InputLabelClassName}>
                    新しいパスワード
                  </label>
                  <DefaultInput
                    className={`max-w-[calc(100vw - 60px)] h-[40px] w-[400px]`}
                    value={password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isRegistering}
                    required
                    minLength="8"
                    autoComplete="new-password"
                  />
                </div>
                <Spin
                  tip={tip}
                  spinning={isRegistering}
                  indicator={<Icon type="loading" spin />}
                >
                  <DefaultButton
                    className={`h-[50px] w-full max-w-[400px]`}
                    type="submit"
                    disabled={isRegistering}
                  >
                    新規登録
                  </DefaultButton>
                </Spin>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className={`mt-[20px] block text-[15px] underline`}
                  style={{ color: color.a.text.normal }}
                  onClick={(e) => {
                    this.toSignIn();
                  }}
                >
                  すでにアカウントをお持ちの方はこちら
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const InputLabelClassName = `static left-[-110px] top-[10px] block pb-[1em] md:absolute md:pb-[0]`;

export default compose(withRouter, inject('store'), observer)(Join);
