import { Sent, User } from 'lib';
import { Attachments } from '../Message/Attachments';
import styled from 'styled-components';
import sentMessage from './sentMessage';
import {
  MessageForward,
  MessageReply,
  MessageReplyAll,
} from 'components/icons';
import MessageBodyTextButton from '../Message/MessageBodyTextButton';
import { useEffect } from 'react';

type Props = {
  sent: Sent;
  lock?: any;
  locker?: User | undefined;
  me?: User | undefined;
  attachments: Sent['attachments'];
  scrollToBottom: any;
  startReply: () => void;
  startReplyAll: () => void;
  startForwarding: () => void;
  onLoad?: () => void;
  isReadOnly?: boolean;
};

const SentBody = ({
  sent,
  lock,
  locker,
  me,
  attachments,
  startReply,
  startReplyAll,
  startForwarding,
  scrollToBottom,
  onLoad,
  isReadOnly = false,
}: Props) => {
  useEffect(() => {
    if (!sent.storagePath) {
      onLoad?.();
    }
  }, [sent.storagePath]);

  const locked = Boolean(lock);
  return (
    <div className="px-3 pb-3">
      <div className="mb-3">
        {sent.storagePath ? (
          sentMessage({ sent, print: null, onStorageSentLoaded: onLoad })
        ) : (
          <div className="whitespace-pre-line">{sent.text}</div>
        )}
        <Attachments attachments={attachments} />
      </div>
      <div className="flex items-center gap-2 text-xs">
        <MessageBodyTextButton
          component={MessageReplyAll}
          text="全員に返信"
          disabled={isReadOnly || locked}
          onClick={startReplyAll}
        />
        <MessageBodyTextButton
          component={MessageReply}
          text="返信"
          disabled={isReadOnly || locked}
          onClick={startReply}
        />
        <MessageBodyTextButton
          component={MessageForward}
          text="転送"
          onClick={startForwarding}
          disabled={isReadOnly}
        />
      </div>

      {locked && (
        <>
          <div className="pt-1">
            {locker?.name || '削除されたユーザ'}が返信中です。
          </div>
          {!isReadOnly && me && lock.locker !== me.id && (
            <ReplyInsteadOf onClick={scrollToBottom}>
              代わりに返信しますか？
            </ReplyInsteadOf>
          )}
        </>
      )}
    </div>
  );
};

export default SentBody;

const ReplyInsteadOf = styled.span`
  vertical-align: bottom;
  color: #3577ef;
  cursor: pointer;

  &:hover {
    transition: opacity 0.2s;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    opacity: 0.8;
  }
`;
