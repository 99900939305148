import { MailAddress } from 'lib';
import _ from 'lodash';
import { CmpIcon, CmpPopover } from 'utils/antdComp';
import { MessageMeta, MessageMailAddresses } from './MessageMeta';

const MAX_DISPLAY_RECIPIENT_LENGTH = 30;
const NAME_ADDR_REGEX = /^(.*)<(.*)>$/;

type Props = {
  subject?: string;
  mailAddresses: MessageMailAddresses;
  bold?: boolean;
};

export const MessageRecipient: React.FC<Props> = ({
  subject,
  mailAddresses,
  bold,
}) => {
  const addressList = [
    ...(mailAddresses.to || []),
    ...(mailAddresses.cc || []),
    ...(mailAddresses.bcc || []),
  ];

  // MailAddressから宛先表示テキスト取得
  const getTextFromMailAddress = (mailAddress: MailAddress): string => {
    if (mailAddress.name) {
      // MailAddress.nameがあればnameを表示
      return mailAddress.name;
    }

    // name <address> の形式なら nameのみ表示
    const matches = mailAddress.address.match(NAME_ADDR_REGEX);
    if (matches && matches[1]) {
      return matches[1].trim();
    } else {
      return mailAddress.address;
    }
  };

  const displayRecipients: string[] = [];

  // 文字数合計が規定の長さを超えるまでdisplayRecipientsに表示テキスト追加 (最低1個は追加)
  for (const a of addressList) {
    const text = getTextFromMailAddress(a);
    // 次の宛先を追加した場合の合計文字数
    const nextLength =
      _.sumBy(displayRecipients, (r) => r.length) + text.length;

    if (
      displayRecipients.length === 0 ||
      nextLength < MAX_DISPLAY_RECIPIENT_LENGTH
    ) {
      displayRecipients.push(text);
    } else {
      break;
    }
  }

  const omitedCount = addressList.length - displayRecipients.length;

  return (
    <div>
      <CmpPopover
        className={`popover m-[-6] p-[6px]`}
        trigger="hover"
        placement="bottom"
        content={
          <div className={`p-[4px]`}>
            <MessageMeta subject={subject} mailAddresses={mailAddresses} />
          </div>
        }
      >
        <div
          className={`${
            bold ? `bold` : ''
          } flex cursor-pointer hover:opacity-[0.8]`}
          style={{ color: 'rgb(0, 0, 0, 0.65)' }}
        >
          <div className="label flex-shrink-0">宛先:</div>
          <div
            aria-label="メール受信者"
            className={`recipients ml-[8px] overflow-hidden text-ellipsis whitespace-nowrap`}
          >
            {displayRecipients.map((r, i) => (
              <span className={`recipient`} key={i}>
                {r}
                {i < displayRecipients.length - 1 ? (
                  <span className={`comma ml-[1px] mr-[4px]`}>,</span>
                ) : null}
              </span>
            ))}
            {omitedCount > 0 ? (
              <span className={`omited ml-[5px] text-[11px]`}>
                +{omitedCount}
              </span>
            ) : null}
          </div>
          <div className={`more ml-[4px] flex-shrink-0 pt-[2px] text-[10px]`}>
            <CmpIcon type="caret-down" />
          </div>
        </div>
      </CmpPopover>
    </div>
  );
};
