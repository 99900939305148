import firebase from 'firebase.js';

// @NOTE: エミュレータではリージョンを指定すると動かない
const callableFunction = (name, options) =>
  process.env.REACT_APP_USE_EMULATOR === 'true'
    ? firebase.app().functions().httpsCallable(name, options)
    : firebase.app().functions('asia-northeast1').httpsCallable(name, options);

export const getSmtpServerFunction = callableFunction('getSmtpServer');
export const setSmtpServerFunction = callableFunction('setSmtpServer');
export const deleteSmtpServerFunction = callableFunction('deleteSmtpServer');
export const importFromImapFunction = callableFunction('importFromImap');
export const reportSentFunction = callableFunction('reportSent');
export const reportMessageByTags = callableFunction('reportMessageByTags');
export const createPortalSession = callableFunction('createPortalSession');
export const fetchSubscription = callableFunction('fetchSubscription');
export const fetchSubscriptionItem = callableFunction('fetchSubscriptionItem');
export const updateSubscriptionProduct = callableFunction(
  'updateSubscriptionProduct'
);
export const updateSubscriptionOptions = callableFunction(
  'updateSubscriptionOptions'
);
export const sendMessageFunction = callableFunction('sendMessage', {
  // functionのタイムアウトが540秒なので余裕を持って長くしている
  timeout: 550 * 1000, // ミリ秒
});
export const updateMessagesFunction = callableFunction('updateMessages');
export const markDeletingMessagesFunction = callableFunction(
  'markDeletingMessages'
);
export const deleteMessagesFunction = callableFunction('deleteMessages');
export const joinCompanyFunction = callableFunction('joinCompany');
export const createCompanyAndUserFunction = callableFunction(
  'createCompanyAndUser'
);

export const addOAuth = callableFunction('addOAuth');
export const removeOAuth = callableFunction('removeOAuth');
export const integrateSlackFunction = callableFunction('integrateSlack');
export const unintegrateSlackFunction = callableFunction('unintegrateSlack');
export const integrateChatworkFunction = callableFunction('integrateChatwork');
export const unintegrateChatworkFunction = callableFunction(
  'unintegrateChatwork'
);
export const getChatworkRoomsFunction = callableFunction('getChatworkRooms');

export const getSalesforceContactFunction = callableFunction(
  'getSalesforceContact'
);
export const getSalesforceRecordsFunction = callableFunction(
  'getSalesforceRecords'
);
export const saveSalesforceRecordFunction = callableFunction(
  'saveSalesforceRecord'
);

export const addInboxFunction = callableFunction('addInbox');
export const updateInboxFunction = callableFunction('updateInbox');
export const inboxOAuthCallbackFunction =
  callableFunction('inboxOAuthCallback');
export const unindexContactsFunction = callableFunction('unindexContacts');
export const sendInvitationFunction = callableFunction('sendInvitation');
export const searchFunction = callableFunction('search');
export const searchContactFunction = callableFunction('searchContact');
export const setCustomClaimsFunction = callableFunction('setCustomClaims');
export const createCompletionFunction = callableFunction('createCompletion');
export const convertTextFunction = callableFunction('convertText');
export const getSlackChannelInfoFunction = callableFunction(
  'getSlackChannelInfo'
);
export const addLineAccountFunction = callableFunction('addLineAccount');
export const sendLineMessageFunction = callableFunction('sendLineMessage');

export const getDomainsFunction = callableFunction('getDomains');
export const authDomainFunction = callableFunction('authDomain');
export const validateDomainAuthFunction =
  callableFunction('validateDomainAuth');

export const sendBulkMessagesFunction = callableFunction('sendBulkMessages');
export const getDeliveryMessageRecipientCountFunction = callableFunction(
  'getDeliveryMessageRecipientCount'
);

export const deleteUserFunction = callableFunction('deleteUser');
export const updateUserRoleFunction = callableFunction('updateUserRole');

/**
 * @type {{res: {allow: boolean}; responseTime: Date} | null}
 */
let cache = null;

const cacheTimeout = 60 * 60 * 1000; // 1 hour
export const checkIPFunction = async function () {
  if (cache) {
    if (Date.now() < cache.responseTime + cacheTimeout) {
      return cache.res;
    }
  }
  const res = await callableFunction('checkIP')();
  cache = {
    res,
    responseTime: Date.now(),
  };
  return res;
};
