import React, { PropsWithChildren, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import lStorage, { storageKeys } from '../localStorage';
import { useTopPagePath } from '../hooks/useTopPagePath';
import { Store } from '../store';
import { useAtomValue } from 'jotai';
import {
  privateTeamArrayAtom,
  privateTeamLoadingAtom,
  teamsAtom,
  teamsLoadingAtom,
} from '../atoms/firestore/team';
import { meAtom } from '../atoms/auth';
import { AuthenticatingComponent } from '../App/Auth/AuthenticatingComponent';

type Props = PropsWithChildren<{
  store: Store;
}>;

/**
 * パスの変更を監視して必要ならリダイレクトする
 */
export const PageRedirect = ({ store, children }: Props): React.ReactNode => {
  const location = useLocation();
  const history = useHistory();
  const topPagePath = useTopPagePath(store);
  const me = useAtomValue(meAtom);
  const teamsLoading = useAtomValue(teamsLoadingAtom);
  const teams = useAtomValue(teamsAtom);
  const privateTeamLoading = useAtomValue(privateTeamLoadingAtom);
  const privateTeamArray = useAtomValue(privateTeamArrayAtom);

  useLayoutEffect(() => {
    if (privateTeamLoading) {
      return;
    }
    const pathname = location.pathname;
    if (/^\/$/.test(pathname)) {
      // 最後にアクセスしたページにリダイレクト
      const url = lStorage.getString(storageKeys.lastVisitedUrl) ?? topPagePath;
      // 設定画面、印刷画面はトップページにリダイレクト
      const validPatterns = [/^\/settings(\/.*)?$/, /^\/print(\/.*)?$/];
      const targetPath = validPatterns.some((pattern) => pattern.test(url))
        ? topPagePath
        : url;
      history.replace(targetPath);
    } else if (me.isReadOnly && isInvalidPathForReadOnlyUser(pathname)) {
      history.replace(topPagePath);
    } else if (/^\/settings$/.test(pathname)) {
      // 設定画面ルート
      if (
        me.isAdmin &&
        teams.length === 1 &&
        store.getTeamInboxes(teams[0].id).length === 0
      ) {
        // チームが一つだけで、メール未設定なら共有メール設定に遷移
        history.replace(`/settings/teams/${teams[0].id}/mail`);
      } else {
        // プロフィールに遷移
        history.replace('/settings/me/profile');
      }
    } else if (/^\/contacts$/.test(pathname)) {
      // コンタクト画面ルート: 自分のコンタクトに遷移。undefinedだった場合会社のコンタクトに遷移
      const team =
        privateTeamArray[0] ||
        teams.find(
          (t) => t.roles[me.id] === 'owner' || t.roles[me.id] === 'member'
        );
      if (team) {
        history.replace(`/contacts/${team.id}/contacts`);
      }
    }

    const originLength = window.location.origin.length;
    const lastVisitedUrl = window.location.href.substring(originLength);
    lStorage.setString(storageKeys.lastVisitedUrl, lastVisitedUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history, privateTeamLoading]);
  return teamsLoading || privateTeamLoading ? (
    <AuthenticatingComponent />
  ) : (
    children
  );
};

const isInvalidPathForReadOnlyUser = (pathname: string): boolean => {
  if (!pathname.startsWith('/me')) {
    return false;
  }
  return (
    !pathname.startsWith('/me/commented') &&
    !pathname.startsWith('/me/mentioned')
  );
};
