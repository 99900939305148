import React from 'react';
import * as color from '../../../color';

export const Footer = () => (
  <div>
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={`mt-[20px] block text-[${color.a.text.normal}] text-[15px] hover:text-inherit`}
      href="https://onebox.tokyo"
    >
      運営会社
    </a>
  </div>
);
