import { useState } from 'react';
import React from 'react';
import { DefaultButton } from '../../Common/Button';
import { Form, Button, Input } from 'antd';
import Tooltip from '../../Common/Tooltip';
import * as color from '../../../color';
import store from 'store';
import ipaddr from 'ipaddr.js';

export interface CIDRChange {
  before: string;
  after: string;
}

export interface CIDRFormProps {
  /**
   * default cidr
   */
  cidr: string;
  /**
   * Update処理に利用
   */
  onSubmit?: (change: CIDRChange) => Promise<void>;

  /**
   * 削除処理時に利用
   */
  onDelete?: (cidr: string) => Promise<void>;
}

const CIDRForm: React.FC<CIDRFormProps> = ({ cidr, onSubmit, onDelete }) => {
  const isAdmin = store.me.isAdmin;
  const [isEdit, setIsEdit] = useState(false);
  const nameError = false;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [currentCidr, setCurrentCidr] = useState(cidr);
  return isEdit ? (
    <>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          if (onSubmit) {
            try {
              ipaddr.parseCIDR(currentCidr);
            } catch (error) {
              return;
            }
            setIsUpdating(true);
            onSubmit({
              before: cidr,
              after: currentCidr,
            }).finally(() => {
              setIsUpdating(false);
            });
          }
        }}
      >
        <div
          className={`mb-[10px] flex w-[450px] items-center justify-between`}
        >
          <Form.Item
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            <Input
              placeholder="34.0.0.0/15"
              disabled={isUpdating}
              value={currentCidr}
              onChange={(event) => {
                setCurrentCidr(event.target.value);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDeleting}
              loading={isUpdating}
            >
              更新
            </Button>
          </Form.Item>
          <Form.Item>
            <Tooltip title="権限がありません" visible={!isAdmin}>
              <Button
                type="danger"
                loading={isDeleting}
                disabled={!isAdmin || isUpdating}
                onClick={() => {
                  if (onDelete) {
                    setIsDeleting(true);
                    onDelete(cidr).finally(() => {
                      setIsDeleting(false);
                    });
                  }
                  return;
                }}
              >
                削除
              </Button>
            </Tooltip>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                setIsEdit(false);
              }}
              disabled={isUpdating || isDeleting}
            >
              キャンセル
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  ) : (
    <div
      style={{
        width: '450px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          color: color.common.text2,
        }}
        onClick={() => {
          return;
        }}
      >
        {cidr}
      </span>
      <Tooltip title="権限がありません" visible={!isAdmin}>
        <div>
          <DefaultButton
            style={{
              height: '40px',
              width: '100px',
              maxWidth: '100px',
              marginLeft: '12.5px',
            }}
            onClick={() => setIsEdit(true)}
            disabled={!isAdmin}
          >
            編集
          </DefaultButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default CIDRForm;
