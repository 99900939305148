import React, { useCallback, useState } from 'react';
import { Input } from '../../../../../components/forms';
import { Button, Loading } from '../../../../../components/basics';
import { useAtomValue } from 'jotai';
import { companyAtom } from '../../../../../atoms/auth';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { message } from 'antd';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';
import { getSlackChannelInfoFunction } from '../../../../../functions';

type Props = {
  channelId?: string;
  channelName?: string;
  updateChannel: (channelId: string, channelName: string) => Promise<void>;
  resetChannel: () => Promise<void>;
  disabled: boolean;
  tooltip?: string;
};

export const SlackIntegrationInput = ({
  channelId,
  channelName,
  updateChannel,
  resetChannel,
  disabled,
  tooltip,
}: Props): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(channelId ?? '');

  const company = useAtomValue(companyAtom);
  const showDialog = useConfirmDialog();

  const onEdit = useCallback(() => {
    setEditing(true);
    setValue(channelId ?? '');
  }, [channelId]);

  const onUpdate = useCallback(async () => {
    if (!value) {
      return;
    }
    const key = `slack-integration-${value}`;
    message.loading({
      content: '通知先チャンネルを設定中です',
      key,
      duration: 0,
    });
    await getSlackChannelInfoFunction({
      companyId: company.id,
      channelId: value,
    })
      .then(async (res) => {
        await updateChannel(value, res.data.name);
        message.success({
          content: '通知先チャンネルを設定しました',
          key,
          duration: 1,
        });
      })
      .catch(() => {
        message.error({
          content: '通知先チャンネルの設定に失敗しました',
          key,
          duration: 1,
        });
      });
    setEditing(false);
  }, [value, updateChannel]);

  const onReset = useCallback(async () => {
    await resetChannel();
    setEditing(false);
    setValue('');
  }, [resetChannel]);

  const content = () => {
    if (!channelId) {
      return (
        <div className="flex items-center gap-2">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="チャンネルID"
            className="w-[150px]"
            disabled={disabled}
          />
          <Tooltip content={tooltip} visible={!!tooltip && disabled}>
            <Button onClick={onUpdate} disabled={disabled || !value}>
              連携
            </Button>
          </Tooltip>
        </div>
      );
    }
    if (editing) {
      return (
        <div className="flex items-center gap-2">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="チャンネルID"
            className="w-[150px]"
          />
          <Button onClick={onUpdate} disabled={!value}>
            更新
          </Button>
          <Button
            color="danger"
            onClick={() =>
              showDialog({
                title: 'Slack連携を解除しますか？',
                okText: '解除',
                okType: 'danger',
                onOk: onReset,
              })
            }
          >
            解除
          </Button>
          <Button
            color="sumi"
            variant="outlined"
            onClick={() => setEditing(false)}
          >
            キャンセル
          </Button>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <div className="min-w-[150px] text-sm font-bold">
          {channelName ? '# ' + channelName : <Loading />}
        </div>
        <Tooltip content={tooltip} visible={!!tooltip && disabled}>
          <Button disabled={disabled} onClick={onEdit}>
            編集
          </Button>
        </Tooltip>
      </div>
    );
  };

  return <div className="flex items-center gap-2">{content()}</div>;
};
