import React, { useEffect, useRef } from 'react';
import { Team as TeamEntity } from 'lib';
import { Inbox, inboxConverter } from '../../../../../firestore/entity/inbox';
import { getSlackChannelInfoFunction } from '../../../../../functions';
import { companyAtom } from '../../../../../atoms/auth';
import { useAtomValue } from 'jotai';
import { SlackIntegrationInput } from './SlackIntegrationInput';
import { deleteField, serverTimestamp, updateDoc } from 'firebase/firestore';
import { companyDoc } from '../../../../../firestore';

type Props = {
  team: TeamEntity;
  inboxes: Inbox[];
  isAdmin: boolean;
};

export const SlackIntegrationTeam = ({
  team,
  inboxes,
  isAdmin,
}: Props): React.ReactElement => {
  const company = useAtomValue(companyAtom);
  const updatingChannelInboxIdsRef = useRef<string[]>([]);

  const updateChannel = async (
    inboxId: string,
    channelId?: string,
    channelName?: string
  ) => {
    await updateDoc(companyDoc('inboxes', inboxId, inboxConverter), {
      slackIntegrationChannel: channelId ?? deleteField(),
      slackIntegrationChannelName: channelName ?? deleteField(),
      updatedAt: serverTimestamp(),
    });
  };

  useEffect(() => {
    inboxes
      .filter(
        (inbox) =>
          !updatingChannelInboxIdsRef.current.includes(inbox.id) &&
          inbox.slackIntegrationChannel &&
          !inbox.slackIntegrationChannelName
      )
      .forEach((inbox) => {
        updatingChannelInboxIdsRef.current.push(inbox.id);
        getSlackChannelInfoFunction({
          companyId: company.id,
          channelId: inbox.slackIntegrationChannel,
        }).then(async (res) => {
          await updateChannel(
            inbox.id,
            inbox.slackIntegrationChannel,
            res.data.name
          );
          updatingChannelInboxIdsRef.current =
            updatingChannelInboxIdsRef.current.filter((id) => id !== inbox.id);
        });
      });
  }, [inboxes]);

  return (
    <div className="mb-5">
      <div className="mb-4 flex">
        <div className="text-sm font-bold">{team.name}</div>
      </div>
      <div className="flex flex-col gap-2">
        {inboxes.map((inbox) => (
          <div className="flex items-center gap-2" key={inbox.id}>
            <div className="min-w-[300px] text-sm">{inbox.email}</div>
            <SlackIntegrationInput
              channelId={inbox.slackIntegrationChannel}
              channelName={inbox.slackIntegrationChannelName}
              updateChannel={(channelId, channelName) =>
                updateChannel(inbox.id, channelId, channelName)
              }
              resetChannel={() => updateChannel(inbox.id)}
              disabled={!isAdmin}
              tooltip="権限がありません"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
