import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../../basics';
import { tv, VariantProps } from 'tailwind-variants';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuContentProps } from '@radix-ui/react-dropdown-menu';
import { twMerge } from 'tailwind-merge';
import { CaretDown, CaretRight } from '../../icons';
import { isEqual } from 'lodash';

const select = tv({
  base: 'grid cursor-pointer select-none grid-cols-[1fr_auto] items-center justify-between gap-[12px] bg-transparent px-2 text-sm text-sumi-900 disabled:cursor-not-allowed',
  variants: {
    size: {
      sm: 'h-7',
      md: 'h-8',
      lg: 'h-10',
    },
    width: {
      auto: '',
      full: 'w-full',
    },
    border: {
      false: 'border-0',
      true: 'border border-sumi-300',
    },
    rounded: {
      none: 'rounded-none',
      md: 'rounded',
      lg: 'rounded-lg',
    },
    disabled: {
      true: 'cursor-not-allowed bg-sumi-100',
    },
  },
  defaultVariants: {
    size: 'md',
    width: 'auto',
    border: true,
    rounded: 'md',
    disabled: false,
  },
});

type SelectVariants = VariantProps<typeof select>;

export type Option<T> = {
  value: T;
  label: string;
};

export type GroupedOption<T> = {
  group: string;
  options: Option<T>[];
  type?: 'section' | 'sub';
};

export type Options<T> = (Option<T> | GroupedOption<T> | T)[];

export type SelectProps<T> = {
  id?: string;
  value: T;
  onChange?: (value: T) => void;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  renderLabel?: (value: T, label: string) => string | JSX.Element | undefined;
  renderOption?: (value: T, label: string) => string | JSX.Element | undefined;
  placeholder?: string;
  options: Options<T>;
  optionsId?: string;
  gap?: number;
  collisionPadding?: number;
  variants?: SelectVariants;
  className?: string;
  dropdownClassName?: string;
  align?: MenuContentProps['align'];
  dropdownRef?: Ref<HTMLDivElement>;
  disabled?: boolean;
  footerElement?: ReactElement | undefined;
};

export const Select = forwardRef<HTMLButtonElement, SelectProps<unknown>>(
  (props, ref): JSX.Element => {
    return <InternalSelect {...props} triggerRef={ref} />;
  }
) as <T>(
  p: SelectProps<T> & {
    ref?: Ref<HTMLButtonElement>;
  }
) => JSX.Element;

export const InternalSelect = <T,>({
  id,
  value,
  onChange,
  visible: forceVisible,
  onVisibleChange,
  renderLabel,
  renderOption,
  placeholder = '',
  options,
  optionsId,
  gap,
  collisionPadding = 16,
  variants,
  className,
  dropdownClassName,
  align = 'start',
  dropdownRef,
  triggerRef,
  disabled = false,
  footerElement,
}: SelectProps<T> & {
  triggerRef: Ref<HTMLButtonElement>;
}): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const onOpenChange = useCallback(
    (visible: boolean) => {
      if (forceVisible == null) {
        setVisible(visible);
      }
      onVisibleChange?.(visible);
    },
    [onVisibleChange]
  );
  const normalizedOptions: GroupedOption<T>[] = useMemo(
    () => normalizeOptions(options),
    [options]
  );
  const items = useMemo(() => {
    if (normalizedOptions.length === 0) {
      return null;
    }
    return normalizedOptions.map((option, i) => {
      if (option.options.length === 0 && options.length === 0) {
        return null;
      }
      return (
        <Option
          key={i}
          value={value}
          renderOption={renderOption}
          dropdownClassName={dropdownClassName}
          group={option}
          onChange={(v) => {
            onChange?.(v);
            onOpenChange(false);
          }}
        />
      );
    });
  }, [normalizedOptions, onChange, renderOption, value]);

  const vLabel = useMemo(() => {
    const array = normalizedOptions.flatMap((o) => o.options);
    return array.find((o) => isEqual(o.value, value))?.label ?? '';
  }, [normalizedOptions, value]);
  const valueLabel = renderLabel ? renderLabel(value, vLabel) : vLabel;

  const shouldVisible = useMemo(
    () => forceVisible || visible,
    [forceVisible, visible]
  );
  return (
    <DropdownMenu.Root open={shouldVisible} modal={false}>
      <DropdownMenu.Trigger
        onPointerDown={undefined} // Radixの標準の挙動を上書きする
        onClick={() => onOpenChange(!shouldVisible)}
        asChild
      >
        <button
          id={id}
          type="button"
          className={twMerge(select({ ...variants, disabled }), className)}
          data-select-trigger={true}
          disabled={disabled}
          ref={triggerRef}
        >
          <span
            className={twMerge(
              'truncate whitespace-nowrap text-start',
              value && valueLabel ? '' : 'text-sumi-500',
              disabled ? 'opacity-30' : 'cursor-pointer'
            )}
          >
            {value && valueLabel ? valueLabel : placeholder}
          </span>
          <Icon
            icon={CaretDown}
            size={24}
            className={'transform ' + (shouldVisible ? 'rotate-180' : '')}
          />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        id={optionsId}
        onInteractOutside={(e) => {
          if (
            !(e.target as HTMLElement).closest('[data-select-trigger=true]')
          ) {
            onOpenChange(false);
          }
        }}
        className={twMerge(
          'z-50 flex max-h-[40dvh] min-w-[var(--radix-dropdown-menu-trigger-width)] flex-col gap-2 overflow-auto rounded-lg bg-white p-2.5 shadow-dropdown',
          dropdownClassName
        )}
        align={align}
        sideOffset={gap}
        ref={dropdownRef}
        collisionPadding={collisionPadding}
      >
        {items}
        {footerElement}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const selectButton = tv({
  base: 'block w-full cursor-pointer whitespace-nowrap rounded-lg px-1.5 py-1 text-left text-sm outline-none',
  variants: {
    selected: {
      true: 'bg-sumi-200 hover:bg-sumi-300',
      false: 'bg-white hover:bg-sumi-100',
    },
    string: {
      true: 'h-8',
      false: '',
    },
  },
});

type OptionProps<T> = Pick<
  SelectProps<T>,
  'value' | 'renderOption' | 'dropdownClassName'
> & {
  onChange: (value: T) => void;
  group: GroupedOption<T>;
};

const Option = <T,>({
  value,
  renderOption,
  onChange,
  dropdownClassName,
  group: { group, type = 'section', options },
}: OptionProps<T>) => {
  const groupName =
    group.length > 0 && type === 'section' ? (
      <div className="w-fit select-none text-xs text-sumi-600">{group}</div>
    ) : null;
  const buttons = useMemo(
    () =>
      options.map(({ value: key, label }, j) => {
        const selected = isEqual(key, value);
        return (
          <SelectButton
            key={j}
            children={
              renderOption ? renderOption(key, label) : label || '未選択'
            }
            selected={selected}
            onClick={() => {
              onChange(key);
            }}
          />
        );
      }),
    [options, renderOption, onChange, value]
  );
  return (
    <div className="flex flex-col gap-2">
      {groupName}
      {options.length > 0 && type !== 'sub' && (
        <div className="flex flex-col gap-2">{buttons}</div>
      )}
      {options.length > 0 && type === 'sub' && (
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger
            className={selectButton({
              selected: false,
              string: true,
              className: 'grid grid-cols-[1fr_auto] items-center',
            })}
          >
            <span>{group}</span>
            <Icon icon={CaretRight} size={18} />
          </DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent
            sideOffset={6}
            className={twMerge(
              'z-50 flex max-h-[40dvh] w-fit flex-col gap-2 overflow-auto rounded-lg bg-white p-2.5 shadow-dropdown',
              dropdownClassName
            )}
          >
            {buttons}
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
      )}
    </div>
  );
};

type ButtonProps = {
  onClick: () => void;
  selected: boolean;
  children: ReactNode;
};

const SelectButton = ({ onClick, selected, children }: ButtonProps) => {
  return (
    <DropdownMenu.Item asChild>
      <button
        type="button"
        onClick={() => onClick()}
        className={selectButton({
          selected,
          string: typeof children === 'string',
        })}
      >
        {children}
      </button>
    </DropdownMenu.Item>
  );
};

const normalizeOptions = <T,>(options: Options<T>): GroupedOption<T>[] => {
  const groups: GroupedOption<T>[] = [];
  const valueOptions = options.filter((o) => {
    const type = getOptionType(o);
    return type === 'value' || type === 'option';
  }) as (T | Option<T>)[];
  if (valueOptions.length > 0) {
    const normalizedArray = valueOptions.map((o): Option<T> => {
      if (getOptionType(o) === 'value') {
        return { value: o as T, label: `${o}` };
      } else {
        return o as Option<T>;
      }
    });
    groups.push({
      group: '',
      options: normalizedArray,
    });
  }

  const groupOptions = options.filter(
    (o) => getOptionType(o) === 'group'
  ) as GroupedOption<T>[];
  groups.push(...groupOptions);

  return groups;
};

const getOptionType = <T,>(
  option: T | GroupedOption<T> | Option<T>
): 'value' | 'option' | 'group' => {
  if (typeof option !== 'object') {
    return 'value';
  }

  const obj = option as any;
  const keys = Object.keys(obj);
  if (keys.includes('value') && keys.includes('label')) {
    return 'option';
  }

  return 'group';
};
