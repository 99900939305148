import React from 'react';
import { Switch } from 'components/basics/Switch/Switch';
import { Button } from '../../../../../components/basics';

type AutoReply = {
  id: string;
  title: string;
  enabled: boolean;
};

type Props = {
  autoReplies: AutoReply[];
  onEdit: (id: string) => void;
  onToggle: (id: string, enabled: boolean) => void;
};

export const AutoReplySettings: React.FC<Props> = ({
  autoReplies,
  onEdit,
  onToggle,
}) => {
  if (autoReplies.length === 0) {
    return null;
  }

  return (
    <div className="flex w-[400px] flex-col gap-2">
      {autoReplies.map((reply) => (
        <div
          key={reply.id}
          className="group grid grid-cols-[250px_auto_auto] items-center gap-2 px-2 hover:bg-sumi-100"
        >
          <div className="overflow-hidden truncate whitespace-nowrap text-base">
            {reply.title}
          </div>
          <div>
            <Button variant="text" onClick={() => onEdit(reply.id)}>
              詳細
            </Button>
          </div>
          <div className="flex justify-end">
            <Switch
              value={reply.enabled}
              onChange={(newValue) => onToggle(reply.id, newValue)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
