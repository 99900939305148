import React, { useState, useEffect } from 'react';
import { H1 } from '../../Common/H1';
import { Comment } from '../../Common/Comment';
import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import { useParams } from 'react-router-dom';
import { H3 } from '../../Common/H3';
import { usePrevious } from '../../Settings/common';
import NotFound from '../../Error/404';
import { Icon, Spin, Select, Alert } from 'antd';
import { normalizeStr } from 'lib';
import { Tag } from 'antd';
export default (props) => {
  const { accountId, teamId } = useParams();
  const prevAccountId = usePrevious(accountId);
  const [itemLoading, setItemLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const [name, setName] = useState('');
  const [defaultName, setDefaultName] = useState('');
  const [domains, setDomains] = useState([]);
  const [defaultDomains, setDefaultDomains] = useState([]);
  const [externalId, setExternalId] = useState('');

  const [updating, setUpdate] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isDuplicateDomains, setIsDuplicateDomains] = useState(false);

  const onChangeName = async (e) => {
    const name = e.target.value;
    setName(name);
    if (name.length > 0 && name !== defaultName) {
      const isDuplicate = await props.checkDuplicateName(name);
      setIsDuplicateName(isDuplicate);
    }
  };
  const onChangeDomain = async (ds) => {
    const newDomains = [];
    ds.forEach((d) => {
      if (!defaultDomains.includes(d)) newDomains.push(d);
    });
    setDomains(ds);
    if (newDomains.length > 0) {
      const isDuplicate = await props.checkDuplicateDomains(newDomains);
      setIsDuplicateDomains(isDuplicate);
    } else {
      setIsDuplicateDomains(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (prevAccountId !== accountId) {
        setItemLoading(true);
        const account = await props.getAccount(accountId);

        if (account) {
          const contacts = await props.getContactByAccountId(accountId, teamId);

          setSelectedItem(account);
          setName(account.name);
          setDefaultName(account.name);
          setDomains(account.domains);
          setDefaultDomains(account.domains);
          setExternalId(account.externalId);
          setContacts(contacts);
        }
        setItemLoading(false);
      }
    })();
  }, [prevAccountId, accountId, props, teamId]);

  const updateAccount = async () => {
    setUpdate(true);
    await props.update(selectedItem.ref, {
      name,
      nameNormalized: normalizeStr(name),
      domains,
      externalId,
    });
  };

  const deleteAccount = async () => {
    await props.delete(selectedItem);
  };

  if (itemLoading) {
    return (
      <div className={`flex h-full items-center justify-center`}>
        <Spin size="large" indicator={<Icon type="loading" spin />} />
      </div>
    );
  }

  if (!selectedItem) {
    return <NotFound />;
  }

  const validName = () => {
    return name.length;
  };

  return (
    <div>
      <H1>{selectedItem?.name}</H1>
      <H3>
        名称 <span className={`text-[#f5222d]`}>*</span>
      </H3>
      <DefaultInput
        value={name}
        onChange={onChangeName}
        placeholder="Onebox株式会社"
        disabled={updating}
        required
        className={`my-[10px] h-[40px] w-[400px]`}
      />
      <Comment>例）Onebox株式会社</Comment>
      {isDuplicateName && (
        <Alert
          message="すでに登録されている取引先を追加することはできません
          "
          type="error"
          style={{ marginTop: 8 }}
        />
      )}

      <H3 style={{ marginTop: 24 }}>ドメイン</H3>
      <Select
        mode="tags"
        style={{ width: 400 }}
        onChange={onChangeDomain}
        value={domains}
        tokenSeparators={[',']}
        disabled={updating}
      ></Select>
      <Comment>
        コンタクトに登録されているメールアドレスのドメイン (@以降の部分)
        と自動で紐付けます。 例) onebox.tokyo
      </Comment>
      {isDuplicateDomains && (
        <Alert
          message="すでに取引先に登録されているドメインを追加することはできません"
          type="error"
          style={{ marginTop: 8 }}
        />
      )}

      <H3 style={{ marginTop: 24 }}>外部ID</H3>
      <DefaultInput
        value={externalId}
        onChange={(e) => setExternalId(e.target.value)}
        placeholder=""
        disabled={updating}
        className={`my-[10px] h-[40px] w-[400px]`}
      />
      <Comment>CRMツールとの連携で活用いただく予定です。</Comment>

      <H3 style={{ marginTop: 24 }}>コンタクト</H3>
      <div className={`mx-[0px] my-[10px]`}>
        {contacts.map((c) => (
          <Tag>{c.name ? c.name : c.email}</Tag>
        ))}
      </div>
      <Comment>
        コンタクトの追加・削除はコンタクト画面から実施していただけます。
      </Comment>

      <div className={`mt-[30px] flex`}>
        <DefaultButton
          className={`mr-[16px] h-[40px] w-full max-w-[100px]`}
          type="danger"
          disabled={updating}
          onClick={deleteAccount}
        >
          削除
        </DefaultButton>

        <DefaultButton
          className={`h-[40px] w-full max-w-[100px]`}
          type="primary"
          disabled={
            updating || !validName() || isDuplicateName || isDuplicateDomains
          }
          onClick={updateAccount}
        >
          更新
        </DefaultButton>
      </div>
    </div>
  );
};
