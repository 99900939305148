import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../store';
import moment from 'moment';
import { CmpButton, CmpDropdown, CmpIcon } from 'utils/antdComp';

const DEFAULT_COUNT = 5;

type Props = {
  teamId: string;
  openEvents: [string, moment.Moment][];
};

export const SeenDropdown: React.FC<Props> = observer(
  ({ teamId, openEvents }) => {
    const supported = store.isSeenHistorySupported;
    const [visible, setVisible] = useState(false);
    const [showAllSeen, setShowAllSeen] = useState(false);
    // TODO: union typeの配列だと扱いづらいのでオブジェクト配列にしたい
    const [seenEvents, setSeenEvents] = useState<
      ([string, moment.Moment] | [string, moment.Moment, string])[]
    >(openEvents.slice(0, DEFAULT_COUNT));

    useEffect(() => {
      if (!visible || !supported) {
        return;
      }
      const shownEvents = showAllSeen
        ? openEvents
        : openEvents.slice(0, DEFAULT_COUNT);
      (async () => {
        const events = await Promise.all(
          shownEvents.map<Promise<[string, moment.Moment, string]>>(
            async ([email, ts]) => {
              const name = await store.contactStore.getContactNameByEmail({
                teamId,
                emailAddress: email,
              });
              return [email, ts, name];
            }
          )
        );
        setSeenEvents(events);
      })();
    }, [teamId, openEvents, showAllSeen, visible]);

    const renderSeenDropdown = () => {
      const items = !supported ? (
        <li>現在のプランでサポートされていません</li>
      ) : seenEvents.length > 0 ? (
        // FIXME: nameを展開するとコンパイルエラーになるのでとりあえずanyにキャスト
        (seenEvents as any[]).map(
          ([email, ts, name]: [string, moment.Moment, string]) => (
            <li
              className={`m-0 flex justify-between gap-[2rem] p-[0.25rem_0.5rem]`}
              key={email}
            >
              <span
                className="max-w-[24em] overflow-hidden text-ellipsis"
                title={email}
              >
                {name || email}
              </span>
              <span title={ts.format('LLL')} style={{ whiteSpace: 'nowrap' }}>
                {ts.fromNow()}
              </span>
            </li>
          )
        )
      ) : (
        <li>まだ閲覧されていません</li>
      );
      const showMore =
        openEvents.length > DEFAULT_COUNT && !showAllSeen ? (
          <div style={{ textAlign: 'center' }}>
            <CmpButton
              className={`mb-[0.5rem] h-[28px] rounded-[28px]`}
              onClick={() => setShowAllSeen(true)}
            >
              もっと見る
            </CmpButton>
          </div>
        ) : null;

      return (
        <div
          className={`max-w-[90vw] rounded-[4px] bg-white`}
          style={{ boxShadow: ' 0 2px 8px rgba(0, 0, 0, 0.15)' }}
        >
          <h3 className="m-0 border-b border-[rgb(230,230,230)] p-[4px] text-center text-[13px] font-medium">
            閲覧履歴
          </h3>
          <ul
            className={`m-0 max-h-[50vh] list-none overflow-y-auto p-[0.5rem_0]`}
          >
            {items}
          </ul>
          {showMore}
        </div>
      );
    };

    const count = openEvents.length > 9 ? '9+' : String(openEvents.length);

    return (
      <CmpDropdown
        overlay={renderSeenDropdown()}
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <div
          className={`${
            seenEvents.length === 0 ? 'none' : ''
          } relative flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-[50%] text-[#4c87f2] hover:bg-[#f0f0f1] hover:text-[14px] hover:text-[rgb(56,56,56)]`}
        >
          <CmpIcon
            className={`eye ${
              seenEvents.length === 0 ? ` text-[rgb(200,200,200)]` : ''
            }`}
            type="eye"
          />
          <div
            className={`count absolute bottom-[4px] right-[-1px] w-[10px] text-left text-[7px] font-semibold ${
              seenEvents.length === 0 ? ` hidden` : ''
            }`}
          >
            {count}
          </div>
        </div>
      </CmpDropdown>
    );
  }
);
