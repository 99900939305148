import {
  DeliveryFormHandle,
  DeliveryMessageCreateForm,
  DeliveryMessageFormData,
} from './DeliveryMessageCreateForm';
import { useAtomValue } from 'jotai';
import { teamsAtom } from '../../../../atoms/firestore/team';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { DeliveryAddEmailAddressDialog } from '../DeliveryAddEmailAddressDialog/DeliveryAddEmailAddressDialog';
import { debounce, groupBy, sumBy, uniqBy } from 'lodash';
import {
  addDoc,
  doc,
  DocumentReference,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { companyCollection } from '../../../../firestore';
import { deliveryAddressesAtom } from '../../../../atoms/firestore/deliveryAddress';
import {
  deliveryMessagesAtom,
  deliveryMessagesLoadingAtom,
} from '../../../../atoms/firestore/deliveryMessages';
import firebase from '../../../../firebase';
import { companyAtom, meAtom } from '../../../../atoms/auth';
import {
  AiAssistantType,
  AiBubbleMenu,
} from '../../../../App/Common/Editor/AiBubbleMenu/AiBubbleMenu';
import { message, message as antdMessage } from 'antd';
import {
  convertTextFunction,
  createCompletionFunction,
  getDeliveryMessageRecipientCountFunction,
} from '../../../../functions';
import {
  DeliveryMessageData,
  DomainAuthInfo,
  DraftAttachment,
  isSimilarAddress,
} from 'lib';
import { v4 } from 'uuid';
import { generateStorageAttachmentName } from '../../../../util';
import { generateAttachment } from '../../../../App/Top/Conversations/util';
import { eventNames, logEvent } from '../../../../analytics';
import bytes from 'bytes';
import { DeliverySendConfirmDialog } from './DeliverySendConfirmDialog';

type Props = {
  messageId: string | undefined;
};

type FormProps = ComponentProps<typeof DeliveryMessageCreateForm>;

export const DeliveryMessageCreateFormWithLogic = observer(
  ({ messageId }: Props) => {
    const isYaritoriAISupported = true;

    const history = useHistory();
    const store = useStore();
    const company = useAtomValue(companyAtom);
    const me = useAtomValue(meAtom);
    const messageDocRef = useRef<DocumentReference | undefined>(
      messageId
        ? doc(companyCollection('deliveryMessages'), messageId)
        : undefined
    );
    const unsubscribeMessageRef = useRef<(() => void) | undefined>(undefined);
    const [defaultData, setDefaultData] = useState<DeliveryMessageFormData>();
    const [loading, setLoading] = useState(messageId != null);
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [addedDeliveryAddresses, setAddedDeliveryAddresses] = useState<
      FormProps['addresses']
    >([]);
    const [isGenerating, setGenerating] = useState(false);
    const recipientCountInitializedRef = useRef(false);
    const [recipientCountLoading, setRecipientCountLoading] = useState(false);
    const [recipients, setRecipients] = useState<{
      count: number;
      total: number;
    }>({ count: 0, total: 0 });
    const [previewData, setPreviewData] = useState<{
      id: string;
      data: DeliveryMessageFormData;
    }>();
    const domainsLoadingRef = useRef(true);
    const domainsRef = useRef<DomainAuthInfo[]>([]);
    const formHandleRef = useRef<DeliveryFormHandle | undefined>();
    const prevContactTagsDisplayRef = useRef<
      NonNullable<DeliveryMessageData['display']>['contactTags'] | undefined
    >(undefined);
    const deliveryAddresses = useAtomValue(deliveryAddressesAtom);
    const teams = useAtomValue(teamsAtom);

    useEffect(() => {
      const unsubscribe = store.domainAuthStore.subscribeDomains((domains) => {
        domainsRef.current = domains;
        domainsLoadingRef.current = false;
      });
      return () => unsubscribe();
    }, [store.domainAuthStore]);

    // フォーカスの関係で若干遅延を入れる
    const openAddAddressDialog = useMemo(
      () => debounce(() => setAddressDialogOpen(true), 50),
      []
    );

    const onAddAddress = async (name: string | undefined, email: string) => {
      const addresses = companyCollection('deliveryAddresses');
      const doc = await addDoc(addresses, {
        name: name ?? null,
        email,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      setAddressDialogOpen(false);
      setAddedDeliveryAddresses([{ id: doc.id, name, address: email }]);
      formHandleRef.current?.setValue('from', doc.id);
    };

    const save = useCallback(
      async (update: DeliveryMessageFormData) => {
        const prevMessage = messages.find(
          (m) => m.id === messageDocRef.current?.id
        );

        const teamTags = teams.flatMap((team) => {
          const tags = store.getContactTags(team.id);
          return tags.map((tag) => ({
            teamId: team.id,
            teamName: team.name,
            id: tag.id,
            name: tag.name,
            color: tag.color ?? null,
          }));
        });

        const contactTagsDisplay = prevMessage?.display?.contactTags ?? [];
        const displayContactTags = update.tags
          .map((tagId) => {
            const tag = teamTags.find((t) => t.id === tagId);
            if (!tag) {
              return contactTagsDisplay.find((t) => t.id === tagId);
            }

            return tag;
          })
          .filter((t) => t != null);

        if (messageDocRef.current) {
          await updateDoc(messageDocRef.current, {
            deliveryAddressId: update.from,
            contactTags: update.tags,
            subject: update.subject,
            text: update.bodyText,
            html: update.isPlaintext ? null : update.body,
            plainTextMode: update.isPlaintext,
            attachments: update.attachments,
            'display.contactTags': displayContactTags,
            updatedAt: serverTimestamp(),
          });
        } else {
          messageDocRef.current = await addDoc(
            companyCollection('deliveryMessages'),
            {
              deliveryAddressId: update.from,
              contactTags: update.tags,
              subject: update.subject,
              text: update.bodyText,
              html: update.isPlaintext ? null : update.body,
              plainTextMode: update.isPlaintext,
              attachments: update.attachments,
              isSent: false,
              'display.contactTags': displayContactTags,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            }
          );
        }
        return messageDocRef.current.id;
      },
      [teams]
    );

    const onUploadAttachment: FormProps['onUploadAttachment'] = async (
      file,
      currentAttachments
    ) => {
      const currentTotalSize = sumBy(currentAttachments, (a) => a.size);
      if (file.size + currentTotalSize >= bytes('15MB')) {
        message.error('添付ファイルの合計は15MBまでです');
        return undefined;
      }

      // 下書きが存在しない場合は一度空で保存する
      if (!messageDocRef.current) {
        await save({
          from: '',
          tags: [],
          subject: '',
          body: '',
          bodyText: '',
          attachments: [],
          isPlaintext: false,
        });
      }

      const messageId = messageDocRef.current!.id;
      const attachmentId = v4();
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `companies/${
          company.id
        }/deliveryMessages/${messageId}/attachments/${attachmentId}/${generateStorageAttachmentName(
          file.name
        )}`
      );

      const uploadTask = fileRef.put(file, {
        customMetadata: {
          uploader: me.id,
        },
      });

      message.loading({
        content: `${file.name}をアップロード中です（0%完了）`,
        key: attachmentId,
      });

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            message.loading({
              content: `${file.name}をアップロード中です（${Math.floor(
                progress
              )}%完了）`,
              key: attachmentId,
            });
          },
          (error) => {
            console.error(error);
            message.error({
              content: `${file.name}のアップロードに失敗しました`,
              key: attachmentId,
            });
            reject();
          },
          () => {
            const mutableFile = file as any;
            mutableFile.uid = attachmentId;
            mutableFile.storagePath = uploadTask.snapshot.ref.fullPath;
            const attachment = generateAttachment(
              mutableFile
            ) as DraftAttachment;
            message.success({
              content: `${file.name}のアップロードが完了しました。`,
              key: attachmentId,
              duration: 2,
            });
            logEvent(eventNames.add_attachment);
            resolve(attachment);
          }
        );
      });
    };

    const onDeleteAttachment: FormProps['onDeleteAttachment'] = async (
      attachment
    ) => {
      await firebase.storage().ref(attachment.storagePath).delete();
    };

    const onUploadImage: FormProps['onUploadImage'] = async (
      file,
      currentAttachments
    ) => {
      const attachment = await onUploadAttachment(file, currentAttachments);
      if (!attachment) {
        return undefined;
      }
      const fileRef = firebase.storage().ref(attachment.storagePath);
      return {
        src: await fileRef.getDownloadURL(),
        contentId: attachment.uid,
        attachment,
      };
    };

    const onGenerate: FormProps['onGenerate'] = async (prompt, handle) => {
      try {
        const formHandle = formHandleRef.current!;
        await formHandle.notifyAutoSave();

        const messageDoc = messageDocRef.current;
        if (!messageDoc) {
          message.error('生成に失敗しました');
          return;
        }

        const messageId = messageDoc.id;

        setGenerating(true);

        message.loading({
          content: '本文を生成中です',
          key: messageId,
          duration: 0,
        });

        await updateDoc(messageDoc, {
          isGenerating: true,
        });

        unsubscribeMessageRef.current = onSnapshot(
          messageDoc as DocumentReference<DeliveryMessageData>,
          (doc) => {
            const { html, isGenerating } = doc.data()!;

            handle.setHtml(html!);
            formHandleRef.current?.setValue('body', html!);

            if (!isGenerating) {
              unsubscribeMessageRef.current?.();
              unsubscribeMessageRef.current = undefined;
              message.success({
                content: `本文の生成が完了しました`,
                key: messageId,
                duration: 2,
              });

              formHandle.notifyAutoSave();
              setGenerating(false);
            }
          }
        );
        await createCompletionFunction({
          companyId: company.id,
          draftId: messageId,
          prompt,
          delivery: true,
        }).catch(() => {
          unsubscribeMessageRef.current?.();
          unsubscribeMessageRef.current = undefined;
          message.error({
            content: '本文の生成に失敗しました',
            key: messageId,
          });
          updateDoc(messageDoc, {
            isGenerating: false,
          });
        });
      } catch (e) {
        console.error(e);
        message.error('エラーが発生しました');
      } finally {
        setGenerating(false);
      }
    };

    const onConvert = (assistant: AiAssistantType) => {
      const editor = formHandleRef.current?.handle.editor;
      if (!editor || !messageId) {
        return;
      }

      const { from, to } = editor.state.selection;
      const text = editor.state.doc.textBetween(from, to, '');

      if (!text) {
        return;
      }

      antdMessage.loading({
        content: '本文を変換中です',
        key: messageId,
        duration: 0,
      });

      convertTextFunction({
        assistant,
        content: text,
      })
        .then((result) => {
          const data = result.data;
          const transaction = editor.state.tr.insertText(data, from, to);
          editor.view.dispatch(transaction);
          editor.commands.focus();
          antdMessage.success({
            content: `本文の変換が完了しました`,
            key: messageId,
            duration: 2,
          });
        })
        .catch(() =>
          antdMessage.error({
            content: '本文の変換に失敗しました',
            key: messageId,
          })
        );
    };

    const updateRecipientCount = async () => {
      const messageId = messageDocRef.current?.id;
      if (!messageId) {
        return;
      }
      setRecipientCountLoading(true);
      await getDeliveryMessageRecipientCountFunction({
        companyId: company.id,
        deliveryMessageId: messageId,
      }).then((res) => {
        setRecipients(res.data);
        setRecipientCountLoading(false);
      });
    };

    const checkDomainAuth = async (
      domain: string
    ): Promise<{ spfDkim: boolean; dmarc: boolean }> => {
      if (domainsLoadingRef.current) {
        return new Promise((resolve) => {
          const id = setInterval(() => {
            if (!domainsLoadingRef.current) {
              clearInterval(id);
              domainsLoadingRef.current = false;
              const found = domainsRef.current.find((d) => d.domain === domain);
              resolve({
                spfDkim: found?.valid === true,
                dmarc: found?.auth?.dns.dmarc.valid === true,
              });
            }
          }, 1000);
        });
      } else {
        const found = domainsRef.current.find((d) => d.domain === domain);
        return {
          spfDkim: found?.valid === true,
          dmarc: found?.auth?.dns.dmarc.valid === true,
        };
      }
    };

    const messagesLoading = useAtomValue(deliveryMessagesLoadingAtom);
    const messages = useAtomValue(deliveryMessagesAtom);
    useEffect(() => {
      if (!messageId || messagesLoading) {
        return;
      }
      const message = messages.find((m) => m.id === messageId);
      if (!message) {
        history.push(DELIVERY_PATHS.getIndexPath());
        return;
      }
      setLoading(false);
      setDefaultData({
        from: message.deliveryAddressId,
        tags: message.contactTags,
        subject: message.subject,
        body: message.html ?? '',
        bodyText: message.text,
        attachments: message.attachments,
        isPlaintext: message.plainTextMode,
      });
      if (!recipientCountInitializedRef.current) {
        if (message.deliveryAddressId && message.contactTags.length > 0) {
          recipientCountInitializedRef.current = true;
          updateRecipientCount().then();
        }
      }
      if (!prevContactTagsDisplayRef.current) {
        prevContactTagsDisplayRef.current = message.display?.contactTags;
      }
    }, [messageId, messagesLoading, messages]);

    if (loading) {
      return null;
    }

    const groups: FormProps['groups'] = teams.map((team) => {
      const tags = store.getContactTags(team.id);
      return {
        team: team.name,
        tags: tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
          color: tag.color,
        })),
      };
    });
    Object.entries(
      groupBy(
        prevContactTagsDisplayRef.current?.filter(
          (t) => !teams.some((team) => team.id === t.id)
        ) ?? [],
        (t) => t.teamName
      )
    ).forEach(([teamName, tags]) => {
      const found = groups.find((group) => group.team === teamName);
      if (found) {
        found.tags = uniqBy([...found.tags, ...tags], (t) => t.id);
      } else {
        groups.push({
          team: teamName,
          tags,
        });
      }
    });

    if (previewData) {
      return (
        <DeliverySendConfirmDialog
          messageId={previewData.id}
          data={previewData.data}
          recipients={recipients}
          onClose={() => setPreviewData(undefined)}
        />
      );
    }

    return (
      <>
        <DeliveryMessageCreateForm
          key="form"
          open={true}
          onOpenChange={(open) => {
            if (!open) {
              history.push(DELIVERY_PATHS.getIndexPath());
            }
          }}
          data={defaultData}
          addresses={uniqBy(
            [
              ...deliveryAddresses.map((addr) => ({
                id: addr.id,
                name: addr.name,
                address: addr.email,
              })),
              ...addedDeliveryAddresses,
            ],
            (a) => a.id
          ).sort((a, b) => a.address.localeCompare(b.address, 'ja'))}
          groups={groups}
          onAddEmailAddress={() => openAddAddressDialog()}
          onGenerate={onGenerate}
          onUploadAttachment={onUploadAttachment}
          onDeleteAttachment={onDeleteAttachment}
          onSubmit={async (data) => {
            const id = await save(data);
            setPreviewData({ id, data });
          }}
          onUploadImage={onUploadImage}
          onUpdateRecipientCount={updateRecipientCount}
          recipientCountLoading={recipientCountLoading}
          checkDomainAuth={checkDomainAuth}
          isYaritoriAISupported={isYaritoriAISupported}
          recipients={recipients}
          formHandleRef={formHandleRef}
          onAutoSave={async (data) => {
            await save(data);
          }}
          editorChildren={(handle) => (
            <>
              {isYaritoriAISupported && (
                <AiBubbleMenu handle={handle} onConvert={onConvert} />
              )}
            </>
          )}
          readonly={isGenerating}
        />
        {addressDialogOpen && (
          <DeliveryAddEmailAddressDialog
            onAdd={onAddAddress}
            checkDuplicate={async (address) =>
              addedDeliveryAddresses.some((a) =>
                isSimilarAddress(a.address, address)
              )
            }
            open={true}
            onOpenChange={setAddressDialogOpen}
          />
        )}
      </>
    );
  }
);
