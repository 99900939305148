import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import { Modal } from 'antd';
import * as Sentry from '@sentry/react';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSendingPasswordResetEmail: false,
    };
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({ errorMessage: '', isSendingPasswordResetEmail: true });

    const auth = firebase.auth();
    const { email } = this.state;

    try {
      await auth.sendPasswordResetEmail(email);
      Modal.success({
        title: 'パスワードの再設定リンクが送信されました。',
        content: `${email} をご確認ください。`,
      });
    } catch (e) {
      console.error('ResetPassword.submit:', e);
      // "auth/user-not-found"
      const { code } = e;
      let errorMessage;
      switch (code) {
        case 'auth/user-not-found':
          errorMessage = '※登録されていないメールアドレスです';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            '※リクエスト回数が上限を超えました。時間をおいてから再度お試し下さい。';
          break;
        default:
          Sentry.captureMessage(e);
          errorMessage = '予期せぬエラーが発生しました';
      }
      this.setState({ errorMessage, isSendingPasswordResetEmail: false });
    }
    this.setState({ email: '', isSendingPasswordResetEmail: false });
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isSendingPasswordResetEmail, email, errorMessage } = this.state;

    return (
      <div className={`block h-[100vh] md:flex`}>
        <div
          className={`w-1/2 bg-cover`}
          style={{ backgroundImage: `url(${bg1})` }}
        />
        <div
          className={`flex h-full w-full flex-col items-center justify-between p-[10px_20px] md:h-auto md:w-1/2`}
        >
          <div />
          <div>
            <div className={`pb-[50px]`}>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <div className={`relative mb-[20px]`}>
                  <label className={`block pb-[1em]`}>
                    パスワード再設定用メールアドレス
                  </label>
                  <DefaultInput
                    className={`max-w-[calc(100vw - 60px)] h-[40px] w-[400px]`}
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isSendingPasswordResetEmail}
                    required
                  />
                </div>
                <DefaultButton
                  className={`mt-[20px] h-[50px] w-full max-w-[400px]`}
                  type="submit"
                  disabled={isSendingPasswordResetEmail}
                >
                  パスワードを再設定
                </DefaultButton>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className={`mt-[20px] block text-[15px] underline`}
                  style={{ color: color.a.text.normal }}
                  onClick={(e) => {
                    this.toSignIn();
                  }}
                >
                  ログインする場合はこちら
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(ResetPassword);
