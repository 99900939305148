import React, { useEffect, useState } from 'react';
import { SettingFields } from '../../common';
import { Alert, Button } from 'antd';
import { autoRecipientsDuplicated } from '../inbox/autoRecipientsDuplicated';
import { AutoBccField, AutoCcField, DefaultSignatureField } from '../Inboxes';
import styled from 'styled-components';
import { DocumentReference } from 'firebase/firestore';
import { Inbox, InboxData } from '../../../../firestore/entity/inbox';
import { Signature, User } from 'lib';

type Props = {
  inbox: Inbox;
  me: User;
  signatures: Signature[];
  update: (
    ref: DocumentReference<InboxData>,
    data: Partial<InboxData>
  ) => Promise<void>;
  disabled?: boolean;
};

export const MyEmailDetailEdit = (props: Props): React.ReactElement => {
  const { inbox } = props;
  const [selectedItem, setSelectedItem] = useState<Inbox | null>(null);
  const [autoCc, setAutoCc] = useState('');
  const [autoBcc, setAutoBcc] = useState('');
  const [defaultSignatureId, setDefaultSignatureId] = useState<string | null>(
    null
  );

  const [updating, setUpdate] = useState(false);

  useEffect(() => {
    if (inbox) {
      setSelectedItem(inbox);
      setAutoCc(inbox.autoCc);
      setAutoBcc(inbox.autoBcc);
      setDefaultSignatureId(inbox.defaultSignatureId);
    }
  }, [inbox]);

  const update = async () => {
    if (!selectedItem) {
      return;
    }
    setUpdate(true);
    await props.update(selectedItem.ref, {
      autoCc,
      autoBcc,
      defaultSignatureId,
    });
    setUpdate(false);
  };

  const isAutoRecipientsDuplicated = autoRecipientsDuplicated(autoCc, autoBcc);

  const disabled = props.disabled || updating;

  return (
    <Wrapper>
      <SettingFields>
        <AutoCcField value={autoCc} onChange={setAutoCc} disabled={disabled} />
        <AutoBccField
          value={autoBcc}
          onChange={setAutoBcc}
          disabled={disabled}
        />
        {isAutoRecipientsDuplicated && (
          <Alert
            message="自動Ccと自動Bccに同じ宛先を含めることはできません"
            type="error"
            style={{ marginTop: 8 }}
          />
        )}
        <DefaultSignatureField
          signatureId={defaultSignatureId}
          signatures={props.signatures}
          onSelect={setDefaultSignatureId}
          disabled={disabled}
        />
      </SettingFields>
      <div className="save">
        <Button
          className="button"
          size="large"
          type="primary"
          disabled={disabled || isAutoRecipientsDuplicated}
          onClick={update}
        >
          保存
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 0px;
  height: 100%;

  & > .save {
    margin-top: 56px;

    & > .button {
      width: 100px;
    }
  }
`;
