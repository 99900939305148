import styled from 'styled-components';
import { Product } from '../../Product';

export const PlanLimitItems: React.FC<{ product: Product }> = ({ product }) => {
  return (
    <Wrapper>
      <PlanLimitItem
        label="共有アドレス数"
        value={
          product.maxInboxes === null ? '無制限' : `${product.maxInboxes}個まで`
        }
      />
      <PlanLimitItem
        label="ユーザー数"
        value={
          product.maxUsers === null ? '無制限' : `${product.maxUsers}名まで`
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > * {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

// PlanLimitItem

const PlanLimitItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className={`text-center leading-[1]`}>
      <div className="label text-[14px]">{label}</div>
      <div className="value mt-[8px] text-[14px] font-semibold text-[#3f82f9]">
        {value}
      </div>
    </div>
  );
};
