import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import QuotedMessage from './quotedMessage';
import { isSP } from '../../../shared/util';
import QuotedMessageGenerator from './quotedMessageGenerator';

export default ({
  useQuote,
  addQuote,
  removeQuote,
  draft,
  plainTextMode,
  readOnly,
}) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const [header, setHeader] = useState('');
  const [text, setText] = useState('');
  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!draft?.id) return;

    (async () => {
      const generator = new QuotedMessageGenerator({
        inReplyToSentRef: draft?.inReplyToSentRef,
        inReplyToMessageRef: draft?.inReplyToMessageRef,
        inReplyToSentId: draft?.inReplyToSentId,
        isForwarded: draft?.isForwarded,
      });

      const [header, text, html] = await generator.execute();
      setHeader(header);
      setText(text);
      setHtml(html);
      setLoading(false);
    })();

    // quotedMessageGeneratorに渡す引数はdraft作成時以降は更新されない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.id]);

  if (!readOnly && !useQuote) {
    return (
      <div onClick={addQuote} style={{ marginTop: 4 }}>
        <span className="ml-[-4px] cursor-pointer rounded-[4px] p-[4px] text-[#c5c6c5] hover:bg-[#f0f0f1] hover:text-[#000000a6]">
          <Icon className={`mr-[4px]`} type="plus" />
          引用を追加
        </span>
      </div>
    );
  }

  return (
    <div
      className={`relative mt-[8px]`}
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      {useQuote && (
        <Icon
          className={`cursor-pointer rounded-[8px] bg-[#f0f0f1] p-[2px_4px]`}
          type="dash"
          onClick={() => setOpen(!open)}
        />
      )}
      {useQuote && open && (
        <div>
          {!readOnly && (
            <div className="absolute right-0 top-0 z-[1] pr-[8px]">
              <Icon
                className={`mr-[8px] cursor-pointer`}
                style={{ visibility: isSP() || hover ? 'visible' : 'hidden' }}
                type="close-circle"
                theme="filled"
                onClick={removeQuote}
              />
            </div>
          )}
          <QuotedMessage
            header={header}
            text={text}
            html={html}
            plainTextMode={plainTextMode}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};
