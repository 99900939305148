import React, { useMemo } from 'react';
import { LeftSidebarCountText } from '../../../App/Top/LeftSidebar/LeftSidebarCountText';
import { tv } from 'tailwind-variants';
import { Icon } from 'antd';

type Props = {
  children: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  count?: number;
  rightButton?: {
    icon: JSX.Element;
    onClick: () => void;
  };
};

const labelStyles = tv({
  base: 'group relative flex w-full select-none items-center justify-start gap-2 bg-transparent py-2 pl-0 pr-2 text-[14px] font-bold',
  variants: {
    interactive: {
      true: 'cursor-pointer',
    },
  },
});

export const SidebarSectionTitle = ({
  children,
  open,
  onOpenChange,
  count,
  rightButton,
}: Props): JSX.Element => {
  const icon = useMemo(
    () => (
      <Icon
        type={open ? 'caret-up' : 'caret-down'}
        className="invisible m-0 ml-[4px] mr-[10px] flex items-center text-[12px] text-inherit group-hover:visible"
      />
    ),
    [open]
  );
  const label = (
    <>
      <span className="truncate">{children}</span>
      {open != null && icon}
      <LeftSidebarCountText count={count} className={'ml-auto'} />
    </>
  );
  return (
    <div className="flex">
      <button
        className={labelStyles({ interactive: open != null })}
        onClick={() => onOpenChange?.(!open)}
      >
        {label}
      </button>
      {rightButton && (
        <button
          className={`relative -right-2 cursor-pointer bg-transparent p-2`}
          onClick={rightButton.onClick}
        >
          <span
            className={'flex h-[21px] w-[21px] items-center justify-center'}
          >
            {rightButton.icon}
          </span>
        </button>
      )}
    </div>
  );
};
