import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import firebase from 'firebase.js';
import FullHeightIframe from '../Message/FullHeightIframe';
import { Icon, Spin } from 'antd';
import { StorageSent } from 'lib';

export default ({ sent, print, onStorageSentLoaded }) => {
  const [storageSent, setStorageSent] = useState(null);

  useEffect(() => {
    (async () => {
      const ref = firebase.storage().ref(sent.storagePath);
      const url = await ref.getDownloadURL();
      const res = await fetch(url);
      const json = await res.json();
      const storageSent = new StorageSent(sent.id, json);
      setStorageSent(storageSent);
      onStorageSentLoaded?.();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sent.id, sent.storagePath]);

  if (!storageSent) {
    return <Spin indicator={<Icon type="loading" spin />} />;
  }

  if (storageSent.html && print) {
    return parse(storageSent.html);
  }

  if (storageSent.html) {
    return <FullHeightIframe srcDoc={storageSent.html} />;
  }

  return (
    <div className="whitespace-pre-line break-words break-all">
      {storageSent.text}
    </div>
  );
};
