import { Select } from '../../../components/forms';
import { useSearch } from './search/useSearch';
import { Controller } from 'react-hook-form';
import { MessageStatus } from 'lib';
import { HorizontalScrollArea } from '../../../components/basics/HorizontalScrollArea';
import { useEffect, useState } from 'react';
import { SearchRangeSelect } from './search/SearchRangeSelect';

type Props = {
  search: ReturnType<typeof useSearch>;
};

export const MobileSearchFilter = ({
  search: { trayOptions, tagOptions, memberOptions, filterForm, search },
}: Props) => {
  const [changed, setChanged] = useState(false);
  const { control, watch } = filterForm;
  useEffect(() => {
    if (filterForm.formState.isDirty) {
      setChanged(true);
    }
  }, [filterForm.formState.isDirty]);
  useEffect(() => {
    if (!changed) {
      return;
    }
    search();
  }, [
    changed,
    watch('searchRange'),
    watch('tray'),
    watch('status'),
    watch('tag'),
    watch('assignee'),
  ]);
  return (
    <HorizontalScrollArea className="flex h-mobile-search-filter flex-nowrap items-center gap-2 bg-white px-4">
      <Controller
        control={control}
        render={({ field }) => (
          <SearchRangeSelect
            value={field.value}
            onChange={(v) => field.onChange(v)}
            renderLabel={(_v, l) =>
              l.length > 14 ? l.substring(0, 14) + '…' : l
            }
            variants={{ rounded: 'lg' }}
            className="max-w-[180px]"
          />
        )}
        name="searchRange"
      />
      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Select
            id="tray"
            {...field}
            options={trayOptions}
            variants={{ rounded: 'lg' }}
          />
        )}
        name="tray"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="status"
            {...field}
            options={[
              MessageStatus.None,
              MessageStatus.Unprocessed,
              MessageStatus.Processed,
            ]}
            placeholder="ステータス"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="status"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="tag"
            {...field}
            options={tagOptions}
            placeholder="タグ"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="tag"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="assignee"
            {...field}
            options={memberOptions}
            placeholder="担当者"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="assignee"
      />
    </HorizontalScrollArea>
  );
};
