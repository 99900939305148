import Scheduled from './Conversation/Scheduled';
import { useParams } from 'react-router-dom';
import * as color from '../../../color';
import { SPBackButton } from './Conversation/SPBackButton';
import { Tooltip } from 'antd';
import { companyDoc } from '../../../firestore';
import { draftConverter } from '../../../firestore/entity/draft';
import { useSubscribeDocument } from '../../../hooks/firestore/subscription';
import { Conversation } from './Conversation/Conversation';

export const ScheduledConversation = (): JSX.Element => {
  const { draftId } = useParams<{ draftId: string }>();

  const [, draft] = useSubscribeDocument(
    companyDoc('drafts', draftId, draftConverter)
  );
  if (!draft) return <div />;

  if (draft.isReply) {
    // 返信の場合は会話を表示
    return (
      <Conversation
        threadView={false}
        messageId={draft.inReplyToMessageId!}
        key={draft.id}
      />
    );
  }

  return (
    <div
      className={`relative h-[100vh] flex-1 overflow-auto sm:h-full`}
      style={{ backgroundColor: color.sidebar.background.normal }}
    >
      <div
        className={`sticky top-0 z-[2] p-[10px]`}
        style={{ backgroundColor: color.sidebar.background.normal }}
      >
        <SPBackButton backLink="/me/scheduled" />
        <div
          className={`subject overflow-hidden text-ellipsis whitespace-nowrap text-[18px] font-semibold`}
          style={{ color: 'rgba(0, 0, 0, 0.8)' }}
        >
          <Tooltip title={draft.subject}>{draft.subject}</Tooltip>
        </div>
      </div>
      <Scheduled draft={draft} />
    </div>
  );
};
