import React from 'react';
import { List } from 'antd';
import { extractNonInlineAttachments } from 'lib';
import clip from '../../../assets/icons/clip.png';

export default ({ attachments }) => {
  const nonInlineAttachments = extractNonInlineAttachments(attachments);
  if (nonInlineAttachments.length === 0) return <></>;

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={nonInlineAttachments}
        renderItem={(attachment) => (
          <List.Item>
            <div className={`flex items-center`}>
              <div className={`mr-[6px]`}>
                <img alt="" className={`h-[18px] w-[18px]`} src={clip} />
              </div>
              <p
                className={`m-0 whitespace-break-spaces p-0 text-left text-[#3577ef]`}
              >
                {attachment.name ? attachment.name : 'file'}
              </p>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};
