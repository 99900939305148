import React from 'react';
import { Icon } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const PreviewImageModal = ({ open, onClose, url, onDownload }) => {
  if (!open) {
    return <></>;
  }
  return (
    <Lightbox
      mainSrc={url}
      onCloseRequest={onClose}
      toolbarButtons={[
        onDownload && (
          <button
            className={`h-[35px] w-[40px] cursor-pointer border-none bg-transparent px-[6px] py-[1px] align-middle opacity-[0.7] hover:opacity-[1]`}
            onClick={onDownload}
          >
            <Icon
              type="download"
              style={{ fontSize: 24, verticalAlign: 'initial' }}
            />
          </button>
        ),
      ]}
    />
  );
};

export default PreviewImageModal;

export const isPreviewableImageMimeType = (mimeType = '') =>
  mimeType.startsWith('image/') && mimeType !== 'image/tiff';
