import React from 'react';
import { compose } from 'recompose';
import { Router, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { DefaultButton } from '../../../Common/Button';
import { Form, Input, Button, notification, Modal, Typography } from 'antd';
import Tooltip from '../../../Common/Tooltip';
import * as color from '../../../../color';
import { db } from '../../../../firebase';
import firebase from 'firebase.js';
import { eventNames, logEvent } from '../../../../analytics';
import { Team as TeamEntity } from 'lib';
import { Store } from '../../../../store';
import { History } from 'history';

type Props = {
  form: any;
  team: TeamEntity;
  store: Store;
  history: History;
  router: Router;
};

type State = {
  name: string;
  isEditing: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
};

const { confirm } = Modal;

const { Text } = Typography;

function hasErrors(fieldsError: { [key: string]: any }): boolean {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      isEditing: false,
      isUpdating: false,
      isDeleting: false,
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  edit = (e: React.MouseEvent) => {
    this.setState({ isEditing: true });
  };

  cancel = (e: React.MouseEvent) => {
    this.setState({ isEditing: false });
  };

  update = async (e: React.FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (err) return;

      const { name } = values;
      this.setState({ isUpdating: true });

      await db
        .collection(`companies/${this.props.store.signInCompany}/teams`)
        .doc(this.props.team.id)
        .update({
          name,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      logEvent(eventNames.update_team_name);
      this.setState({ isEditing: false, isUpdating: false });

      // お知らせ
      notification['success']({ message: 'チームを更新しました' });
    });
  };

  delete = async (e: React.MouseEvent) => {
    confirm({
      title: `チーム "${this.props.team.name}" を削除しますか？`,
      content: (
        <div>
          <div>
            <p>
              <Text type="danger">削除前に下記の2点を必ずご確認下さい。</Text>
            </p>
          </div>
          <div>
            <p>
              <Text strong>①チームに紐づくデータがすべて削除されます。</Text>
            </p>
            <ul>
              <li>送受信したメール</li>
              <li>メール設定</li>
              <li>コンタクト</li>
              <li>テンプレート</li>
              <li>署名</li>
              <li>タグ</li>
              <li>フロー</li>
            </ul>
            <p>
              がすべて削除されます。
              <br />
              ※一度削除すると元に戻せません。
            </p>
          </div>

          <div>
            <p>
              <Text strong>
                ②メールアドレスの転送設定の解除をお願いします。
              </Text>
            </p>
            <p>
              削除対象のメールアドレスの
              <Text underline>転送設定が解除されているか</Text>
              を今一度ご確認下さい。
            </p>
          </div>
        </div>
      ),
      onOk: async () => {
        this.setState({ isDeleting: true });
        await db
          .collection(`companies/${this.props.store.signInCompany}/teams`)
          .doc(this.props.team.id)
          .delete();
        logEvent(eventNames.delete_team);
        this.setState({ isEditing: false, isDeleting: false });

        // お知らせ
        notification['success']({ message: 'チームを削除しました' });
      },
      onCancel: () => {
        //
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  toTeam = () =>
    this.props.history.push(`/settings/teams/${this.props.team.id}/members`);

  render() {
    const { team } = this.props;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    // Only show error after a field is touched.
    const nameError = isFieldTouched('name') && getFieldError('name');

    const { isEditing, isUpdating, isDeleting } = this.state;
    return (
      <div className="mb-[10px] flex w-[450px] items-center justify-between">
        {isEditing ? (
          <Form layout="inline" onSubmit={this.update}>
            <Form.Item
              validateStatus={nameError ? 'error' : ''}
              help={nameError || ''}
            >
              {getFieldDecorator('name', {
                initialValue: team.name,
                rules: [
                  { required: true, message: 'チーム名を入力して下さい' },
                ],
              })(<Input placeholder="チーム名" disabled={isUpdating} />)}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError()) || isDeleting}
                loading={isUpdating}
              >
                更新
              </Button>
            </Form.Item>
            <Form.Item>
              <Tooltip
                title="権限がありません"
                visible={!this.props.store.me.isAdmin}
              >
                <Button
                  type="danger"
                  loading={isDeleting}
                  disabled={!this.props.store.me.isAdmin || isUpdating}
                  onClick={this.delete}
                >
                  削除
                </Button>
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <Button onClick={this.cancel} disabled={isUpdating || isDeleting}>
                キャンセル
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              className="text-[14px] font-bold"
              style={{ color: color.common.text2 }}
              onClick={this.toTeam}
            >
              {team.name}
            </a>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <Tooltip
              title="権限がありません"
              visible={!this.props.store.me.isAdmin}
            >
              <div>
                <DefaultButton
                  className={`ml-[12.5px] h-[40px] w-[100px] max-w-[100px]`}
                  onClick={this.edit}
                  disabled={!this.props.store.me.isAdmin}
                >
                  編集
                </DefaultButton>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    );
  }
}

export default compose<
  Props,
  Omit<Props, 'store' | 'router' | 'history' | 'form'>
>(
  withRouter,
  inject('store'),
  observer,
  Form.create({ name: 'edit_team' })
)(Index);
