import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import React, { useLayoutEffect, useMemo } from 'react';
import { COMPANY_EMAIL_PATHS, createCompanyEmailRoutes } from '../../routes';
import { CompanyEmailTabKey } from './CompnayEmailTabKey';
import { TabsSettingPageContainer } from '../../common/TabsSettingPageContainer/TabsSettingPageContainer';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { TemplatesWithLogic } from '../templates/Templates/TemplatesWithLogic';
import { SignaturesWithLogic } from '../signatures/Signatures/SignaturesWithLogic';
import { TagsWithLogic } from '../tags/Tags/TagsWithLogic';
import { FiltersWithLogic } from '../filters/Filters/FiltersWithLogic';
import { StatusesWithLogic } from '../statuses/Statuses/StatusesWithLogic';
import { InboxesWithLogic } from '../inbox/Inboxes/InboxesWithLogic';
import { AutoReplySettingsWithLogic } from '../autoReply/AutoReplySettings/AutoReplySettingsWithLogic';
import { ArrowUp } from 'components/icons';

export const CompanyEmailWithLogic = observer(() => {
  const store = useStore();
  const { teamId } = useParams<{ teamId: string }>();
  const history = useHistory();
  const match = useRouteMatch({
    path: COMPANY_EMAIL_PATHS.base,
    exact: true,
  });

  const team = store.getTeam(teamId);
  const routes = useMemo(() => createCompanyEmailRoutes(history), [history]);

  useLayoutEffect(() => {
    // settings/teams/:teamId/mail にアクセスされた場合に settings/teams/:teamId/mail/inboxes にリダイレクトさせる
    if (match) {
      history.replace(
        routes.getPathNameForTab(CompanyEmailTabKey.Inbox, teamId)
      );
    }
  }, [match, routes, teamId]);

  if (!team) {
    return null;
  }

  return (
    <TabsSettingPageContainer
      title={`${team.name} のメール設定`}
      pages={[
        {
          pathname: routes.getPathNameForTab(CompanyEmailTabKey.Inbox, teamId),
          label: 'メールアドレス',
          element: () => (
            <InboxesWithLogic isPrivateTeam={false} routes={routes} />
          ),
        },
        {
          pathname: routes.getPathNameForTab(
            CompanyEmailTabKey.Template,
            teamId
          ),
          label: 'テンプレート',
          element: () => (
            <TemplatesWithLogic
              teamId={teamId}
              routes={routes.templateRoutes}
            />
          ),
        },
        {
          pathname: routes.getPathNameForTab(
            CompanyEmailTabKey.Signature,
            teamId
          ),
          label: '署名',
          element: () => (
            <SignaturesWithLogic
              teamId={teamId}
              routes={routes.signatureRoutes}
            />
          ),
        },
        {
          pathname: routes.getPathNameForTab(CompanyEmailTabKey.Tag, teamId),
          label: 'タグ',
          element: () => (
            <TagsWithLogic teamId={teamId} routes={routes.tagRoutes} />
          ),
        },
        {
          pathname: routes.getPathNameForTab(CompanyEmailTabKey.Filter, teamId),
          label: 'フロー',
          element: () => (
            <FiltersWithLogic teamId={teamId} routes={routes.filterRoutes} />
          ),
        },
        {
          pathname: routes.getPathNameForTab(
            CompanyEmailTabKey.AutoReply,
            teamId
          ),
          label: '自動返信',
          icon: ArrowUp,
          element: () => (
            <AutoReplySettingsWithLogic
              teamId={teamId}
              routes={routes.autoReplyRoutes}
            />
          ),
        },
        {
          pathname: routes.getPathNameForTab(CompanyEmailTabKey.Status, teamId),
          label: 'ステータス',
          icon: ArrowUp,
          element: () => <StatusesWithLogic teamId={teamId} />,
        },
      ]}
    />
  );
});
