import Highlighter from 'react-highlight-words';
import React, { useMemo } from 'react';

type Props = {
  text: string;
  highlightWords: string[];
  trimText?: boolean | number;
  className?: string;
};

export const TextHighlighter = ({
  text,
  highlightWords,
  trimText = false,
  className,
}: Props) => {
  const trimLength = useMemo(() => {
    if (typeof trimText === 'boolean') {
      return trimText ? 10 : 0;
    } else if (trimText > 0) {
      return trimText;
    }
    return 0;
  }, [trimText]);
  const trimmedText = useMemo(() => {
    if (trimLength === 0) {
      return text;
    }

    const lowerText = text.toLocaleLowerCase();
    const indexes = highlightWords
      .map((w) => w.toLocaleLowerCase())
      .map((w) => lowerText.indexOf(w))
      .filter((i) => i >= 0);
    const firstIndex =
      trimLength > 0 && indexes.length > 0 ? Math.min(...indexes) : 0;
    const shouldTrim = firstIndex >= trimLength;
    if (shouldTrim) {
      return '…' + text.substring(firstIndex - trimLength + 1);
    } else {
      return text;
    }
  }, [text, highlightWords, trimLength]);
  return (
    <Highlighter
      textToHighlight={trimmedText}
      searchWords={highlightWords}
      className={className}
      highlightClassName="p-0 bg-wood-400/50 rounded"
      autoEscape
    />
  );
};
