import { useEffect, useState } from 'react';
import { db, db9 } from '../../../../firebase';
import { useStore } from 'hooks/useStore';
import {
  createPortalSession,
  fetchSubscription,
  fetchSubscriptionItem,
  updateSubscriptionOptions,
  updateSubscriptionProduct,
} from '../../../../functions';
import { message as toast } from 'antd';
import { Product } from '../Product';
import { PlanCard } from './PlanCard';
import { StripeProductOptionDialog } from './StripeProductOptionDialog';
import { OpenInNew, OpenInNewIcon } from 'components/icons';
import {
  ProductOption,
  StripeCard,
  StripePaymentMethod,
  StripeProductOption,
  StripeProductOptionType,
  StripeProductOptionValue,
} from './types';
import moment from 'moment';
import { Button, Icon, Loading } from 'components/basics';
import { atom, useAtomValue } from 'jotai';
import { signInCompanyStripeProductAtom } from '../../../../atoms/firestore/signInCompanyStripeProduct';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { loadable } from 'jotai/utils';
import { allTeamStatusesInGroupAtom } from '../../../../atoms/firestore/customStatuses';

/**
 * バージョン６またはそれ以上
 */
export const MIN_UI_VERSION = 6;

const formatFileSize = (size: number) => {
  const gb = size / 1024 / 1024 / 1024;
  return `${gb.toFixed(2)} GB`;
};

const getOptions = async (
  interval: 'month' | 'year'
): Promise<StripeProductOption[]> => {
  const snapshot = await db
    .collection('stripe')
    .doc('collections')
    .collection('products')
    .where('active', '==', true)
    .where('metadata.itemType', '==', 'option')
    .get();

  return Promise.all(
    snapshot.docs.map<Promise<StripeProductOption>>(async (doc) => {
      const priceSnapshot = await doc.ref
        .collection('prices')
        .where('active', '==', true)
        .where('interval', '==', interval)
        .get();

      let price = 0;
      let priceId = null;
      if (priceSnapshot.docs.length > 0) {
        const priceData = priceSnapshot.docs[0].data();

        price = priceData?.unit_amount || 0;
        priceId = priceSnapshot.docs[0].id;
      }

      return {
        id: doc.id,
        name: doc.data().name,
        optionType: doc.data().metadata?.optionType,
        price,
        priceId,
        interval,
      };
    })
  );
};

const productsAtom = atom<Promise<Product[]>>(async (get) => {
  const currentProduct = get(signInCompanyStripeProductAtom);
  if (!currentProduct) {
    return [];
  }

  const products = await getDocs(
    query(
      collection(db9, 'stripe/collections/products'),
      where('active', '==', true),
      where('metadata.version', '==', `${currentProduct.version}`),
      orderBy('metadata.order')
    )
  );
  return products.docs.map((product) => {
    const data = product.data();
    return new Product({
      id: product.id,
      name: data.name,
      description: data.description,
      metadata: data.metadata,
      badge: data.name === 'ビジネス',
    });
  });
});

const loadableProductsAtom = loadable(productsAtom);

/**
 * MinV6PaymentのPaymentコンポーネント
 * V6またはそれ以上のバージョンの場合はMinV6Payment配下のコンポーネントを利用する。
 */
export const Payment = () => {
  const store = useStore();
  const [updating, setUpdating] = useState(false);
  const [customerPortalLoading, setCustomerPortalLoading] = useState(false);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<number | null>(null);
  const productsResult = useAtomValue(loadableProductsAtom);
  const [paymentMethod, setPaymentMethod] =
    useState<StripePaymentMethod | null>(null);

  const [stripeCard, setStripeCard] = useState<StripeCard | null>(null);
  const [stripeProductOptions, setStripeProductOptions] =
    useState<StripeProductOption[]>();
  const [purchasedOptions, setPurchasedOptions] =
    useState<StripeProductOptionValue[]>();
  const [openChange, setOpenChange] = useState<boolean>(false);

  const currentProduct = useAtomValue(signInCompanyStripeProductAtom);
  const [, customStatuses] = useAtomValue(allTeamStatusesInGroupAtom);

  const loadSubscription = async () => {
    const [subscription, subscriptionItem] = await Promise.all([
      fetchSubscription({
        companyId: store.signInCompany,
      }).then((r) => r.data),
      fetchSubscriptionItem({
        companyId: store.signInCompany,
        itemType: 'plan',
      })
        .then((r) => r?.data)
        .catch((e) => {
          if (e.code !== 'not-found') throw e;
        }),
    ]);

    const interval = subscriptionItem?.price.recurring.interval;

    const subscriptionItems = subscription?.items?.data || [];

    if (interval) {
      const options = await getOptions(interval);
      setStripeProductOptions(options);

      if (subscriptionItems.length > 0) {
        const mappedPurchasedOptions = subscriptionItems
          .filter((item: any) => {
            return options.find(
              (productOption) => productOption.id === item?.plan?.product
            );
          })
          .map((item: any) => ({
            optionId: item?.plan?.product,
            priceId: item?.price?.id,
            checked: true,
            seat: item?.quantity || 0,
          }));

        setPurchasedOptions(mappedPurchasedOptions);
      }
    }

    setPaymentMethod(StripePaymentMethod.invoice);

    setCurrentPeriodEnd(subscription?.current_period_end);

    const defaultPaymentMethod =
      subscription?.customer?.invoice_settings?.default_payment_method;
    if (defaultPaymentMethod?.type === 'card') {
      setStripeCard({
        last4: defaultPaymentMethod.card.last4,
        exp_month: defaultPaymentMethod.card.exp_month,
        exp_year: defaultPaymentMethod.card.exp_year,
        brand: defaultPaymentMethod.card.brand,
      });
      setPaymentMethod(StripePaymentMethod.card);
    }
  };

  useEffect(() => {
    loadSubscription().then();
  }, []);

  const redirectToCustomerPortal = async () => {
    setCustomerPortalLoading(true);
    const resp = await createPortalSession({
      companyId: store.signInCompany,
      returnUrl: window.location.href,
    });
    if (!resp?.data?.url) {
      setCustomerPortalLoading(false);
      return;
    }
    window.location.assign(resp.data.url);
  };

  const updateProduct = async (product?: Product | null) => {
    if (!product || updating) {
      return;
    }

    const key = 'updateProduct';
    toast.loading({ content: 'プランを変更中です…', duration: 0, key });
    setUpdating(true);
    await updateSubscriptionProduct({
      companyId: store.signInCompany,
      plan: product.plan,
    });
    toast.success({ content: 'プランを変更しました', key });
    setUpdating(false);
  };

  const joinableUserCount = store.users.length + store.invitedUsers.length;

  const plans =
    productsResult.state === 'hasData'
      ? productsResult.data.map<ProductOption>((p) => {
          const inboxExceeded =
            p.maxInboxes !== null &&
            (store.inboxesLength ?? 0) > Number(p.maxInboxes);
          const usersExceeded =
            p.maxUsers !== null &&
            (joinableUserCount ?? 0) > Number(p.maxUsers);
          const customStatusesExceeded =
            !p.customStatusSupported &&
            Object.values(customStatuses).some((statuses) => statuses.length);
          const isCurrent = p.id === currentProduct?.id;

          return {
            product: p,
            exceeds: {
              inbox: inboxExceeded,
              users: usersExceeded,
              customStatuses: customStatusesExceeded,
            },
            isCurrent,
          };
        })
      : [];

  const onSubmitOption = async (
    newProductOptions: StripeProductOptionValue[]
  ) => {
    const key = 'updateProductOption';
    toast.loading({ content: 'オプションを変更中です…', duration: 0, key });

    setOpenChange(false);

    await updateSubscriptionOptions({
      companyId: store.signInCompany,
      values: newProductOptions.map((productOption) => {
        const productOptionDetail = stripeProductOptions?.find(
          (productOptionInner) =>
            productOptionInner.id === productOption.optionId
        );

        let quantity = 0;
        if (productOption.checked) {
          quantity =
            productOptionDetail?.optionType === StripeProductOptionType.seat
              ? productOption?.seat || 0
              : 1;
        }

        return {
          priceId: productOptionDetail?.priceId || null,
          quantity,
        };
      }),
    });

    setTimeout(async () => {
      await loadSubscription();
      toast.success({ content: 'オプションを変更しました', key });
    }, 1000);
  };

  return (
    <>
      <div
        className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
      >
        <h1 className="mb-6 text-xl font-bold leading-8">お支払い</h1>
        <h1 className="mb-0 text-lg font-bold">料金</h1>

        <div className="pb-2 pt-4">
          <div className="flex justify-start gap-4">
            {plans.map((plan) => {
              const p = plan.product;
              let tooltip: string | undefined;
              if (plan.exceeds.inbox) {
                tooltip = `このプランで使用できる共有メールアドレス数(${p.maxInboxes}個まで)を超えているため選択できません`;
              } else if (plan.exceeds.users) {
                tooltip = `このプランで使用できるユーザー数(招待中のユーザー含め${p.maxUsers}名まで)を超えているため選択できません`;
              } else if (plan.exceeds.customStatuses) {
                tooltip = `カスタムステータスが設定されているため選択できません`;
              }

              const disabled =
                plan.isCurrent ||
                plan.exceeds.inbox ||
                plan.exceeds.users ||
                plan.exceeds.customStatuses;

              return (
                <PlanCard
                  key={p.id}
                  product={p}
                  updating={!disabled && updating}
                  disabled={disabled}
                  buttonLabel={
                    plan.isCurrent ? '選択中のプランです' : undefined
                  }
                  version={currentProduct?.version || MIN_UI_VERSION}
                  tooltip={tooltip}
                  onSelect={() => updateProduct(plan.product)}
                ></PlanCard>
              );
            })}
          </div>
        </div>

        <a
          href="https://yaritori.jp/plan/"
          target="_blank"
          rel="noreferrer"
          className="mb-6 flex items-center gap-0.5 text-sm font-bold"
        >
          料金プランの詳細を確認
          <OpenInNewIcon />
        </a>

        <div className="w-full max-w-[752px]">
          <div className="grid grid-cols-[auto_1fr] items-start gap-2 border-b border-sumi-300 pb-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">
              オプション
            </p>
            <div className="flex flex-col items-start justify-start gap-2 text-base text-sumi-600">
              {purchasedOptions == null || stripeProductOptions == null ? (
                <div className="flex h-6 items-center">
                  <Loading size={18} />
                </div>
              ) : (
                <>
                  {purchasedOptions.length === 0 ? (
                    <div>現在オプションは購入していません</div>
                  ) : (
                    <div>
                      {stripeProductOptions
                        .filter((option1) =>
                          purchasedOptions.some(
                            (option2) => option1.id === option2.optionId
                          )
                        )
                        .map((option) => option.name)
                        .join('・')}
                      はオプションでご利用いただけます
                    </div>
                  )}
                </>
              )}
              <Button
                onClick={() => setOpenChange(!openChange)}
                className="h-8 py-0 font-bold"
                disabled={
                  purchasedOptions == null || stripeProductOptions == null
                }
              >
                オプションの購入
              </Button>
            </div>
          </div>

          <div className="flex items-start gap-2 border-b border-sumi-300 py-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">使用容量</p>
            <div>
              <p className="mb-0 text-base text-sumi-900">
                {formatFileSize(store.company?.storageSize || 0)}
              </p>
              <p className="mb-0 text-sumi-600">
                使用容量が10GB(100万通程度)を超えた場合は、追加で10GBごとに月額1,000円が企業アカウントに加算されます。
              </p>
            </div>
          </div>

          <h1 className="my-6 text-lg font-bold text-sumi-900">請求方法</h1>

          <div className="flex items-start gap-2 border-b border-sumi-300 pb-4">
            <div className="w-[136px] shrink-0 text-sm text-sumi-900">
              お支払方法
            </div>
            <div className="flex flex-col items-start gap-2">
              {!paymentMethod && (
                <div className="flex h-6 items-center">
                  <Loading size={18} />
                </div>
              )}
              <div className="text-base text-sumi-900">
                {paymentMethod === StripePaymentMethod.card &&
                  'クレジットカード決済'}
                {paymentMethod === StripePaymentMethod.invoice && '請求書発行'}
              </div>
              {stripeCard && (
                <div className="text-base  text-sumi-900">
                  {stripeCard?.last4} {stripeCard?.brand}{' '}
                  {stripeCard?.exp_month}/{stripeCard?.exp_year}
                </div>
              )}
              {paymentMethod === StripePaymentMethod.card && (
                <div className="text-sumi-600">
                  請求書発行をご希望の場合は担当者にお伝えください。
                </div>
              )}
              <Button
                variant="outlined"
                onClick={redirectToCustomerPortal}
                className="h-8 py-0 font-bold"
                loading={customerPortalLoading}
              >
                変更
              </Button>
            </div>
          </div>

          <div className="flex items-start gap-2 border-b border-sumi-300 py-4 text-sumi-900">
            <div className="w-[136px] shrink-0 text-sm">次の支払発生日</div>
            <div className="flex flex-col items-start gap-2">
              {currentPeriodEnd ? (
                <div className="text-base">
                  {moment.unix(currentPeriodEnd).format('YYYY年MM月DD日')}
                </div>
              ) : (
                <div className="flex h-6 items-center">
                  <Loading size={18} />
                </div>
              )}
              <Button
                onClick={redirectToCustomerPortal}
                variant="text"
                className="flex h-auto items-center gap-0.5 p-0 font-bold underline sm:p-0"
                loading={customerPortalLoading}
              >
                ご請求の詳細
                <Icon icon={OpenInNew} size="1.2em" />
              </Button>
            </div>
          </div>
        </div>

        {stripeProductOptions != null && purchasedOptions != null && (
          <StripeProductOptionDialog
            stripeProductOptions={stripeProductOptions}
            openChange={openChange}
            setOpenChange={setOpenChange}
            defaultValues={purchasedOptions}
            onSubmit={onSubmitOption}
          />
        )}
      </div>
    </>
  );
};
