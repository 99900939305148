import { useStore } from '../../hooks/useStore';
import { useHistory, useParams } from 'react-router-dom';
import { SearchQuery } from '../../store/search';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { HorizontalScrollArea } from '../../components/basics/HorizontalScrollArea';
import { SearchRangeSelect } from '../Common/MainNav/search/SearchRangeSelect';
import { Button, Icon } from '../../components/basics';
import { Close } from '../../components/icons';
import { useAtomValue, useSetAtom } from 'jotai';
import { usersAtom } from '../../atoms/firestore/user';
import { meAtom } from '../../atoms/auth';
import {
  getSearchRange,
  searchRangeToQuery,
} from '../Common/MainNav/search/searchRange';
import { dirtyResetAtom } from '../Common/MainNav/search/useSearch';

export const FilterDisplay = observer((): JSX.Element => {
  const store = useStore();
  const { searchStore } = store;
  const { tray, query: queryString } = useParams<{
    tray: string;
    query: string;
  }>();
  const history = useHistory();

  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const resetFilter = useSetAtom(dirtyResetAtom);

  const query: SearchQuery = useMemo(() => {
    try {
      return JSON.parse(decodeURIComponent(queryString));
    } catch (e) {
      console.error(e);
      history.push('/me/drafts');
      return {};
    }
  }, [queryString]);
  const searchRange = useMemo(() => getSearchRange(query), [query]);

  const date = useMemo(() => {
    if (!query.before && !query.after) {
      return undefined;
    }
    let s = '';
    if (query.after) {
      s += query.after;
    }
    s += ' ~ ';
    if (query.before) {
      s += query.before;
    }
    return s;
  }, [query.before, query.after]);

  const updateQueryAndSearch = (update: Partial<SearchQuery>) => {
    searchStore.query = {
      ...searchStore.query,
      ...update,
    };
    history.push(
      `/search/${tray}/${encodeURIComponent(JSON.stringify(searchStore.query))}`
    );
  };

  useEffect(() => {
    if (store.inboxesLoading || store.tagsLoading) {
      return;
    }
    searchStore.query = query;
    switch (tray) {
      case 'messages':
        searchStore.searchMessages({});
        break;
      case 'sent':
        searchStore.searchSent({});
        break;
      case 'deleted':
        searchStore.searchMessages({
          deleted: true,
        });
        break;
    }
  }, [store.inboxesLoading, store.tagsLoading, query, tray]);

  const tag = query.tags ? store.getTag(query.tags[0]) : undefined;
  const assigneeName = useMemo(() => {
    const assignee = query.assignee
      ? users.find((u) => u.id === query.assignee)
      : undefined;
    if (!assignee) {
      return undefined;
    }
    if (assignee.id === me.id) {
      return '自分';
    }
    return assignee.name;
  }, [query.assignee, me.id]);

  return (
    <HorizontalScrollArea className="hidden h-12 items-center bg-white text-sm sm:flex">
      <div className="items-center gap-2 whitespace-nowrap pl-4 pr-4 lg:pl-2">
        <div className="inline-block">
          <SearchRangeSelect
            value={searchRange}
            onChange={(searchRange) =>
              updateQueryAndSearch(searchRangeToQuery(searchRange))
            }
            variants={{ rounded: 'lg', border: false }}
            className="mr-2 min-w-fit bg-sea-500 font-bold text-white"
          />
        </div>
        {query.status && (
          <FilterItem
            label="ステータス"
            value={query.status}
            onDelete={() => updateQueryAndSearch({ status: undefined })}
          />
        )}
        {query.from && (
          <FilterItem
            label="From"
            value={query.from}
            onDelete={() => updateQueryAndSearch({ from: undefined })}
          />
        )}
        {query.toOrCc && (
          <FilterItem
            label="To,Cc"
            value={query.toOrCc}
            onDelete={() => updateQueryAndSearch({ toOrCc: undefined })}
          />
        )}
        {query.subjectOrText && (
          <FilterItem
            label="件名,本文"
            value={query.subjectOrText}
            onDelete={() => updateQueryAndSearch({ subjectOrText: undefined })}
          />
        )}
        {tag && (
          <FilterItem
            label="タグ"
            value={tag.name}
            onDelete={() => updateQueryAndSearch({ tags: undefined })}
          />
        )}
        {assigneeName && (
          <FilterItem
            label="担当者"
            value={assigneeName}
            onDelete={() => updateQueryAndSearch({ assignee: undefined })}
          />
        )}
        {date && (
          <FilterItem
            label="受信日時"
            value={date}
            onDelete={() =>
              updateQueryAndSearch({ before: undefined, after: undefined })
            }
          />
        )}
        <Button
          variant="text"
          className="mr-10 h-8 text-xs no-underline"
          onClick={() => resetFilter()}
        >
          フィルターをリセット
        </Button>
      </div>
    </HorizontalScrollArea>
  );
});

type FilterItemProps = {
  label: string;
  value: string;
  onDelete: () => void;
};

const FilterItem = ({ label, value, onDelete }: FilterItemProps) => {
  return (
    <div className="mr-2 inline-flex h-8 items-center gap-0.5 rounded-lg bg-sea-500 pl-2 font-bold text-white">
      <div className="flex select-none gap-2 whitespace-nowrap">
        <div>{label}</div>
        <div>{value}</div>
      </div>
      <button
        type="button"
        className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-transparent p-0"
        onClick={() => onDelete()}
      >
        <Icon icon={Close} size={14} />
      </button>
    </div>
  );
};
