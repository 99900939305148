import { v4 as uuidv4 } from 'uuid';
import { message as messageNotification } from 'antd';
import firebase from 'firebase.js';
import { removeUndefined } from 'lib';

export const inline = 'inline';

export const generateAttachment = (file) => {
  const { content_id, uid, storagePath, size, type, name } = file;
  // Sendgridで送った場合に文字化けするのを防いでいる
  const filename = `=?UTF-8?B?${btoa(unescape(encodeURIComponent(name)))}?=`;
  return removeUndefined({
    content_id,
    uid,
    storagePath,
    size,
    type: type.length > 0 ? type : 'application/octet-stream',
    name, // name for rc-upload
    filename, // name for sendgrid
    disposition: Boolean(content_id) ? inline : 'attachment',
  });
};

export const uploadFileToStorage = async (
  file,
  storagePath,
  uploader,
  teamId
) => {
  return new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const ref = storageRef.child(storagePath);

    const uploadTask = ref.put(file, {
      customMetadata: {
        teamId,
        uploader,
      },
    });

    const attachmentId = uuidv4();
    messageNotification.loading({
      content: `${file.name}をアップロード中です（0%完了）`,
      key: attachmentId,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        messageNotification.loading({
          content: `${file.name}をアップロード中です（${Math.floor(
            progress
          )}%完了）`,
          key: attachmentId,
        });
      },
      (error) => {
        console.error('uploadFileToStorage:', error);
        messageNotification.error({
          content: `${file.name}のアップロードに失敗しました`,
          key: attachmentId,
        });
        reject();
      },
      async () => {
        // 成功
        file.storagePath = uploadTask.snapshot.ref.fullPath; // Fileオブジェクトのため、スプレッド演算子を使用しない
        messageNotification.success({
          content: `${file.name}のアップロードが完了しました。`,
          key: attachmentId,
          duration: 2,
        });
        resolve(generateAttachment(file));
      }
    );
  });
};

export const getScrollContainerId = () => {
  return 'ConversationContainer';
};
