import { useRouteMatch } from 'react-router-dom';
import { MessageItemWrapper } from 'components/MessageList';
import level from '../../../../../../font/level';
import moment from 'moment';
import { Draft as DraftEntity } from '../../../../../../firestore/entity/draft';

export interface DraftProps {
  draft: DraftEntity;
  toFunc: (to: string) => void;
  to: string;
}

export default function Draft({ draft, to, toFunc }: DraftProps) {
  const match = useRouteMatch({ path: to });

  return (
    <MessageItemWrapper onClick={() => toFunc(to)} selected={!!match}>
      <div className="pl-5">
        <div className="flex justify-between">
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap pr-[8px] font-bold"
            style={{ fontSize: level[1] }}
          >
            {draft.to.length > 0 ? draft.to.join(' ') : '（宛先がありません）'}
          </div>
          <div
            className="whitespace-nowrap text-[#bcbccb]"
            style={{ fontSize: level[0] }}
          >
            {moment(draft.date).isSame(moment(), 'day')
              ? draft.date.format('HH:mm')
              : draft.date.format('M月D日')}
          </div>
        </div>
        <div
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          style={{ fontSize: level[1] }}
        >
          {draft.subject.length > 0 ? draft.subject : '（件名がありません）'}
        </div>
        <div
          className="overflow-hidden text-ellipsis whitespace-nowrap text-[#bcbccb]"
          style={{ fontSize: level[1] }}
        >
          {draft.body.length > 0 ? draft.body : '（本文がありません）'}
        </div>
      </div>
    </MessageItemWrapper>
  );
}
