import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { H1 } from '../../Common/H1';
import { H2 } from '../../Common/H2';
import { Comment } from '../../Common/Comment';
import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import Tooltip from '../../Common/Tooltip';
import Team from './Team/Team';
import { Input as AntdInput, notification } from 'antd';
import { db } from '../../../firebase';
import firebase from 'firebase.js';
import { eventNames, logEvent } from '../../../analytics';
import { History } from 'history';
import { Store } from '../../../store';

type Props = {
  store: Store;
  history: History;
};

type State = {
  name: string;
  isCreating: boolean;
};

class Index extends React.Component<Props, State> {
  formRef: React.RefObject<AntdInput>;
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      isCreating: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.formRef.current?.focus();
  }

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  create = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name } = this.state;
    if (name.length === 0) return;

    this.setState({ isCreating: true });

    await db
      .collection(`companies/${this.props.store.signInCompany}/teams`)
      .add({
        name,
        roles:
          this.props.store.joinedTeams.length < 10
            ? { [this.props.store.me.id]: 'owner' }
            : {},
        autoReplyDrafterId: this.props.store.me.id,
        isPrivate: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    logEvent(eventNames.add_team);
    this.setState({ name: '', isCreating: false });

    // お知らせ
    notification['success']({ message: 'チームを作成しました' });
  };

  toTeam = (id: string) =>
    this.props.history.push(`/settings/teams/${id}/inboxes`);

  render() {
    const { teams, me } = this.props.store;
    const { name, isCreating } = this.state;

    const createDisabled = !me.isAdmin || isCreating;
    return (
      <div
        className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
      >
        <H1>チーム設定</H1>

        <div className="mb-[50px]">
          <H2>チームを作成する</H2>
          <form onSubmit={this.create}>
            <div style={{ display: 'flex' }}>
              <Tooltip title="権限がありません" visible={!me.isAdmin}>
                <DefaultInput
                  value={name}
                  onChange={this.onChangeName}
                  placeholder="チーム名"
                  disabled={createDisabled}
                  required
                  ref={this.formRef}
                  className={`mb-[10px] h-[40px] w-[400px]`}
                />
              </Tooltip>
              <Tooltip title={'権限がありません'} visible={!me.isAdmin}>
                <div>
                  <DefaultButton
                    className={`ml-[12.5px] h-[40px] w-[100px] max-w-[100px]`}
                    type="primary"
                    disabled={createDisabled}
                  >
                    作成
                  </DefaultButton>
                </div>
              </Tooltip>
            </div>
          </form>
          <Comment>例：CS、カスタマー対応、営業</Comment>
        </div>

        {teams.length > 0 && (
          <div className="mb-[50px]">
            <H2>作成済みのチーム</H2>
            {teams.map((t) => (
              <Team team={t} key={t.id} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default compose<Props, Omit<Props, 'store' | 'router' | 'history'>>(
  withRouter,
  inject('store'),
  observer
)(Index);
