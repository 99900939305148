import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SearchFilterData } from '../MainSearch';
import { SearchQuery } from '../../../../store/search';
import { useStore } from '../../../../hooks/useStore';
import moment from 'moment';
import { Options } from '../../../../components/forms';
import { useForm } from 'react-hook-form';
import { Store } from '../../../../store';
import { atom, useAtom, useSetAtom } from 'jotai';
import { Tag } from 'lib';
import { getSearchRange, searchRangeToQuery } from './searchRange';

export const Tray = {
  messages: '受信トレイ',
  sent: '送信済み',
  deleted: 'ゴミ箱',
} as const;
export type Tray = keyof typeof Tray;

export type SearchRange =
  | {
      type: 'allInboxes';
    }
  | {
      type: 'teamInboxes';
      teamId: string;
    }
  | {
      type: 'inbox';
      teamId: string;
      inboxId: string;
    };

type UseSearchResult = {
  filterForm: ReturnType<typeof useForm<SearchFilterData>>;
  trayOptions: Options<Tray>;
  tagOptions: Options<string>;
  memberOptions: Options<string | null>;
  search: () => void;
};

const trayOptions: Options<Tray> = Object.entries(Tray).map(
  ([value, label]) => ({
    value: value as Tray,
    label,
  })
);

const resetAtom = atom<(() => void) | undefined>(undefined);
const dirtyAtom = atom(false);
export const dirtyResetAtom = atom<boolean, [], void>(
  (get) => get(dirtyAtom),
  (get) => get(resetAtom)?.()
);

const returnPathAtom = atom<string>();

export const useSearch = (onSearch?: () => void): UseSearchResult => {
  const setReset = useSetAtom(resetAtom);
  const setDirty = useSetAtom(dirtyAtom);
  const [returnPath, setReturnPath] = useAtom(returnPathAtom);

  const history = useHistory();
  const store = useStore();
  const { searchStore } = store;
  const { query } = searchStore;
  const match = useRouteMatch('/search/:tray/:query');
  const params = (match ? match.params : {}) as {
    tray?: Tray;
    query?: string;
  };

  const filterToQuery = (filter: SearchFilterData): SearchQuery => {
    const {
      searchRange,
      status,
      from,
      toOrCc,
      subjectOrText,
      tag,
      assignee,
      after,
      before,
      hasAttachments,
      attachmentsFilename,
    } = filter;

    return {
      ...query,
      ...searchRangeToQuery(searchRange),
      keywords: filter.keyword
        ? filter.keyword.replace('　', ' ').split(' ')
        : undefined,
      status: status || undefined,
      from: from || undefined,
      toOrCc: toOrCc || undefined,
      subjectOrText: subjectOrText || undefined,
      tags: tag ? [tag] : undefined,
      assignee: assignee === '' ? undefined : assignee,
      after: after ? moment(after).format('YYYY-MM-DD') : undefined,
      before: before ? moment(before).format('YYYY-MM-DD') : undefined,
      hasAttachments: hasAttachments || undefined,
      attachmentsFilename: attachmentsFilename || undefined,
    };
  };

  const filterForm = useForm<SearchFilterData>({
    defaultValues: {
      keyword: '',
      searchRange: { type: 'allInboxes' },
      tray: 'messages',
      status: '',
      from: '',
      toOrCc: '',
      subjectOrText: '',
      tag: '',
      assignee: '',
      after: null,
      before: null,
      hasAttachments: false,
      attachmentsFilename: '',
    },
  });

  const onSubmit = (data: SearchFilterData) => {
    if (!searchStore.inSearch) {
      const path = window.location.href.substring(
        window.location.origin.length
      );
      setReturnPath(path);
    }
    history.push(
      `/search/${data.tray}/${encodeURIComponent(
        JSON.stringify(filterToQuery(data))
      )}`
    );
    onSearch?.();
  };

  const updateQuery = () => {
    const { getValues, reset } = filterForm;
    const range = getValues('searchRange');

    const tagId = getValues('tag');
    const tagOptions = getTargetTags(range, store);
    const foundTag = tagOptions.find((t) => t.id === tagId);
    if (tagId) {
      if (foundTag) {
        reset({ tag: foundTag.isInbox ? '' : tagId }, { keepValues: true });
      } else {
        reset({ tag: '' }, { keepValues: true });
      }
    }

    const tray = getValues('tray');
    searchStore.query.sender = tray === 'sent' ? store.me.id : undefined;
  };

  useEffect(() => {
    setReset(() => () => {
      filterForm.reset();

      if (!searchStore.inSearch) {
        return;
      }

      const toPath = returnPath ?? '/me/drafts';
      history.push(toPath);
    });
  }, [filterForm, searchStore.inSearch, returnPath, history]);

  useEffect(() => {
    setDirty(filterForm.formState.isDirty);
  }, [filterForm.formState.isDirty]);

  useEffect(() => {
    const { getValues } = filterForm;
    const tray = getValues('tray');
    const range = getValues('searchRange');
    const query = searchStore.query;
    if (range.type === 'allInboxes' || tray === 'sent') {
      query.inbox = false;
    }
    if (range.type !== 'inbox') {
      query.teamIds = undefined;
      query.inboxId = undefined;
    }
    searchStore.query = query;
    updateQuery();
  }, [filterForm.watch('searchRange'), filterForm.watch('tray')]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const { tray, query: stringQuery } = params;
    if (stringQuery) {
      let query: SearchQuery | undefined;
      try {
        query = JSON.parse(decodeURIComponent(stringQuery)) as SearchQuery;
        const { after, before } = query;
        filterForm.reset(
          {
            tray: tray,
            ...query,
            keyword: query.keywords?.join(' '),
            searchRange: getSearchRange(query),
            after: after ? new Date(after) : null,
            before: before ? new Date(before) : null,
          },
          {
            keepDefaultValues: true,
          }
        );
      } catch (e) {}
    }

    if (pathname.startsWith('/search')) {
      searchStore.inSearch = true;
      return;
    } else {
      searchStore.inSearch = false;
    }
    updateQuery();
  }, [params.tray, params.query]);

  return {
    filterForm,
    search: filterForm.handleSubmit(onSubmit),
    trayOptions,
    tagOptions: getTagOptions(filterForm.watch('searchRange'), store),
    memberOptions: getMemberOptions(store),
  };
};

const getTargetTags = (range: SearchRange, store: Store): Tag[] => {
  const teamIds =
    range.type === 'allInboxes' ? store.joinedTeamIds : [range.teamId];
  return store.tags
    .filter((tag) => !tag.isInbox)
    .filter((tag) => teamIds.includes(tag.teamId));
};

const getTagOptions = (range: SearchRange, store: Store): Options<string> => {
  const tags = getTargetTags(range, store).map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));
  return [{ value: '', label: '' }, ...tags];
};

const getMemberOptions = (store: Store): Options<string | null> => {
  const teamIds = store.searchStore.query.teamIds;
  const ids = teamIds ?? store.joinedTeamIds;
  const teams = store.joinedTeams.filter((team) => ids.includes(team.id));
  const members: Options<string> = store.users
    .filter((user) => teams.some((team) => team.isMember(user.id)))
    .filter((user) => user.id !== store.me.id)
    .map((user) => ({ value: user.id, label: user.name }));
  return [
    { value: '', label: '' },
    { value: null, label: 'なし' },
    { value: store.me.id, label: '自分' },
    ...members,
  ];
};
