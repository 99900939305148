import React from 'react';
import { Skeleton } from 'antd';
import * as color from '../../../color';

export default () => {
  return (
    <div
      style={{ boxShadow: ' 0 1px 6px rgba(0, 0, 0, 0.16)' }}
      className={`m-[10px] rounded-[10px] px-0 py-[20px] bg-[${color.common.white}]`}
    >
      <div className="border-b-[0.5px] border-[#f1f1f3] px-[30px] pb-[20px]">
        {/* タイトルのスケルトン */}
        <Skeleton loading active paragraph={false} />
        {/* ユーザ情報のスケルトン */}
        <Skeleton loading avatar active paragraph={false} />
      </div>
      <div className={`pb-0 pl-[30px] pr-[30px] pt-[20px]`}>
        <div className={`mb-[20px]`}>
          {/* メール内容のスケルトン */}
          <Skeleton loading active />
        </div>
      </div>
    </div>
  );
};
