import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { Drawer } from '../../components/basics/Drawer/Drawer';
import { LeftSidebar } from './LeftSidebar/LeftSidebar';
import { SidebarSearchOverlayWithLogic } from '../../components/sidebar/SidebarSearchOverlay/SidebarSearchOverlayWithLogic';

export const SidebarDrawer = observer(() => {
  const store = useStore();
  return (
    <Drawer
      placement="left"
      onOpenChange={store.closeDrawer}
      open={store.drawerOpen}
      className="h-full w-[250px]"
    >
      <SidebarSearchOverlayWithLogic>
        <LeftSidebar />
      </SidebarSearchOverlayWithLogic>
    </Drawer>
  );
});
