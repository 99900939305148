import { Skeleton, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ChatworkRoomSelect } from './ChatworkRoomSelect';
import { ChatworkRoom, UserChatworkNotificationType } from 'lib';
import { UserNotificationTypeCheckboxGroup } from '../UserNotificationTypeCheckboxGroup';
import { UserNotificationAlert } from '../UserNotificationAlert';
import { UserNotificationHeading } from '../UserNotificationHeading';
import * as color from '../../../../color';
import { usePrevious } from 'App/Settings/common';
import { useStore } from 'hooks/useStore';
import { IntegrationRoutes } from '../IntegrationRoutes';

type Props = {
  integrationRoutes: IntegrationRoutes;
};

export const UserChatworkNotification: React.FC<Props> = observer((props) => {
  const { integrationStore } = useStore();
  const { chatworkStore: cwStore } = integrationStore;
  const [roomId, setRoomId] = useState<number | null>(null);
  const [roomsLoading, setRoomsLoading] = useState(false);

  const {
    isSupported,
    isLoadingIntegrations,
    integration,
    myNotificationSettings,
    rooms,
    isScopeMissing,
  } = cwStore;

  const prevIntegration = usePrevious(integration);

  const isSwitchDisabled = !isSupported || !integration || isScopeMissing;
  const isAlertVisible = isSupported && (!integration || isScopeMissing);
  const isNotificationTypesDisabled =
    !isSupported || !integration || !myNotificationSettings?.enabled;
  const isRoomSelectVisible = integration && !isScopeMissing;
  const isRoomSelectDisabled = !isSupported || !myNotificationSettings?.enabled;

  useEffect(() => {
    if (integration && integration !== prevIntegration) {
      (async () => {
        try {
          setRoomsLoading(true);
          await cwStore.loadRooms();
        } finally {
          setRoomsLoading(false);
        }
      })();
    }
  }, [integration, roomsLoading, prevIntegration]);

  useEffect(() => {
    setRoomId(
      rooms.find(
        (r: ChatworkRoom) => r.room_id === myNotificationSettings?.roomId
      )?.room_id || null
    );
  }, [rooms, setRoomId, myNotificationSettings]);

  const handleChangeEnabled = async (enabled: boolean) => {
    cwStore.updateMyNotificationSettings('enabled', enabled);
  };

  const handleChangeTypes = async (types: UserChatworkNotificationType[]) => {
    cwStore.updateMyNotificationSettings('types', types);
  };

  const handleChangeRoom = async (roomId: number | null) => {
    cwStore.updateMyNotificationSettings('roomId', roomId);
    setRoomId(roomId);
  };

  return (
    <Skeleton loading={isLoadingIntegrations}>
      <Tooltip
        title={
          !isSupported && 'Chatwork通知は現在のプランではサポートされていません'
        }
      >
        <div style={{ color: color.common.text2 }}>
          <div className="heading mb-[24px]">
            <UserNotificationHeading
              title="Chatwork通知"
              enabled={
                !!integration && (myNotificationSettings?.enabled ?? false)
              }
              onChangeEnabled={handleChangeEnabled}
              switchDisabled={isSwitchDisabled}
            />
          </div>
          {isAlertVisible && (
            <div className="alert mb-[24px]">
              <UserNotificationAlert
                isIntegrated={!!integration}
                gotoIntegration={() => props.integrationRoutes.toIndex()}
                target="Chatwork"
              />
            </div>
          )}
          <div className="types mb-[24px]">
            <UserNotificationTypeCheckboxGroup
              value={myNotificationSettings?.types}
              onChange={handleChangeTypes}
              disabled={isNotificationTypesDisabled}
            />
          </div>
          {isRoomSelectVisible && (
            <div className="rooms">
              通知先ルーム
              <ChatworkRoomSelect
                style={{ marginLeft: 12, width: 240 }}
                roomId={roomId ?? undefined}
                onSelectRoomId={handleChangeRoom}
                disabled={isRoomSelectDisabled}
                loading={roomsLoading}
                rooms={rooms}
              />
            </div>
          )}
        </div>
      </Tooltip>
    </Skeleton>
  );
});
