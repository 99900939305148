import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import { Icon, Spin } from 'antd';
import { createCompanyAndUserFunction } from '../../functions';
import { Store } from 'store';
import { History } from 'history';

type Props = {
  store: Store;
  history: History;
};

type State = {
  companyName: string;
  email: string;
  password: string;
  isRegistering: boolean;
  errorMessage: string;
  tip: string;
};

class _Register extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      companyName: '',
      email: '',
      password: '',
      isRegistering: false,
      errorMessage: '',
      tip: '',
    };
  }

  submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isRegistering: true, tip: 'ユーザを作成中…' });
    const { companyName, email, password } = this.state;

    try {
      // 自動ログインを行わない（ページを再読込するため）
      this.props.store.skipAutoSignIn = true;
      // firebaseに認証ユーザを作成
      await this.createUserWithEmailAndPasswordWithSigninRetry(email, password);
      this.setState({ tip: '会社を作成中…' });
      // 会社とユーザを作成
      await createCompanyAndUserFunction({
        companyName,
      });
      this.setState({ tip: 'ログイン中…' });
      // ユーザプロフィール設定画面へ移動する
      window.location.href = '/settings/teams/';
    } catch (error) {
      console.error('Register.submit:', error);
      const { code } = error as any;
      if (code === 'auth/email-already-in-use' || code === 'already-exists') {
        this.setState({
          errorMessage: '※すでに登録されているメールアドレスです',
          isRegistering: false,
        });
        return;
      }
      this.setState({
        errorMessage: '予期せぬエラーが発生しました',
        isRegistering: false,
      });
    }
  };

  createUserWithEmailAndPasswordWithSigninRetry = async (
    email: string,
    password: string
  ) => {
    let errorOnCreateUser = null;
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      return;
    } catch (error) {
      errorOnCreateUser = error;
    }
    const { message } = errorOnCreateUser as any; // code = auth/internal-error
    if (errorOnCreateUser && message.includes('deadline exceeded')) {
      const maxSigninRetry = 3;
      for (let i = 0; i < maxSigninRetry; i++) {
        // wait a sec for retry
        await new Promise((r) => setTimeout(r, 2000));
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password);
          if (firebase.auth().currentUser != null) return;
        } catch (error) {
          // ignore errors while retry, just do next try
        }
      }
    }
    // throw original error when all signIn attempts failed
    throw errorOnCreateUser;
  };

  onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ companyName: e.target.value });

  onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: e.target.value });

  onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ password: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isRegistering, errorMessage } = this.state;

    return (
      <div className={`block h-[100vh] md:flex`}>
        <div
          className="w-1/2 bg-[#f3f8f9] bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${bg1})` }}
        />
        <div
          className={`flex h-full w-full flex-col items-center justify-between p-[10px_20px] md:h-auto md:w-1/2 md:p-[40px_20px_10px]`}
        >
          <div />
          <div>
            <div className={`pb-[50px] pt-[32px] lg:pt-0`}>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <div className={`relative mb-[20px]`}>
                  <label className={`block pb-[0.6em]`}>会社名</label>
                  <DefaultInput
                    className={`h-[40px] w-[360px]`}
                    value={this.state.companyName}
                    onChange={this.onChangeCompanyName}
                    placeholder="Onebox株式会社"
                    type="text"
                    disabled={isRegistering}
                    required
                    autoComplete="organization"
                  />
                </div>
                <div className={`relative mb-[20px]`}>
                  <label className={`block pb-[0.6em]`}>
                    メールアドレス
                    <div className="caption text-[10px] text-[#de7171]">
                      ※ログインで使用するためご自身のアドレスを入力ください
                    </div>
                  </label>
                  <DefaultInput
                    className={`h-[40px] w-[360px]`}
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isRegistering}
                    required
                    autoComplete="username"
                  />
                </div>
                <div className={`relative mb-[20px]`}>
                  <label className={`block pb-[0.6em]`}>パスワード</label>
                  <DefaultInput
                    className={`h-[40px] w-[360px]`}
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isRegistering}
                    required
                    minLength={8}
                    autoComplete="new-password"
                  />
                </div>
                <Spin
                  tip={this.state.tip}
                  spinning={isRegistering}
                  indicator={<Icon type="loading" spin />}
                >
                  {/* FIXME: any */}
                  <DefaultButton
                    className={`mt-[40px] h-[50px] w-full max-w-[400px]`}
                    type={'submit' as any}
                    disabled={isRegistering}
                  >
                    会社を作成
                  </DefaultButton>
                </Spin>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className={`mt-[20px] block text-center text-[13px] underline`}
                  style={{ color: color.a.text.normal }}
                  onClick={(e) => {
                    this.toSignIn();
                  }}
                >
                  すでにアカウントをお持ちの方はこちら
                </a>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export const Register = compose<Props, Omit<Props, 'store' | 'history'>>(
  withRouter,
  inject('store'),
  observer
)(_Register);
