import React from 'react';
import FullHeightIframe from '../Conversations/Conversation/Message/FullHeightIframe';
import { Icon, Spin } from 'antd';

export default ({ header, text, html, plainTextMode, loading }) => {
  if (loading) {
    return <Spin indicator={<Icon type="loading" spin />} />;
  }

  return (
    <div>
      <pre
        style={{
          fontFamily: `'Hiragino Kaku Gothic ProN', 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif`,
          color: 'rgba(0, 0, 0, 1)',
        }}
      >
        {header}
      </pre>

      {plainTextMode ? (
        <pre>{text}</pre>
      ) : (
        <blockquote
          style={{ color: 'rgba(0, 0, 0, 1)' }}
          className="m-0 ml-[0.8ex] border-l border-[rgb(204,204,204)] pl-[1ex]"
        >
          <FullHeightIframe srcDoc={html} />
        </blockquote>
      )}
    </div>
  );
};
