import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Details } from './ContactInfo';
import { Icon } from 'antd';

interface Props {
  label: string;
  actions: ReactNode;
  children: ReactNode;
}

export const SalesforceDetails = ({
  label,
  actions,
  children,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <StyledDetails onClick={() => setOpen(!open)}>
        <span style={{ display: 'flex' }}>
          {label}
          <Icon
            type={open ? 'caret-up' : 'caret-down'}
            className="icon ml-[4px] mr-[10px] flex items-center text-[12px] text-inherit"
          />
        </span>
        <div onClick={(e) => e.stopPropagation()}>{actions}</div>
      </StyledDetails>
      {open && children}
    </>
  );
};

const StyledDetails = styled(Details)`
  padding: 8px 0;
`;
