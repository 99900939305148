import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Draft as DraftEntity,
  draftConverter,
} from '../../../firestore/entity/draft';
import { companyDoc, registerUnsubscribe } from '../../../firestore';
import { onSnapshot } from 'firebase/firestore';
import { v4 } from 'uuid';
import { Conversation } from './Conversation/Conversation';
import { SPBackButton } from './Conversation/SPBackButton';
import * as color from '../../../color';
import { Draft } from './Conversation/Draft';

export const DraftConversation = () => {
  const [draft, setDraft] = useState<DraftEntity>();

  const { draftId } = useParams<{ draftId: string }>();

  useEffect(() => {
    return registerUnsubscribe(
      v4(),
      onSnapshot(companyDoc('drafts', draftId, draftConverter), (snapshot) =>
        setDraft(snapshot.data())
      )
    );
  }, [draftId]);

  if (!draft) return <div />;

  if (draft.isReply) {
    // 返信の場合は会話を表示
    return (
      <Conversation
        threadView={false}
        messageId={draft.inReplyToMessageId!}
        key={draft.id}
      />
    );
  }

  // 新規作成は下書きのみを表示
  return (
    <div
      className={`absolute z-[2] h-[100vw] w-[100vh] flex-1 overflow-auto sm:relative sm:z-[1] sm:h-full sm:w-auto`}
      style={{ backgroundColor: color.sidebar.background.normal }}
    >
      <div className={`p-[10px]`}>
        <SPBackButton backLink="/me/drafts" />
      </div>
      <Draft draft={draft} key={draft.id} />
    </div>
  );
};
