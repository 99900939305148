import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { DatePicker, TimePicker } from 'antd';
import { Button } from '../../../../../components/basics';
import moment from 'moment';
import { AutoReplyData } from '../../../../../firestore/entity/inbox';
import { Input, Select } from '../../../../../components/forms';
import { Textarea } from '../../../../../components/forms/Textarea/Textarea';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';

export type AutoReplyForm = Omit<
  AutoReplyData,
  'teamID' | 'updatedAt' | 'createdAt'
>;

type Props = {
  inboxes: { id: string; email: string }[];
  autoReply?: AutoReplyData;
  onSave: (data: AutoReplyForm) => Promise<void>;
  onDelete?: () => Promise<void>;
  open: boolean;
  readonly: boolean;
  onOpenChange: (open: boolean) => void;
};

export const AutoReplyEditDrawer = ({
  inboxes,
  autoReply,
  onSave,
  onDelete,
  open,
  onOpenChange,
  readonly,
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<AutoReplyForm>({
    defaultValues: {
      inboxId: autoReply?.inboxId ?? '',
      title: autoReply?.title ?? '',
      autoReplyMode: autoReply?.autoReplyMode ?? 'allDay',
      messageBody: autoReply?.messageBody ?? '',
      enabled: autoReply?.enabled ?? true,
      startTime: autoReply?.startTime ?? null,
      endTime: autoReply?.endTime ?? null,
      periodStartDate: autoReply?.periodStartDate ?? null,
      periodEndDate: autoReply?.periodEndDate ?? null,
      periodStartTime: autoReply?.periodStartTime ?? null,
      periodEndTime: autoReply?.periodEndTime ?? null,
    },
  });

  const showDialog = useConfirmDialog();

  const autoReplyMode = watch('autoReplyMode');
  useEffect(() => {
    if (autoReplyMode !== 'specifiedTime') {
      setValue('startTime', null);
      setValue('endTime', null);
    }
    if (autoReplyMode !== 'specifiedPeriod') {
      setValue('periodStartDate', null);
      setValue('periodEndDate', null);
      setValue('periodStartTime', null);
      setValue('periodEndTime', null);
    }
  }, [autoReplyMode, setValue]);

  const onSubmit = async (update: AutoReplyForm) => {
    await onSave(update);
  };

  const onClickDelete = async () => {
    if (!onDelete) {
      return;
    }
    showDialog({
      title: `自動返信を削除しますか？`,
      description: '一度削除すると元に戻せません',
      okType: 'danger',
      okText: '削除',
      onOk: onDelete,
    });
  };

  const validateTime = (v: string | null) =>
    autoReplyMode !== 'specifiedTime' || v ? true : '時間帯を入力してください';

  const validatePeriodDate = (v: string | null) => {
    if (autoReplyMode !== 'specifiedPeriod') {
      return true;
    }
    if (!v) {
      return '期間を入力してください';
    }
    const periodStartDate = getValues('periodStartDate');
    const periodStartTime = getValues('periodStartTime');
    const periodEndDate = getValues('periodEndDate');
    const periodEndTime = getValues('periodEndTime');
    if (periodStartDate && periodEndDate && periodEndDate && periodEndTime) {
      const start = periodStartDate + periodStartTime;
      const end = periodEndDate + periodEndTime;
      if (start >= end) {
        return '開始日には終了日より前の日付を入力してください';
      }
    }
    return true;
  };

  const validatePeriodTime = (v: string | null) =>
    autoReplyMode !== 'specifiedPeriod' || v ? true : '期間を入力してください';

  const periodErrorMessage = (
    errors.startTime ??
    errors.endTime ??
    errors.periodStartDate ??
    errors.periodEndDate ??
    errors.periodStartTime ??
    errors.periodEndTime
  )?.message;

  return (
    <SettingPageDrawer
      title={autoReply ? '自動返信編集' : '自動返信作成'}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <InputGroup
          label="タイトル"
          errorMessage={errors.title?.message}
          required
        >
          <Input
            {...register('title', {
              required: 'タイトルを入力してください',
              disabled: readonly,
            })}
            className="w-full resize-y rounded-lg border border-sumi-300 p-2 outline-none focus-visible:border-sea-500 disabled:text-sumi-500"
          />
        </InputGroup>
        <InputGroup
          label="適用する期間"
          errorMessage={periodErrorMessage}
          required
        >
          <>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  {...register('autoReplyMode', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="allDay"
                  className="mr-2"
                />
                すべての時間帯
              </label>
            </div>
            <div className="mb-4">
              <label className="mb-4 flex items-center">
                <input
                  {...register('autoReplyMode', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="specifiedTime"
                  className="mr-2"
                />
                指定した時間帯
              </label>
              <div className="ml-6 flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="w-8">開始</div>
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      validate: validateTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : null)
                        }
                        disabled={readonly || autoReplyMode !== 'specifiedTime'}
                      />
                    )}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8">終了</div>
                  <Controller
                    name="endTime"
                    control={control}
                    rules={{
                      validate: validateTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : null)
                        }
                        disabled={readonly || autoReplyMode !== 'specifiedTime'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-4 flex items-center">
                <input
                  {...register('autoReplyMode', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="specifiedPeriod"
                  className="mr-2"
                />
                指定した期間
              </label>
              <div className="ml-6 flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="w-8">開始</div>
                  <Controller
                    name="periodStartDate"
                    control={control}
                    rules={{
                      validate: validatePeriodDate,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        placeholder="日付"
                        format="YYYY-MM-DD"
                        value={value ? moment(value, 'YYYY-MM-DD') : undefined}
                        onChange={(date) =>
                          onChange(date ? date.format('YYYY-MM-DD') : null)
                        }
                        disabled={
                          readonly || autoReplyMode !== 'specifiedPeriod'
                        }
                      />
                    )}
                  />
                  <Controller
                    name="periodStartTime"
                    control={control}
                    rules={{
                      validate: validatePeriodDate,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : null)
                        }
                        disabled={
                          readonly || autoReplyMode !== 'specifiedPeriod'
                        }
                      />
                    )}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8">終了</div>
                  <Controller
                    name="periodEndDate"
                    control={control}
                    rules={{
                      validate: validatePeriodTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        placeholder="日付"
                        format="YYYY-MM-DD"
                        value={value ? moment(value, 'YYYY-MM-DD') : undefined}
                        onChange={(date) =>
                          onChange(date ? date.format('YYYY-MM-DD') : null)
                        }
                        disabled={
                          readonly || autoReplyMode !== 'specifiedPeriod'
                        }
                      />
                    )}
                  />
                  <Controller
                    name="periodEndTime"
                    control={control}
                    rules={{
                      validate: validatePeriodTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : null)
                        }
                        disabled={
                          readonly || autoReplyMode !== 'specifiedPeriod'
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        </InputGroup>
        <InputGroup
          label="適用するメールアドレス"
          errorMessage={errors.inboxId?.message}
          required
        >
          <Controller
            name="inboxId"
            control={control}
            rules={{
              required: 'メールアドレスを入力してください',
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={(value) => onChange(value)}
                disabled={readonly}
                className="w-full"
                options={inboxes.map((inbox) => ({
                  value: inbox.id,
                  label: inbox.email,
                }))}
              />
            )}
          />
        </InputGroup>
        <InputGroup label="自動返信の本文">
          <Controller
            name="messageBody"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                className="w-full"
                minRows={8}
                maxRows={8}
                disabled={readonly}
              />
            )}
          ></Controller>
        </InputGroup>
        <div className="flex gap-4">
          {autoReply ? (
            <>
              <Button type="submit" disabled={readonly || isSubmitting}>
                更新
              </Button>
              <Button
                color="danger"
                disabled={readonly || isSubmitting}
                onClick={onClickDelete}
              >
                削除
              </Button>
            </>
          ) : (
            <Button type="submit" disabled={readonly || isSubmitting}>
              作成
            </Button>
          )}
        </div>
      </form>
    </SettingPageDrawer>
  );
};
