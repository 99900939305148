import { H1 } from 'App/Common/H1';
import { H2 } from 'App/Common/H2';
import { SlackIntegration } from './slack';
import { ChatworkIntegration } from './chatwork';
import { IntegrationRoutes } from './IntegrationRoutes';
import { useStore } from 'hooks/useStore';
import { SalesforceIntegration } from './salesforce/SalesforceIntegration';

type Props = {
  routes: IntegrationRoutes;
};

export const Integrations: React.FC<Props> = (props) => {
  const store = useStore();
  return (
    <div className={`p-[32px]`}>
      <H1>外部サービス連携</H1>
      <div className="service mb-[48px]">
        <H2>Slack</H2>
        <SlackIntegration />
      </div>
      {store.isV2Plan && (
        <div className="service mb-[48px]">
          <H2>Chatwork</H2>
          <ChatworkIntegration routes={props.routes} />
        </div>
      )}
      <div className="service mb-[48px]">
        <H2>Salesforce</H2>
        <SalesforceIntegration />
      </div>
    </div>
  );
};
