import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import level from '../../../font/level';
import { Icon, Tooltip } from 'antd';

class Event extends Component {
  render() {
    const { event } = this.props;

    switch (event.type) {
      case 'assignee:update':
      case 'status:update:backlog':
      case 'status:update:processed':
      case 'tags:add':
      case 'tags:remove':
      case 'reminder:update': //指定したタイプのイベントのみ表示する
        return (
          <div
            className={`m-0 flex flex-wrap items-center p-[8px_10px] sm:m-[16px_10px] sm:p-[0_6px]`}
          >
            <div className={`message flex items-center`}>
              <div
                className={`icon-wrapper mr-[6px] text-[14px]`}
                style={{ color: 'rgba(0, 0, 0, 0.5)' }}
              >
                <Icon type="bell"></Icon>
              </div>
              <div className={`text`} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                {event.text}
              </div>
            </div>
            <div className={`time-wrapper flex-shrink-0`}>
              <Tooltip title={event.date.format('YYYY年M月D日 HH:mm')}>
                <span
                  className={`text-[#8d8d8d]`}
                  style={{ fontSize: level[0] }}
                >
                  {event.date.fromNow()}
                </span>
              </Tooltip>
            </div>
          </div>
        );
      default:
        return <></>; // 指定したタイプでないイベントは表示しない
    }
  }
}

export default compose(withRouter, inject('store'), observer)(Event);
