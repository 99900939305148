import { Inboxes, InboxesProps } from './Inboxes';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Store } from '../../../../../store';
import {
  addInboxFunction,
  fetchSubscriptionItem,
} from '../../../../../functions';
import { db9 } from '../../../../../firebase';
import {
  collection,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { Product } from '../../../Payment/Product';
import { CompanyEmailRoutes } from '../../CompnayEmail/CompanyEmailRoutes';
import { TEAM_INBOX_PATHS } from '../../../routes';
import { InboxType } from 'lib';
import { useToast } from '../../../../../hooks/useToast';
import { eventNames, logEvent } from '../../../../../analytics';
import { useOAuthHandler } from './useOAuthHandler';
import { EmailEditDrawerWithLogic } from '../EmailEditDrawer/EmailEditDrawerWithLogic';
import { Dialog } from '../../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../../components/basics/dialog/DialogContent';
import { ForwardingAddressField } from '../fields/ForwardingAddressField';

type Props = {
  isPrivateTeam: boolean;
  routes: CompanyEmailRoutes;
};

type AddInboxResult = {
  inboxId: string;
  forwardTo: string;
};

export const InboxesWithLogic = observer(({ isPrivateTeam, routes }: Props) => {
  const { teamId } = useParams<{ teamId: string }>();
  const store = useStore();
  const history = useHistory();
  const { showToast } = useToast();
  const [addedDialogContext, setAddedDialogContext] = useState<
    { forwardAddress: string } | undefined
  >(undefined);
  const inboxes = store.getTeamInboxes(teamId);
  const signatures = store.getSignatures(teamId);
  const addState = useAddState(store);
  const readonly = isPrivateTeam ? store.me.isReadOnly : !store.me.isAdmin;
  const basePath = routes.inboxRoutes.getIndexPathName({ teamId });
  const newPath = routes.inboxRoutes.getNewPathName({ teamId });
  const newPathMatch = useRouteMatch({
    exact: true,
    path: newPath,
  });

  const currentInboxId = useInboxId();
  const currentInbox = currentInboxId
    ? store.getInbox(currentInboxId)
    : undefined;

  const addInbox = async (
    email: string,
    type: InboxType,
    tokenId: string | undefined
  ) => {
    await addInboxFunction({
      companyId: store.signInCompany,
      teamId,
      type,
      email,
      name: '',
      autoCc: '',
      autoBcc: '',
      defaultSignatureId: '',
      tokenId,
    })
      .then((res: { data: AddInboxResult }) => {
        logEvent(eventNames.add_inbox);
        showToast('success', 'メールアドレスを追加しました');

        const { inboxId, forwardTo } = res.data;
        routes.inboxRoutes.toDetail({ teamId, inboxId });

        if (type === 'email') {
          setAddedDialogContext({ forwardAddress: forwardTo });
        }
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'メールアドレスの追加に失敗しました');
      });
  };

  const handleAddInboxByGoogle = useOAuthHandler(
    'google',
    store.signInCompany,
    teamId,
    async (d) => await addInbox(d.email, d.service, d.id)
  );

  const handleAddInboxByMicrosoft = useOAuthHandler(
    'outlook',
    store.signInCompany,
    teamId,
    async (d) => await addInbox(d.email, d.service, d.id)
  );

  const renderEditDrawer = (inboxId: string) => {
    if (currentInbox?.id !== inboxId) {
      return null;
    }

    return (
      <EmailEditDrawerWithLogic
        key={currentInbox.id}
        teamId={teamId}
        inbox={currentInbox}
        routes={routes}
        readonly={readonly}
        onClose={() => history.push(basePath)}
      />
    );
  };

  return (
    <>
      <Inboxes
        inboxes={inboxes}
        signatures={signatures.map((s) => ({ id: s.id, name: s.title }))}
        addState={addState}
        isPrivateTeam={isPrivateTeam}
        readonly={readonly}
        addDialogOpen={!!newPathMatch}
        onChangeAddDialogOpen={(open) => {
          history.push(open ? newPath : basePath);
        }}
        editingInboxId={currentInboxId}
        onChangeEditingInboxId={(inboxId) => {
          const path = inboxId
            ? routes.inboxRoutes.getDetailPathName({ teamId, inboxId })
            : basePath;
          history.push(path);
        }}
        onAddByEmailAddress={async (email) =>
          await addInbox(email, 'email', undefined)
        }
        onAddByGoogleOauth={handleAddInboxByGoogle}
        onAddByMicrosoftOauth={handleAddInboxByMicrosoft}
        renderEditDrawer={renderEditDrawer}
      />
      <Dialog
        open={addedDialogContext != null}
        onOpenChange={() => setAddedDialogContext(undefined)}
        width="sm"
      >
        <DialogHeader title="メールアドレスを追加しました" />
        <DialogContent>
          <div className="break-all pb-4">
            <div>転送コードを発行しました。</div>
            <div>
              転送コードをコピーし、お使いのメールサーバー (Gmail・Xserverなど)
              の設定から「転送先」に指定してください
            </div>
          </div>
          <ForwardingAddressField
            email={addedDialogContext?.forwardAddress ?? ''}
            fontWeight="bold"
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

const useAddState = (store: Store): InboxesProps['addState'] => {
  const [state, setState] = useState<InboxesProps['addState']>('loading');
  const unsubscribeRef = useRef<Unsubscribe | undefined>(undefined);

  const check = async () => {
    if (
      process.env.REACT_APP_USE_EMULATOR === 'true' ||
      process.env.REACT_APP_PROJECT_ID === 'yaritori-dev'
    ) {
      setState('available');
      return;
    }

    const resp = await fetchSubscriptionItem({
      companyId: store.signInCompany,
      itemType: 'plan',
    });

    const productsRef = collection(db9, 'stripe', 'collections', 'products');
    const q = query(productsRef, where('active', '==', true));
    unsubscribeRef.current?.();
    unsubscribeRef.current = onSnapshot(q, (snap) => {
      const product = snap.docs
        .map((doc: any) => new Product({ id: doc.id, ...doc.data() }))
        .find((p) => p.id === resp?.data.price.product);

      if (
        product?.maxInboxes &&
        store.inboxesLength >= Number(product.maxInboxes)
      ) {
        setState('max_inboxes');
      } else {
        setState('available');
      }
    });
  };

  useEffect(() => {
    check().then();
  }, [check]);

  useEffect(() => {
    return () => {
      unsubscribeRef.current?.();
    };
  }, []);

  return state;
};

export const useInboxId = (): string | undefined => {
  const newPathMatch = useRouteMatch({
    exact: true,
    path: TEAM_INBOX_PATHS.new,
  });
  const editPathMatch = useRouteMatch<{ inboxId: string }>({
    exact: true,
    path: TEAM_INBOX_PATHS.detail,
  });
  if (newPathMatch || !editPathMatch) {
    return undefined;
  }
  return editPathMatch.params.inboxId;
};
