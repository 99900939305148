import { ReactNode } from 'react';
import { createStore, Provider, useAtom, useAtomValue } from 'jotai';
import { useSubscribeAuthState } from '../atoms/auth';
import { attachmentDeletionSchedulerEffect } from '../components/delivery/form/DeliveryMessageCreateForm/attachmentDeletionScheduler';
import { signInCompanyStripeProductEffect } from '../atoms/firestore/signInCompanyStripeProduct';

export type Props = {
  children: ReactNode;
};

export const store = createStore();

export const StoreProvider = ({ children }: Props): JSX.Element => {
  return (
    <Provider store={store}>
      <GlobalEffects>{children}</GlobalEffects>
    </Provider>
  );
};

const GlobalEffects = ({ children }: { children: ReactNode }): JSX.Element => {
  useSubscribeAuthState();
  useAtom(attachmentDeletionSchedulerEffect);
  useAtomValue(signInCompanyStripeProductEffect);
  return <>{children}</>;
};
