import React from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Members from './Members';
import { Groups } from './Groups';

const { TabPane } = Tabs;

export const MembersAndGroups = observer((): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const tabKey = location.pathname.split('/').slice(-1)[0];

  const onChangeTab = (key: string) => {
    history.push(`/settings/company/${key}`);
  };

  return (
    <div className="pb-[32px] pl-[32px] pr-[16px] pt-[16px]">
      <Tabs
        animated={false}
        defaultActiveKey={tabKey}
        onChange={onChangeTab}
        size={'large'}
      >
        <TabPane tab="メンバー設定" key="members">
          <Members />
        </TabPane>
        <TabPane tab="グループ設定" key="groups">
          <Groups />
        </TabPane>
      </Tabs>
    </div>
  );
});
