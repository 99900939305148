import React, { useState } from 'react';
import { H1 } from '../../Common/H1';
import { Comment } from '../../Common/Comment';
import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import { H3 } from '../../Common/H3';
import { normalizeStr } from 'lib';
import { Select, Alert } from 'antd';

export default (props) => {
  const { add } = props;
  const [name, setName] = useState('');
  const [domains, setDomains] = useState([]);
  const [externalId, setExternalId] = useState('');
  const [adding, setAdding] = useState(false);
  const [isDuplicateDomains, setIsDuplicateDomains] = useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const onChangeName = async (e) => {
    const name = e.target.value;
    setName(name);
    const isDuplicate = await props.checkDuplicateName(name);
    setIsDuplicateName(isDuplicate);
  };
  const onChangeDomain = async (domains) => {
    setDomains(domains);
    if (domains.length > 0) {
      const isDuplicate = await props.checkDuplicateDomains(domains);
      setIsDuplicateDomains(isDuplicate);
    } else {
      setIsDuplicateDomains(false);
    }
  };
  const addAccount = async (e) => {
    e.preventDefault();
    setAdding(true);
    await add({
      name,
      nameNormalized: normalizeStr(name),
      domains,
      externalId,
    });
  };

  const valid = () => {
    return name.length;
  };
  return (
    <div>
      <H1>新しい取引先</H1>

      <form onSubmit={addAccount}>
        <H3>
          名称 <span className={`text-[#f5222d]`}>*</span>
        </H3>
        <DefaultInput
          className={`my-[10px] h-[40px] w-[400px]`}
          value={name}
          onChange={onChangeName}
          placeholder="田中 一郎"
          disabled={adding}
        />
        <Comment>例）Onebox株式会社</Comment>
        {isDuplicateName && (
          <Alert
            message="すでに登録されている取引先を追加することはできません
            "
            type="error"
            style={{ marginTop: 8 }}
          />
        )}
        <H3 style={{ marginTop: 24 }}>ドメイン</H3>
        <Select
          mode="tags"
          style={{ width: 400 }}
          onChange={onChangeDomain}
          value={domains}
          tokenSeparators={[',']}
          disabled={adding}
        ></Select>
        <Comment>
          コンタクトに登録されているメールアドレスのドメイン (@以降の部分)
          と自動で紐付けます。 例) onebox.tokyo
        </Comment>
        {isDuplicateDomains && (
          <Alert
            message="すでに取引先に登録されているドメインを追加することはできません"
            type="error"
            style={{ marginTop: 8 }}
          />
        )}

        <H3 style={{ marginTop: 24 }}>外部ID</H3>
        <DefaultInput
          className={`my-[10px] h-[40px] w-[400px]`}
          value={externalId}
          onChange={(e) => setExternalId(e.target.value)}
          placeholder=""
          disabled={adding}
        />
        <Comment>CRMツールとの連携で活用いただく予定です。</Comment>

        <DefaultButton
          className={`mt-[30px] h-[40px] w-full max-w-[100px]`}
          type="primary"
          disabled={adding || !valid() || isDuplicateName || isDuplicateDomains}
          loading={adding}
        >
          作成
        </DefaultButton>
      </form>
    </div>
  );
};
