import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import * as color from '../../../color';
import { H2 } from '../../Common/H2';
import { Comment } from '../../Common/Comment';
import { DefaultButton } from '../../Common/Button';
import Avatar from '../../Common/Avatar';
import Tooltip from '../../Common/Tooltip';
import { db } from '../../../firebase';
import { Button, message, Modal } from 'antd';
import { Store } from '../../../store';
import { InvitedUser, User } from 'lib';
import { InviteModal } from './Invite/InviteModal';
import { RoleSelect } from './RoleSelect';
import { tv } from 'tailwind-variants';
import { deleteUserFunction, updateUserRoleFunction } from '../../../functions';

const { confirm } = Modal;

type Props = {
  store: Store;
};

const roleSelect = tv({ base: 'w-[120px]' });

type State = {
  editingUserIds: string[];
  modalVisible: boolean;
};

class Members extends React.Component<Props, State> {
  formRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = { editingUserIds: [], modalVisible: false };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.formRef.current?.focus();
  }

  onSelectRole = async (
    userId: string,
    role: 'admin' | 'member' | 'readonly'
  ) => {
    this.setState({
      editingUserIds: [...this.state.editingUserIds, userId],
    });

    try {
      await updateUserRoleFunction({
        companyId: this.props.store.signInCompany,
        userId,
        role,
      });
      message.success('メンバーを更新しました');
    } catch (e) {
      console.error(e);
      message.error('メンバーの更新に失敗しました');
    }

    this.setState({
      editingUserIds: this.state.editingUserIds.filter((id) => id !== userId),
    });
  };

  setModalVisible(modalVisible: boolean) {
    this.setState({ modalVisible });
  }

  removeUser = async (user: User) => {
    confirm({
      title: `ユーザ "${user.name}" を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        try {
          await deleteUserFunction({
            companyId: this.props.store.signInCompany,
            userId: user.id,
          });
          message.success('ユーザを削除しました');
        } catch (e) {
          console.error(e);
          message.error('ユーザの削除に失敗しました');
        }
      },
      onCancel: () => {
        //
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  removeInvite = async (invitedUser: InvitedUser) => {
    confirm({
      title: `ユーザ "${invitedUser.email}" の招待を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        // @NOTE invitedUser or invitationsの片方だけ消されると困るので
        // トランザクションを張る
        // https://github.com/onebox-inc/yaritori/pull/389#discussion_r607008637
        await db.runTransaction(async (tx) => {
          await Promise.all([
            tx.delete(invitedUser.ref),
            tx.delete(
              db
                .collection('companies')
                .doc(this.props.store.signInCompany)
                .collection('invitations')
                .doc(invitedUser.invitationId)
            ),
          ]);
        });
        message.success('ユーザの招待を削除しました');
      },
      onCancel: () => {
        //
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  render() {
    const { me, users, invitedUsers, invitable } = this.props.store;
    const { editingUserIds, modalVisible } = this.state;
    const inviteDisabled = !me.isAdmin || modalVisible || !invitable;
    const inviteDisabledTooltipTitle =
      (!me.isAdmin && '権限がありません') ||
      (!invitable &&
        '現在のプランのユーザー数（招待中のユーザーも含みます）の上限に達しているため招待できません') ||
      '';

    return (
      <div className="pt-[32px]">
        <div className={contentClassName}>
          <Tooltip title={inviteDisabledTooltipTitle} visible>
            <DefaultButton
              className={`h-[40px] p-[0_30px]`}
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={inviteDisabled}
            >
              メンバーを招待する
            </DefaultButton>
          </Tooltip>
          <InviteModal
            visible={modalVisible}
            close={() => this.setModalVisible(false)}
          />
        </div>

        <div className={contentClassName}>
          <H2>全体のメンバー</H2>

          <div>
            {/* 自分 */}
            <div className={memberClassName}>
              <div className={profileClassName}>
                <Avatar user={me} />
                <div className={nameEmailClassName}>
                  <div
                    className={nameClassName}
                    style={{ color: color.common.text2 }}
                  >
                    {me.name}
                    <span
                      className="text-[11px] font-normal"
                      style={{ color: color.common.text3 }}
                    >
                      （自分）
                    </span>
                  </div>
                  <Comment>{me.email}</Comment>
                </div>
              </div>
              <Tooltip title="自分の権限は変更できません" visible>
                <RoleSelect
                  className={roleSelect()}
                  defaultValue={me.role}
                  value={me.role}
                  style={{ marginRight: 72 }}
                  disabled
                />
              </Tooltip>
            </div>

            {/* 他のメンバー */}
            {users
              .filter((u) => u.id !== me.id)
              .map((u) => (
                <div className={memberClassName} key={u.id}>
                  <div className={profileClassName}>
                    <Avatar user={u} />
                    <div className={nameEmailClassName}>
                      <div
                        className={nameClassName}
                        style={{ color: color.common.text2 }}
                      >
                        {u.name}
                      </div>
                      <Comment>{u.email}</Comment>
                    </div>
                  </div>
                  <Tooltip title="権限がありません" visible={!me.isAdmin}>
                    <RoleSelect
                      className={roleSelect()}
                      onSelect={(value) => this.onSelectRole(u.id, value)}
                      value={u.role}
                      disabled={!me.isAdmin || editingUserIds.length > 0}
                      loading={editingUserIds.some((id) => id === u.id)}
                    />
                  </Tooltip>
                  <Tooltip title="権限がありません" visible={!me.isAdmin}>
                    <Button
                      className={`ml-[12px`}
                      type="danger"
                      onClick={() => this.removeUser(u)}
                      disabled={!me.isAdmin}
                    >
                      削除
                    </Button>
                  </Tooltip>
                </div>
              ))}
          </div>
        </div>
        <div className={contentClassName}>
          <H2>招待中のメンバー</H2>
          {invitedUsers.length === 0 && <div>招待中のメンバーはいません</div>}
          {invitedUsers
            .filter((u) => u.id !== me.id)
            .map((u) => (
              <div className={memberClassName} key={u.id}>
                <div className={profileClassName}>
                  <Avatar user={u} />
                  <div className={nameEmailClassName}>
                    <div
                      className={nameClassName}
                      style={{ color: color.common.text2 }}
                    >
                      {u.email}
                    </div>
                    <Comment>
                      招待の期限：{u.expiredAt.format('YYYY/MM/DD HH:mm')}
                      {u.isExpired ? '（期限切れ）' : ''}
                    </Comment>
                  </div>
                </div>
                <RoleSelect
                  className={roleSelect()}
                  defaultValue="member"
                  value={u.role}
                  disabled
                />
                <Tooltip title="権限がありません" visible={!me.isAdmin}>
                  <Button
                    className={`ml-[12px`}
                    type="danger"
                    onClick={() => this.removeInvite(u)}
                    disabled={!me.isAdmin}
                  >
                    削除
                  </Button>
                </Tooltip>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const contentClassName = `mb-[50px]`;

const memberClassName = `mt-[10px] flex items-center`;

const profileClassName = `flex w-[392px] items-center`;

const nameEmailClassName = `ml-[10px] flex max-w-[300px] flex-col justify-center`;

const nameClassName = `text-[13px] font-bold`;

export default compose<Props, Omit<Props, 'store'>>(
  withRouter,
  inject('store'),
  observer
)(Members);
