// @ts-nocheck
import { useEffect, useState } from 'react';
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';
import { Button, Menu } from 'antd';
import RecaptchaContainer from '../Auth/RecaptchaContainer';
import { siteKey } from '../../firebase';
import { DefaultInput } from 'App/Common/Input';
import { DefaultButton } from 'App/Common/Button';

const auth = getAuth();

/**
 * @type {import('firebase/auth').MultiFactorResolver | null}
 */
const initMultiFactorResolver = null;

/**
 * @type {import('firebase/auth').RecaptchaVerifier | null}
 */
const initRecaptchaVerifier = null;

/**
 * type check PhoneMultiFactorInfo
 * @param {import('firebase/auth').MultiFactorInfo} hint
 * @returns {hint is import('firebase/auth').PhoneMultiFactorInfo}
 */
function isPhoneMultiFactorInfo(hint) {
  return hint.factorId === 'phone';
}

/**
 * @typedef {object} MFAVerifyPhoneCodeFormProps
 * @prop {import('firebase/auth').MultiFactorError=} mfaRequiredError
 */

/**
 *
 * @param {MFAVerifyPhoneCodeFormProps} props
 * @returns {JSX.Element | null}
 */
export function MFAVerifyPhoneCodeForm(props) {
  const { mfaRequiredError } = props;

  const [multiFactorResolver, setMultiFactorResolver] = useState(
    initMultiFactorResolver
  );

  const [recaptchaVerifier, setRecaptchaVerifier] = useState(
    initRecaptchaVerifier
  );
  const [verificationId, setVerificationId] = useState('');
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!mfaRequiredError) {
      return;
    }

    const resolver = getMultiFactorResolver(auth, mfaRequiredError);
    setMultiFactorResolver(resolver);
  }, [mfaRequiredError]);

  if (!multiFactorResolver || multiFactorResolver.hints.length === 0) {
    return null;
  }

  if (multiFactorResolver && !verificationId) {
    return (
      <form>
        <div style={{ marginLeft: '15px' }}>
          <RecaptchaContainer
            siteKey={siteKey}
            onCheckBox={({ recaptchaVerifier }) => {
              setRecaptchaVerifier(recaptchaVerifier);
            }}
            expiredCallback={() => setRecaptchaVerifier(null)}
            onError={(error) => console.error(error)}
          />
        </div>
        <Menu
          onClick={(event) => {
            if (!recaptchaVerifier) {
              return;
            }
            const { key } = event;
            const phoneInfoOptions = {
              multiFactorHint: multiFactorResolver.hints.find(
                (hint) => hint.uid === key
              ),
              session: multiFactorResolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            // Send SMS verification code
            return phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then(function (verificationId) {
                setVerificationId(verificationId);
              });
          }}
          style={{ width: 352, borderRight: '0px' }}
        >
          {multiFactorResolver.hints.map((hint) => {
            if (!isPhoneMultiFactorInfo(hint)) {
              return null;
            }
            return (
              <Menu.Item disabled={!recaptchaVerifier} key={hint.uid}>
                <Button
                  disabled={!recaptchaVerifier}
                  style={{ width: '300px' }}
                >
                  {hint.phoneNumber.replaceAll('*', 'X')} にSMSを送信
                </Button>
              </Menu.Item>
            );
          })}
        </Menu>
      </form>
    );
  }

  if (verificationId) {
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();

          const cred = PhoneAuthProvider.credential(verificationId, code);
          const multiFactorAssertion =
            PhoneMultiFactorGenerator.assertion(cred);
          return multiFactorResolver
            .resolveSignIn(multiFactorAssertion)
            .then((cred) => {
              // Complete sign-in.
              window.location.href = '/';
            })
            .catch((error) => {
              switch (error.code) {
                case 'auth/invalid-verification-code':
                  setErrorMessage('確認コードが違います');
                  break;
                default:
                  setErrorMessage(error.code);
              }
            });
        }}
      >
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
        <div className={`relative mb-[20px]`}>
          <label
            className={`static left-[-110px] top-[10px] block pb-[1em] lg:absolute lg:pb-0`}
          >
            確認コード
          </label>
          <DefaultInput
            className={`h-[40px] w-[400px]`}
            style={{ maxWidth: 'calc(100vw - 60px)' }}
            value={code}
            onChange={(event) => {
              const val = event.target.value;
              setCode(val);
            }}
            placeholder="123456"
            type="number"
            disabled={false}
          />
        </div>
        <DefaultButton
          className={`mt-[20px] h-[50px] w-full max-w-[400px]`}
          type="primary"
        >
          確認コードの送信
        </DefaultButton>
      </form>
    );
  }

  return null;
}
