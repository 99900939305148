import { AutoReplySettings } from './AutoReplySettings';
import {
  AutoReplyEditDrawer,
  AutoReplyForm,
} from '../AutoReplyEditDrawer/AutoReplyEditDrawer';
import { Button, Loading } from 'components/basics';
import { Checkmark } from 'components/basics/Checkmark/Checkmark';
import { useStore } from 'hooks/useStore';
import { useToast } from 'hooks/useToast';
import {
  addDoc,
  deleteDoc,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  AutoReply,
  autoReplyConverter,
} from '../../../../../firestore/entity/inbox';
import { observer } from 'mobx-react';
import { useSubscribeCollection } from '../../../../../hooks/firestore/subscription';
import { companyCollection, companyDoc } from '../../../../../firestore';
import { SettingObjectRoutes } from '../../../common';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ComponentProps } from 'react';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../../../atoms/auth';

type Props = {
  teamId: string;
  routes: SettingObjectRoutes<{ teamId: string }, 'autoReplyId'>;
};

export const AutoReplySettingsWithLogic = observer(
  ({ teamId, routes }: Props) => {
    const store = useStore();
    const { showToast } = useToast();
    const history = useHistory();
    const me = useAtomValue(meAtom);

    const [loading, autoReplies] = useSubscribeCollection(
      () =>
        query(
          companyCollection('autoReplies', autoReplyConverter),
          where('teamId', '==', teamId)
        ),
      []
    );

    const handleSave = async (data: AutoReplyForm, autoReplyId?: string) => {
      try {
        if (autoReplyId) {
          await updateDoc(companyDoc('autoReplies', autoReplyId), {
            ...data,
            updatedAt: serverTimestamp(),
          });
        } else {
          await addDoc(companyCollection('autoReplies'), {
            ...data,
            teamId,
            updatedAt: serverTimestamp(),
            createdAt: serverTimestamp(),
          });
        }
        showToast('success', '自動返信を設定しました');
        routes.toIndex({ teamId });
      } catch (e) {
        console.error('自動返信の設定に失敗しました:', e);
        showToast('error', '自動返信の設定に失敗しました');
      }
    };

    const handleDelete = async (autoReplyId: string) => {
      try {
        await deleteDoc(companyDoc('autoReplies', autoReplyId));
        showToast('success', '自動返信を削除しました');
        routes.toIndex({ teamId });
      } catch (e) {
        console.error('自動返信の削除に失敗しました:', e);
        showToast('error', '自動返信の削除に失敗しました');
      }
    };

    const handleToggle = async (autoReplyId: string, enabled: boolean) => {
      try {
        await updateDoc(companyDoc('autoReplies', autoReplyId), {
          enabled,
          updatedAt: serverTimestamp(),
        });
      } catch (e) {
        console.error('自動返信の更新に失敗しました:', e);
        showToast('error', '自動返信の更新に失敗しました');
      }
    };

    const ButtonAutoReply = ({
      onClick,
      className = '',
    }: {
      onClick: () => void;
      className?: string;
    }) => (
      <div className={`text-sm ${className}`}>
        <Button onClick={onClick}>自動返信を設定</Button>
      </div>
    );

    if (loading) {
      return <Loading />;
    }

    const content = store.isAutoReplySupported ? (
      autoReplies.length ? (
        <>
          <ButtonAutoReply
            className="mb-4"
            onClick={() => routes.toNew({ teamId })}
          />
          <div className="mb-4 text-sm">
            メールを受信した際に、あらかじめ設定したメッセージを自動的に差出人に返信します。
          </div>
          <AutoReplySettings
            autoReplies={autoReplies}
            onEdit={(id) => routes.toDetail({ teamId, autoReplyId: id })}
            onToggle={handleToggle}
          />
        </>
      ) : (
        <>
          <div className="mb-4 text-base font-bold">
            自動返信を使いこなしましょう！
          </div>
          <Checkmark>
            営業時間外・休暇中など不在時のメールを自動返信します
          </Checkmark>
          <Checkmark className="mb-4">
            1次対応を自動化できるので、送信者に安心感を与えることができます
          </Checkmark>
          <ButtonAutoReply onClick={() => routes.toNew({ teamId })} />
        </>
      )
    ) : (
      <>
        <div className="mb-4 text-base font-bold">
          自動返信を使いこなしましょう！
        </div>
        <Checkmark>
          営業時間外・休暇中など不在時のメールを自動返信します
        </Checkmark>
        <Checkmark className="mb-4">
          1次対応を自動化できるので、送信者に安心感を与えることができます
        </Checkmark>
        <Button onClick={() => history.push('/settings/company/payments')}>
          プランをアップグレード
        </Button>
      </>
    );

    return (
      <div>
        {content}
        <Switch>
          <Route path={routes.paths.new} exact>
            <AutoReplyEditDrawer
              inboxes={store.getTeamInboxes(teamId)}
              open
              onOpenChange={() => routes.toIndex({ teamId })}
              onSave={handleSave}
              readonly={me.isReadOnly}
            />
          </Route>
          <Route path={routes.paths.detail} exact>
            <EditDrawer
              autoReplies={autoReplies}
              inboxes={store.getTeamInboxes(teamId)}
              open
              onOpenChange={() => routes.toIndex({ teamId })}
              onSave={handleSave}
              onDelete={handleDelete}
              readonly={me.isReadOnly}
            />
          </Route>
        </Switch>
      </div>
    );
  }
);

type EditDrawerProps = Omit<
  ComponentProps<typeof AutoReplyEditDrawer>,
  'autoReply' | 'onSave' | 'onDelete'
> & {
  autoReplies: AutoReply[];
  onSave: (data: AutoReplyForm, autoReplyId: string) => Promise<void>;
  onDelete: (autoReplyId: string) => Promise<void>;
};

const EditDrawer = (props: EditDrawerProps) => {
  const { autoReplyId } = useParams<{ autoReplyId: string }>();

  const autoReply = props.autoReplies.find((a) => a.id === autoReplyId);

  return (
    <AutoReplyEditDrawer
      {...props}
      autoReply={autoReply}
      onSave={(data) => props.onSave(data, autoReplyId)}
      onDelete={() => props.onDelete(autoReplyId)}
      key={autoReplyId}
    />
  );
};
